import React, { useEffect, useState } from 'react';
import { Container, Row, Col,  Card, Form, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import ReactS3 from '../../Utils/ReactS3';
import CountryList from '../../Utils/Countries.json';
import { ToastContainer, toast } from 'react-toastify';
import Compressor from 'compressorjs';
import { uploadFileToBackend } from '../../Utils/UploadFile';
import FileUploader from '../../Utils/FileUploader';


const Onboard = () => {
    
    const [s3Data, setS3Data] = useState();
    // const formRef = useRef(null);
    const countryOptions=CountryList?.map((itm)=>{
        return({ value:itm.name, label:itm.code +' - '+itm.name})
    })
  
    useEffect(()=>{
      getAwsCredentails();
    },[])
     
    const getAwsCredentails = async()=>{
        await fetch(process.env.REACT_APP_BASE_URL + '/aws/get/ITGoaWhatsapp', {
          method:'GET'
        }).then((res)=>res.json()).then((response)=>{
          console.log("response at get AWS", response);
          setS3Data(response?.data);
        }).catch((err)=>{
          console.log("err while getting aws credentials", err);
        })
    }
    
    const [loader, setLoader] = useState(false);

    { /* --------------------------- Value State -----------------------------------*/ }
        const [officialName, setOfficialName] = useState('');
        const [officialEmail, setOfficialEmail ] = useState('');
        const [officialContact, setOfficialContact] = useState('');
        
        const [fbEmail, setFbEmail] = useState('');
        const [fbPass, setFbPass]   = useState('');
        
        const [bankStatement, setBankStatement ] = useState('');
        const [bizLicense, setBizLicense] = useState('');
        const [certOfIncorp, setCertOfIncorp] = useState('');
        const [gst, setGst] = useState('');
        const [pan, setPan] = useState('');
        const [adhar, setAdhar] = useState('');
        const [utilityBill, setUtilityBill] = useState('');

        const [metaAccountName, setMetaAccountName] =useState('');
        const [bizName, setBizName] = useState('');
        const [bizWebsite, setBizWebsite] = useState('');
        const [country, setCountry] = useState('');

        const [wpProfileImg, setWpProfileImg] = useState('');
        const [whatsappNo, setWhatsappNo] = useState('');
        const [wpBizAccountName, setWpBizAccountName] = useState('');
        const [wpProfileDisplayName, setWProfileDisplayName] = useState('');
        const [bizCategory, setBizCategory] = useState('');
        const [wpWebsite, setwpWebsite] = useState('');
        const [bizDescription, setBizDescription] = useState('');

    {/*-------------------- Error States -------------------------*/}
        const [officialNameErr, setOfficialNameErr] = useState(false);
        const [officialEmailErr, setOfficialEmailErr ] = useState(false);
        const [officialContactErr, setOfficialContactErr] = useState(false);
        
        const [fbEmailErr, setFbEmailErr] = useState(false);
        const [fbPassErr, setFbPassErr]   = useState(false);
        
        const [bankStatementErr, setBankStatementErr ] = useState(false);
        const [bizLicenseErr, setBizLicenseErr] = useState(false);
        const [certOfincorpErr, setCertOfIncorpErr] = useState(false);
        const [gstErr, setGstErr] = useState(false);
        const [panErr, setPanErr] = useState(false);
        const [adharErr, setAdharErr] = useState(false);
        const [utilityBillErr, setUtilityBillErr] = useState(false);

        const [metaAccountNameErr, setMetaAccountNameErr] =useState(false);
        const [bizNameErr, setBizNameErr] = useState(false);
        const [bizWebsiteErr, setBizWebsiteErr] = useState(false);
        const [countryErr, setCountryErr] = useState(false);

        const [wpProfileImgErr, setWpProfileImgErr] = useState(false);
        const [whatsappNoErr, setWhatsappNoErr] = useState(false);
        const [wpBizAccountNameErr, setWpBizAccountNameErr] = useState(false);
        const [wpProfileDisplayNameErr, setWProfileDisplayNameErr] = useState(false);
        const [bizCategoryErr, setBizCategoryErr] = useState(false);
        const [wpWebsiteErr, setwpWebsiteErr] = useState(false);
        const [bizDescriptionErr, setBizDescriptionErr] = useState(false);

        const [uploadState, setUploadState] = useState(null);
        const [fileUrl, setFileUrl] = useState("");


        const categoryOptions =[
            { value:'One', label:'One' },
            { value:'Two', label:'Two' },
            { value:'Three', label:'Three' }
        ]

        const onBoardForm=()=>{
            setOfficialName('');
            setOfficialEmail('');
            setOfficialContact('');
            setFbEmail('');
            setFbPass('')  ; 
            setBankStatement('');
            setBizLicense('');
            setGst('');
            setPan('');
            setAdhar('')
            setUtilityBill('');
            setMetaAccountName('');
            setBizName('');
            setBizWebsite('');
            setCountry('');
            setWpProfileImg('');
            setWhatsappNo('');
            setWpBizAccountName('')
            setWProfileDisplayName('');
            setBizCategory('');
            setwpWebsite('');
            setBizDescription('');
        }

    {/* ------------ Onboard ------------------ */}

     // upload image
//   function uploadImageToS3(event) {
//     const image = event.target.files[0];
//     if (!image) return;

//     if (image.type === "image/png" || image.type === "image/jpeg") {
//       new Compressor(image, {
//         quality: 0.6,
//         convertSize:0,
//         success: async(result) => {
//           try {
//             let newFileName = image.name.replace(/\..+$/, "");
//             let fileType = image.type;
//             console.log("image", image.size);
//             console.log("compressed result", result.size); 

    
//             // Use the uploadImageFromBackend utility
//             const imageURL = await uploadImageFromBackend(newFileName, fileType, result);

//             if (imageURL) {
//                 console.log("imageURL", imageURL);
//                 console.log("newFileName", newFileName);
//             //   now return image url from where its called, to set result useState.
//             } else {
//               console.error("Image upload failed.");
//             }
//           } catch (error) {
//             console.error("Error uploading image:", error);
//           }
//         },
//         error: (err) => {
//           console.error("Compression error:", err.message);
//         },
//       });
//     } else {
//       toast.error("Invalid file type. Please upload a PNG or JPEG image.");
//     }
//   }

 

            // const handleFileChange = async (event) => {
            //     const file = event.target.files[0];
            //     if (!file) return;

            //     const result = await uploadFileToBackend(file, setUploadState);
            //     setFileUrl(result);
            // };
            console.log("fileUrl", fileUrl);
            console.log("bankStatemnt", bankStatement);
            console.log("bankLicese", bizLicense);


        const handleBankStatementImg=async(e)=>{
            let result = await ReactS3(e.target.files[0], s3Data, "onboardingDocs");
            setBankStatement(result);
        }
        
        const handleBizLicenseImg=async(e)=>{
            let result = await ReactS3(e.target.files[0], s3Data, "onboardingDocs");
            setBizLicense(result);
        }
        
        const handleCertOfIncorp=async(e)=>{
            let result = await ReactS3(e.target.files[0], s3Data, "onboardingDocs");
            setCertOfIncorp(result);
        }
        
        const handleGstImg = async(e)=>{
            let result = await ReactS3(e.target.files[0], s3Data, "onboardingDocs");
            setGst(result);
        }

        const handlePanImg = async(e)=>{
            let result = await ReactS3(e.target.files[0], s3Data, "onboardingDocs");
            setPan(result);
        }
        const handleAdharImg = async(e)=>{
            let result = await ReactS3(e.target.files[0], s3Data, "onboardingDocs");
            setAdhar(result);
        }
        const handleUtilityBill = async(e)=>{
            let result = await ReactS3(e.target.files[0], s3Data, "onboardingDocs");
            setUtilityBill(result);
        }
        const handleWpProfileImg=async(e)=>{
            let result = await ReactS3(e.target.files[0], s3Data, "onboardingDocs");
            setWpProfileImg(result);
        }
        
   
            
        const isValidate = ()=>{

            let isValid = true;
            let scrollArray = [];


            if(!officialName){
                isValid = false;
                setOfficialNameErr(true);
                scrollArray.push('officialName');
            }
            else if(!officialEmail || !officialEmail.includes('@') || !officialEmail.includes('.')){
                isValid = false;
                setOfficialEmailErr(true);
                scrollArray.push('officialEmail');
            }
            else if(!officialContact || officialContact.length<10){
                isValid = false;
                setOfficialContactErr(true);
                scrollArray.push('officialContact');
            }


            else if(!fbEmail || !fbEmail.includes('@') || !fbEmail.includes('.')){
                isValid = false;
                setFbEmailErr(true);
                scrollArray.push('fbEmail');
            }
            else if(!fbPass){
                isValid = false;
                setFbPassErr(true);
                scrollArray.push('fbPass');
            }


            else if(!bankStatement){
                isValid = false;
                setBankStatementErr(true);
                scrollArray.push('bankStatement');
            }
            else if(!bizLicense){
                isValid = false;
                setBizLicenseErr(true);
                scrollArray.push('bizLicense');
            }
            else if(!certOfIncorp){
                isValid = false;
                setCertOfIncorpErr(true);
                scrollArray.push('certOfIncorp');
            }
            else if(!gst){
                isValid = false;
                setGstErr(true);
                scrollArray.push('gst');
            }
            else if(!pan){
                isValid = false;
                setPanErr(true);
                scrollArray.push('pan');
            }
            else if(!adhar){
                isValid = false;
                setAdharErr(true);
                scrollArray.push('adhar');
            }
            else if(!utilityBill){
                isValid = false;
                setUtilityBillErr(true);
                scrollArray.push('utilityBill');
            }
              else if(!wpProfileImg){
                isValid = false;
                setWpProfileImgErr(true);
                scrollArray.push('wpProfileImg')
            }
            else if(!metaAccountName){
                isValid = false;
                setMetaAccountNameErr(true);
                scrollArray.push('metaAccountName');
            }
            else if(!bizName){
                isValid = false;
                setBizNameErr(true);
                scrollArray.push('bizName');
            }
            else if(!country){
                isValid = false;
                setCountryErr(true);
                scrollArray.push('country');
            }
            else if(!bizWebsite){
                isValid = false;
                setBizWebsiteErr(true);
                scrollArray.push('bizWebsite');
            }
            else if(!whatsappNo){
                isValid = false;
                setWhatsappNoErr(true);
                scrollArray.push('whatsappNo')
            }
            else if(!wpBizAccountName){
                isValid = false;
                setWpBizAccountNameErr(true);
                scrollArray.push('wpBizAccountName');
            }
            else if(!wpProfileDisplayName){
                isValid = false;
                setWProfileDisplayNameErr(true);
                scrollArray.push('wpProfileDisplayName');
            }
            else if(!bizCategory){
                isValid = false;
                setBizCategoryErr(true);
                scrollArray.push('bizCategory');
            }
            else if(!wpWebsite){
                isValid = false;
                setwpWebsiteErr(true);
                scrollArray.push('  '); 
            }
            else if(!bizDescription){
                isValid = false;
                setBizDescriptionErr(true);
                scrollArray.push('bizDescription');
            }

            if(scrollArray.length > 0){
                console.log("scrol", scrollArray[0]);
                document.getElementById(scrollArray[0])?.scrollIntoView({ behavior:'smooth'});
              }

              return isValid;
        }

        
         const onBoard = async(e)=>{
            e.preventDefault();
            if(isValidate()){
                console.log("Validated");
            setLoader(true);
            await fetch(process.env.REACT_APP_BASE_URL + '/department/onboard/client',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    officialName: officialName,
                    officialEmail: officialEmail,
                    officialContact: officialContact,
                    fbEmail: fbEmail,
                    fbPassword: fbPass,
                    bankStatement: bankStatement,
                    bizLicense: bizLicense,
                    certOfIncorp: certOfIncorp,
                    gst: gst,
                    pan: pan,
                    adhar: adhar,
                    utilityBill: utilityBill,
                    metaAccountName: metaAccountName,
                    metaBusinessName: bizName,
                    country: country,
                    bizWebsite: bizWebsite,
                    wpProfileImg: "",
                    wpBizAccountName: wpBizAccountName,
                    wpProfileDisplayName: wpProfileDisplayName,
                    bizCategory: bizCategory,
                    websiteOnWhatsapp: "",
                    whatsappNo: officialContact,
                    bizDescription: bizDescription,
                    socialMediaLinks: ""
         })
            }).then((res)=>res.json()).then((response)=>{
                console.log("response", response);
                if(response.message === "Record Created Successfully...!"){
                    toast.success('Submitted Successfully !');
                    setLoader(false);
                    onBoardForm();
                }
                else if(response.message === "Department name already exist...!"){
                    toast.error('Department name already exist...!');
                    setLoader(false);
                 }
                else{
                    toast.warning("Something went wrong, try again");
                    setLoader(false);
                }
            }).catch((Err)=>{
                console.log("Error while onboarding client", Err);
                setLoader(false);
            })
         }
         else{
            setLoader(false);
         }
        }




  return (
    <div className='Onboard'>
        {/* <ToastContainer/> */}
        <Container className='h-100'>
            <Row className='h-100 justify-content-center align-items-center'>
                <Card className='my-5'>
                    <h2>ITGOA BUSINESS ONBOARDING</h2>
                <Form onSubmit={onBoard}>
                    <Row className='mb-4'>
                        <h6><Icon.TelephoneFill className='me-2' size={14}/>Contact Information</h6>
                        <Col md={4}>
                            <Form.Group className='mb-3' id="officialName">
                                <Form.Label>Entity official Name</Form.Label>
                                <Form.Control value={officialName} placeholder='Enter name'
                                 onChange={(e)=>{if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                                 e.target.value.trim()
                                ) {
                                    setOfficialName(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setOfficialName(e.target.value);
                              }
                            e.target.value.length>0 ? setOfficialNameErr(false): setOfficialNameErr(true)}}/>
                                { officialNameErr ? <p className='errMsg'>Please enter enitity official name</p>:''}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className='mb-3' id="officialEmail">
                                <Form.Label>Entity official Email</Form.Label>
                                <Form.Control value={officialEmail} placeholder='Enter email id'
                                    onChange={(e)=>{ setOfficialEmail(e.target.value); 
                                    e.target.value.length>0 ? setOfficialEmailErr(false) : setOfficialEmailErr(true)}}/>
                            { officialEmailErr ? <p className='errMsg'>Please enter official email</p>:''}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className='mb-3' id='officialContact'>
                            <Form.Label>Official Contact Number</Form.Label>
                            <Form.Control value={officialContact} placeholder='Enter official contact number' 
                             onChange={(e)=>{  if(e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10){
                                    setOfficialContact(e.target.value)
                                }else if (e.target.value.length === 0){
                                    setOfficialContact(e.target.value)
                                }
                             e.target.value.length>0 ? setOfficialContactErr(false) : setOfficialContactErr(true); }}/>
                             {officialContactErr ? <p className='errMsg'>Please enter official contact Number</p>:''}
                            </Form.Group>
                        </Col>
                    </Row>
    

                    <Row className='mb-4'>
                    <h6><Icon.Facebook className='me-2' size={14}/>Social Media</h6>
                        <Col md={4}>
                            <Form.Group className='mb-3' id="fbEmail">
                                <Form.Label>Facebook Registered Email</Form.Label>
                                <Form.Control value={fbEmail} placeholder='Enter facebook email'
                                    onChange={(e)=>{ setFbEmail(e.target.value); 
                                    e.target.value.length>0 ? setFbEmailErr(false) : setFbEmailErr(true)}}/>
                                { fbEmailErr ? <p className='errMsg'>Please enter facebook account email</p>:'' }
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className='mb-3' id='fbPass'>
                                <Form.Label>Facebook Password</Form.Label>
                                <Form.Control value={fbPass} placeholder='Enter facebook password'
                                    onChange={(e)=>{ setFbPass(e.target.value);
                                    e.target.value.length>0 ? setFbPassErr(false): setFbPassErr(true) }}/>
                                {fbPassErr ? <p className='errMsg'>please enter facebook account password</p> :''}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <h6><Icon.FileEarmarkImage className='me-2' size={14}/>Documents/Images required</h6>
                        {/* <Col md={3}>
                            <Form.Group className='mb-3' id="bankStatement">
                                <Form.Label>Business Bank Statement</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange}/>
                                {uploadState === "uploading" && <p>Uploading...</p>}
                                {uploadState === "done" && <p>Upload successful! <a href={fileUrl}>View file</a></p>}
                                {uploadState === "error" && <p>Upload failed. Please try again.</p>}
                            </Form.Group>
                        </Col>  */}

                        <Col md={3} className='mb-4' id="bankStatement">
                            <FileUploader
                                label="Business Bank Statement"
                                multiple={false}
                                fileTypes={['image', 'pdf']}
                                maxSizeMB={5}
                                onUploadSuccess={(result) => console.log("Upload success:", result)}
                                onError={(err) => console.error("Upload error:", err)}
                                onFilesChange={(files) =>setBankStatement(files[0]?.url)}
                                />
                            { bankStatementErr ? <p className='errMsg'>Please upload bank statement</p> : ''}

                        </Col>

                        <Col md={3} className='mb-4' id="bizLicense">
                            <FileUploader
                                label="Bank License"
                                multiple={false}
                                fileTypes={['image', 'pdf']}
                                maxSizeMB={5}
                                onUploadSuccess={(result) => console.log("Upload success:", result)}
                                onError={(err) => console.error("Upload error:", err)}
                                onFilesChange={(files) =>setBizLicense(files[0]?.url)}
                                />
                                { bizLicenseErr ? <p className='errMsg'>Please upload business license</p> : ''}
                           
                        </Col>

                        <Col md={3} className='mb-4' id="certOfIncorp">
                            <FileUploader
                                label="Certificate of incorporation"
                                multiple={false}
                                fileTypes={['image', 'pdf']}
                                maxSizeMB={5}
                                onUploadSuccess={(result) => console.log("Upload success:", result)}
                                onError={(err) => console.error("Upload error:", err)}
                                onFilesChange={(files) =>setCertOfIncorp(files[0]?.url)}
                                />
                                { certOfincorpErr ? <p className='errMsg'>Please of certificate of incorporation</p> :''}
                        </Col>

                        <Col md={3} className='mb-4' id="gst">
                        <FileUploader
                            label="GST Certificate"
                            multiple={false}
                            fileTypes={['image', 'pdf']}
                            maxSizeMB={5}
                            onUploadSuccess={(result) => console.log("Upload success:", result)}
                            onError={(err) => console.error("Upload error:", err)}
                            onFilesChange={(files) =>setGst(files[0]?.url)}
                            />
                            { gstErr ? <p className='errMsg'>Please upload GST certificate</p> :''}
                        </Col>
                        
                        <Col md={3} className='mb-4' id="pan">
                            <FileUploader
                                label="Pan Card"
                                multiple={false}
                                fileTypes={['image', 'pdf']}
                                maxSizeMB={5}
                                onUploadSuccess={(result) => console.log("Upload success:", result)}
                                onError={(err) => console.error("Upload error:", err)}
                                onFilesChange={(files) => setPan(files[0]?.url)}
                                />
                                { panErr ? <p className='errMsg'>Please upload pan card</p> :''}
                            
                        </Col>

                        <Col md={3} className='mb-4' id='adhar'>
                        <FileUploader
                            label="Adhar Card"
                            multiple={false}
                            fileTypes={['image', 'pdf']}
                            maxSizeMB={5}
                            onUploadSuccess={(result) => console.log("Upload success:", result)}
                            onError={(err) => console.error("Upload error:", err)}
                            onFilesChange={(files) =>setAdhar(files[0]?.url)}
                            />
                            {adharErr ? <p className='errMsg'>Please upload pan card</p> :''}
                        </Col>

                        <Col md={3} className='mb-4' id="utilityBill">
                            <FileUploader
                                label="Utility Bill"
                                multiple={false}
                                fileTypes={['image', 'pdf']}
                                maxSizeMB={5}
                                onUploadSuccess={(result) => console.log("Upload success:", result)}
                                onError={(err) => console.error("Upload error:", err)}
                                onFilesChange={(files) =>setUtilityBill(files[0]?.url)}
                                />
                                {utilityBillErr ? <p className='errMsg'>Please upload utility bill</p> :''}
                        </Col>

                        <Col md={3} id='wpProfileImg'>
                            <FileUploader
                                label="Whatsapp Profile Image"
                                multiple={false}
                                fileTypes={['image', 'pdf']}
                                maxSizeMB={5}
                                onUploadSuccess={(result) => console.log("Upload success:", result)}
                                onError={(err) => console.error("Upload error:", err)}
                                onFilesChange={(files) =>setWpProfileImg(files[0]?.url)}
                                />
                                {wpProfileImgErr ? <p className='errMsg'>Please upload whatsapp profile image</p> :''}
                           
                        </Col>
                    </Row>


                    <Row className='mb-3'>
                        <h6><Icon.BuildingFill className='me-2' size={14}/>Company Information</h6>
                        <Col md={4} >
                            <Form.Group className='mb-3' id='metaAccountName'>
                                <Form.Label>Meta (facebook) business account</Form.Label>
                                <Form.Control value={metaAccountName} placeholder='Enter meta business account'
                                   onChange={(e)=>{ setMetaAccountName(e.target.value);
                                   e.target.value.length >0 ? setMetaAccountNameErr(false): setMetaAccountNameErr(true) }}/>
                             {metaAccountNameErr ? <p className='errMsg'>Please enter meta business acc name</p> :''}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className='mb-3' id='bizName'>
                                <Form.Label>Business Name</Form.Label>
                                <Form.Control value={bizName} placeholder='Enter business name'
                                   onChange={(e)=>{ setBizName(e.target.value);
                                e.target.value.length>0 ? setBizNameErr(false) : setBizNameErr(true) }}/>
                            {bizNameErr ? <p className='errMsg'>Please enter business name</p> :''}
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className='mb-3' id="country">
                                <Form.Label>Country</Form.Label>
                                <Select isMulti={false} isSearchable={true}  options={countryOptions}
                                placeholder={<div>Select any country</div>} onChange={(option)=>{ setCountry(option?.value);
                                setCountryErr(false);}}/>
                                {countryErr ? <p className='errMsg'>Please select country</p> :''}
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className='mb-3' id="bizWebsite">
                                <Form.Label>Business website or profile page link</Form.Label>
                                <Form.Control value={bizWebsite} placeholder='Enter business website link'
                                   onChange={(e)=>{ setBizWebsite(e.target.value);
                                e.target.value.length>0 ? setBizWebsiteErr(false): setBizWebsiteErr(true) }}/>
                            {bizWebsiteErr ? <p className='errMsg'>Please enter business webiste link</p> :''}
                            </Form.Group>
                        </Col> 
                    </Row>


                    <Row className='mb-3'>
                        <h6><Icon.Whatsapp className='me-2' size={14}/>WhatsApp business profile</h6>
                        
                        <Col md={4}>
                            <Form.Group className='mb-3' id='whatsappNo'>
                            <Form.Label>Whatsapp Number</Form.Label>
                            <Form.Control value={whatsappNo} placeholder='Enter whatsapp number' 
                             onChange={(e)=>{  if(e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10){
                                    setWhatsappNo(e.target.value)
                                }else if (e.target.value.length === 0){
                                    setWhatsappNo(e.target.value)
                                }
                             e.target.value.length>0 ? setWhatsappNoErr(false) : setWhatsappNoErr(true); }}/>
                             {whatsappNoErr ? <p className='errMsg'>Please enter whatsapp number</p>:''}
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className='mb-3' id="wpBizAccountName">
                                <Form.Label>WhatsApp business account name</Form.Label>
                                <Form.Control value={wpBizAccountName} placeholder='Enter whatsapp account name'
                                  onChange={(e)=>{ setWpBizAccountName(e.target.value);
                                  e.target.value.length>0 ? setWpBizAccountNameErr(false): setWpBizAccountNameErr(true); }}/>
                                  {wpBizAccountNameErr ? <p className='errMsg'>Please enter whatsapp business account name</p> :''}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className='mb-3' id="wpProfileDisplayName">
                                <Form.Label>WhatsApp Business profile display name</Form.Label>
                                <Form.Control value={wpProfileDisplayName} placeholder='Enter whatsapp profile display name'
                                  onChange={(e)=>{ setWProfileDisplayName(e.target.value);
                                  e.target.value.length>0 ? setWProfileDisplayNameErr(false) : setWProfileDisplayNameErr(true) }}/>
                                  {wpProfileDisplayNameErr ? <p className='errMsg'>Please enter whatsapp profile display name</p>:''}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group className='mb-3' id="bizCategory">
                                <Form.Label>Business Category</Form.Label>
                                <Select isMulti={false} isSearchable={true} options={categoryOptions} placeholder={<div>Select any category</div>}
                                  onChange={(option)=>{ setBizCategory(option?.value); setBizCategoryErr(false);}}/>
                                 { bizCategoryErr ? <p className='errMsg'>Please select business category</p> :""}
                            </Form.Group>
                        </Col>

                        <Col md={8}>
                            <Form.Group className='mb-3' id="wpWebsite">
                                <Form.Label>Whatsapp Website (This website will be visible to people on WhatsApp.)</Form.Label>
                                <Form.Control value={wpWebsite} placeholder='Enter whatsapp website link'
                                  onChange={(e)=>{ setwpWebsite(e.target.value); 
                                  e.target.value.length>0 ? setwpWebsiteErr(false) :  setwpWebsiteErr(true)}}/>
                                  {wpWebsiteErr ? <p className='errMsg'>Please enter whatsapp website</p>:''}
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className='mb-3' id="bizDescription">
                                <Form.Label>Business Description</Form.Label>
                                <Form.Control value={bizDescription} placeholder='Enter description'
                                  onChange={(e)=>{ setBizDescription(e.target.value); 
                                  e.target.value.length>0 ? setBizDescriptionErr(false) :  setBizDescriptionErr(true)}}/>
                                  {bizDescriptionErr ? <p className='errMsg'>Please enter business description</p>:''}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button type='submit' className='primaryBtn mx-auto'>
                        <Icon.Whatsapp className='me-2'/>Onboard Now
                    </Button>
                    </Form>
                </Card>

            </Row>
        </Container>
    </div>
  )
}

export default Onboard;