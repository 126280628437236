import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Form, Spinner } from 'react-bootstrap';
import Pagination from '../Common/Pagination'; 
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import edit from "../../Assets/edit.svg";
import deletes from "../../Assets/delete.svg";
import { useNavigate } from 'react-router-dom';
import useApiCall from "../../Hooks/useApiCall";

export const ManageCategory = () => {

    const { apiCall } = useApiCall()
    const navigate = useNavigate()

    const [showAdd, setShowAdd]  = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [loader, setLoader] = useState(false);
  
    // States for Pagination ----------
        const [pageNo, setPageNo] = useState(1);
        const [docPerPage, setDocPerPage] = useState(10);
        const [noOfPages, setNoOfPages] = useState();
  
    // States of Add Role ----------------
        const [id, setId] = useState();
        const [categoryList, setCategoryList] = useState([])
        const [filterList, setFilterList] = useState([])
        const [filterCategory, setFilterCategory] = useState("")
        const [categoryName, setCategoryName] = useState("");
  
    // States of Add User Error ----------------
        const [categoryNameErr, setcategoryNameErr] = useState(false)
  
  // States of Delete user -----------------------
       const [delId, setDelId] = useState();
  
    useEffect(()=>{
      getAllCategorties();
    },[pageNo, docPerPage])
  
    const getAllCategorties = async() =>{

        try {
            setLoader(true);
            
            const response = await apiCall({
                apiGroup:'category',
                apiRoute:'getCategories',
                queryParams:`pageNo=${pageNo}&docPerPage=${docPerPage}`,
                payload:{
                    categoryName : ""
                  }
            })

            setCategoryList(response.data.users)
            setNoOfPages(response.data.noOfPages);
            setLoader(false);
        } catch (err) {
            console.log("Error while getting users", err);
            setLoader(false);
        }

        // await fetch(process.env.REACT_APP_BASE_URL + `/category/filter?pageNo=${pageNo}&docPerPage=${docPerPage}`,{
        //     method:'POST',
        //     body:JSON.stringify({
        //         "categoryName" : ""
        //       })
        // }).then((res)=>res.json()).then((response)=>{
        //     setCategoryList(response.data.users)
        //     setNoOfPages(response.data.noOfPages);
        //     setLoader(false);
        // }).catch((err)=>{
        //     console.log("Error while getting users", err);
        //     setLoader(false);
        // })
    }
  
    const closeAddModal = () =>{
        setCategoryName("")
        
        setcategoryNameErr(false);
        
        setShowAdd(false);
        setShowEdit(false);
    }

    const closeFilterCategoryModel = () =>{
        setShowFilter(false)
    }
  
    const addValidate=()=>{
        let isValid = true;
        if(categoryName.length === 0){
            isValid = false;
            setcategoryNameErr(true); 
          }
        return isValid;
    }
  
    const editValidate=()=>{
        let isValid = true;
        if(categoryName.length === 0){
            isValid = false;
            setcategoryNameErr(true); 
        }
        return isValid;
    }
  
    const addCategory=async()=>{
        setLoader(true);
      
        if(addValidate()){

            try {
                
                const response = await apiCall({
                    apiGroup:'category',
                    apiRoute:'addCategory',
                    payload:{
                        categoryName:categoryName
                      }
                })

                if(response.message === "Record Created Successfully...!"){
                    toast.success("Category added successfully");
                      setLoader(false);
                      closeAddModal();
                      getAllCategorties();
                  }
            } catch (error) {
                console.log("Err while adding user", error);
                setLoader(false);
            }

            // await fetch(process.env.REACT_APP_BASE_URL + '/category/create',{
            //     method:'POST',
            //     headers:{'Content-Type':'application/json'},
            //     body:JSON.stringify({
            //       categoryName:categoryName
            //     })
            // }).then((res)=>res.json()).then((response)=>{
            //     if(response.message === "Record Created Successfully...!"){
            //       toast.success("Category added successfully");
            //         setLoader(false);
            //         closeAddModal();
            //         getAllCategorties();
            //     }
            // }).catch((err)=>{
            //     console.log("Err while adding user", err);
            //     setLoader(false);
            // }) 
        }
        else{
            setLoader(false);
        }
    }
  
    /* ----- Edit Modal --------*/
    const setEditData=(itm)=>{
        setId(itm?._id);
        setCategoryName(itm?.categoryName)
        }
  
    const editCategory= async ()=>{
        setLoader(true);
        if(editValidate()){

            try {
                
                const response = await apiCall({
                    apiGroup:'category',
                    apiRoute:'editCategory',
                    payload:{
                        id:id,
                        categoryName:categoryName
                      }
                })

                if(response.message === "Record Updated Successfully...!"){
                    toast.success("Category updated successfully");
                    setLoader(false);
                    setShowEdit(false);
                    closeAddModal();
                    getAllCategorties()
                }

            } catch (error) {
                console.log("Err while updating user", error);
                setLoader(false);
            }

        // await fetch(process.env.REACT_APP_BASE_URL + '/category/update',{
        //     method:'POST',
        //     headers:{'Content-Type':'application/json'},
        //     body:JSON.stringify({
        //         id:id,
        //         categoryName:categoryName
        //     })
        // }).then((res)=>res.json()).then((response)=>{
        //     if(response.message === "Record Updated Successfully...!"){
        //         toast.success("Category updated successfully");
        //         setLoader(false);
        //         setShowEdit(false);
        //         closeAddModal();
        //         getAllCategorties()
        //     }
        // }).catch((err)=>{
        //     console.log("Err while updating user", err);
        //     setLoader(false);
        // }) 
    }
    else{
        setLoader(false);
    }
    }
  
    const deleteConsumer=async()=>{
        setLoader(true);

        try {
            
            const response = await apiCall({
                apiGroup:'category',
                apiRoute:'deleteCategory',
                extendedRoute:`/${delId}`,
                payload:{}
            })

            if(response.message === "Record Delete Successfully...!"){
                toast.success("Category deleted successfully !");
                setShowDel(false);
                getAllCategorties();
            }
            setLoader(false);
        } catch (error) {
            console.log("Err while adding user", error);
            setLoader(false);
        }

            // await fetch(process.env.REACT_APP_BASE_URL + `/category/delete/${delId}`,{
            //     method:'POST',
            //     headers:{'Content-Type':'application/json'},
            // }).then((res)=>res.json()).then((response)=>{
            //     if(response.message === "Record Delete Successfully...!"){
            //         toast.success("Category deleted successfully !");
            //         setShowDel(false);
            //         getAllCategorties();
            //     }
            //     setLoader(false);
            // }).catch((err)=>{
            //     console.log("Err while adding user", err);
            //     setLoader(false);
            // }) 
        }
    
        // function for pagination
        const handlePageChange = (pageNumber) => {
            setPageNo(pageNumber);
        };
    
        const handleDocsPerPage = (docsPageProp) => {
            setPageNo(1)
            setDocPerPage(docsPageProp);
        }

    // Filter API
    const getfilteredCategory = async() =>{
        await fetch(process.env.REACT_APP_BASE_URL + `/category/filter?pageNo=${pageNo}&docPerPage=${docPerPage}`,{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                categoryName:filterCategory.value
            })
        }).then((res)=>res.json())
        .then((response)=>{
            setCategoryList(response.data.users)
            setShowFilter(false)
        })
        .catch((err)=>{
            console.log("err",err);
            setLoader(false)
        })
    }

    useEffect(()=>{
        filterOptions()
    },[])

    const filterOptions = async() =>{

        try {
            
            const response = await apiCall({
                apiGroup:'category',
                apiRoute:'categoryFilterOptions',
            })

            setFilterList(response.data.categoryNameOptions)
        } catch (error) {
            console.log("err",error);
            setLoader(false)
        }

        // await fetch(process.env.REACT_APP_BASE_URL + '/category/filterOptions',{
        //     method:'GET',
        //     headers:{'Content-Type':'application/json'},
        // }).then((res)=>res.json())
        // .then((response)=>{
        //     setFilterList(response.data.categoryNameOptions)
        // })
        // .catch((err)=>{
        //     console.log("err",err);
        //     setLoader(false)
        // })
    }

    const clearFilter = () =>{
        setFilterCategory("")
    }
  
  return (
    <div className='ManageUsers outletPadding'>
        {/* <ToastContainer/> */}
        <Container>
                <Row className='justify-content-end align-items-center mb-4'>
                    <Col md={6} className='d-flex'>
                        <Button className='primaryBtn' onClick={()=>navigate(-1)}>
                            <Icon.ArrowLeft className='me-2'/>Back
                        </Button>
                    </Col>
                    <Col md={6} className='d-flex justify-content-end'>
                        <Button className='primaryBtn' onClick={()=>setShowAdd(true)}>
                            <Icon.PersonAdd className='me-2'/>Add User Category
                        </Button>
                    </Col>
                </Row>

                <div className="outer-wrapper mx-auto">
                {
                   loader ? <center><Spinner variant='primary' className='mt-3'/></center> :
                    <div className="table-wrapper" data-aos="fade-up">
                        <table>
                            <thead>
                                <th>Sr No</th>
                                <th> User Category</th>
                                <th>Actions</th>
                            </thead>
                            {
                                categoryList!== null && categoryList !== undefined && categoryList.length > 0 ? categoryList.map((itm, index) => {
  
                                        const { categoryName } = itm
  
                                        return (
                                            <tr key={Math.random() * 999999999}>
                                                <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                                <td>{categoryName}</td>
                                                <td>
                                                    <img src={edit} className='me-3 icon' style={{ cursor:'pointer' }}
                                                        onClick={()=>{setShowEdit(true); setEditData(itm)}}/>
                                                    <img src={deletes} className='me-2 icon' style={{ cursor:'pointer' }}
                                                        onClick={()=>{setShowDel(true); setDelId(itm?._id)}}/>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : 'No data Found'
                            }
  
                        </table>
                    </div>
                }
                    
                </div>
                <Pagination currentPage={pageNo} totalPages={noOfPages}
                    onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage}
                    />
    
    {/* ---------------- Add User Modal ----------------------------------------------------------- */}
            <Modal size='md' show={showAdd} backdrop='static' keyboard={false} centered onHide={closeAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title> <h4 className='mb-0'><Icon.PersonAdd size={20} className='me-2'/>Add Category</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Category name</Form.Label>
                            <Form.Control 
                              placeholder='Enter category name' 
                              name='categoryName'
                              value={categoryName}
                              onChange={(e)=> {if (
                                !e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&  e.target.value.trim() ) {
                                    setCategoryName(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setCategoryName(e.target.value);
                                }
                             e.target.value.length > 0 ? 
                              setcategoryNameErr(false) :
                              setcategoryNameErr(true); 
                              }} 
                              />
                            {categoryNameErr ? <p className='errMsg'>Please enter category name</p> : ''}
                        </Form.Group> 
                        </Col>
                    </Row>   
                </Modal.Body>
  
                <Modal.Footer>
                    <Button className='primaryBtn' onClick={addCategory} disabled={loader}> 
                    { loader ? <Spinner size='sm'/> : <Icon.PersonAdd className='me-2'/> }Add category</Button>
                </Modal.Footer>
            </Modal>
  
    {/* ---------------- Edit User Modal ----------------------------------------------------------- */}
            <Modal size='md' show={showEdit} backdrop='static' keyboard={false} centered onHide={closeAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title> <h4 className='mb-0'><Icon.PencilSquare size={20} className='me-2'/>Edit Category</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Category name</Form.Label>
                            <Form.Control 
                              placeholder='Enter first name' 
                              name='categoryName' 
                              value={categoryName} 
                              onChange={(e)=> {
                                if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&  e.target.value.trim() ) {
                                    setCategoryName(e.target.value);
                                } else if (e.target.value.length === 0) {
                                  setCategoryName(e.target.value);
                                }
                             e.target.value.length > 0 ? setcategoryNameErr(false) : setcategoryNameErr(true); }} />
                            {categoryNameErr ? <p className='errMsg'>Please enter category</p> : ''}
                        </Form.Group> 
                        </Col>
                    </Row>   
                </Modal.Body>
  
                <Modal.Footer>
                    <Button className='primaryBtn' onClick={editCategory} disabled={loader}> 
                    { loader ? <Spinner size='sm'/> : <Icon.PencilSquare className='me-2'/> }Edit Category</Button>
                </Modal.Footer>
            </Modal>
  
    {/* ---------------- Delete User Modal --------------------------------------------------------- */}
            <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={() => setShowDel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> <h4 className='mb-0'><Icon.Trash size={20} className='me-2'/>Delete User</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='text-center mb-0'>Are you sure to delete ?</h4>    
                </Modal.Body>
  
                <Modal.Footer>
                    <Button className='primaryBtn' onClick={deleteConsumer} disabled={loader}> 
                    { loader ? <Spinner size='sm'/> : <Icon.Trash className='me-1'/> }Delete</Button>
                </Modal.Footer>
            </Modal>

    {/* ---------------- Filter Model -------------------------------------------------------------- */}
    {/* <Modal size='md' show={showFilter} backdrop='static' keyboard={false} centered onHide={closeFilterCategoryModel}>
        <Modal.Header closeButton>
            <Modal.Title> <h4 className='mb-0'><Icon.Funnel size={20} className='me-2'/>Filter</h4> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={12}>
                    <Form.Group className='mb-3'>
                    <Form.Label>Category name</Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        name='categoryName'
                        value={filterCategory}
                        options={filterList}
                        onChange={(option) => {
                            setFilterCategory(option);
                        }}
                    />
                    </Form.Group> 
                </Col>
            </Row>   
        </Modal.Body>
  
        <Modal.Footer>
            <Button className='primaryBtn' onClick={clearFilter} disabled={loader}><Icon.XCircle className='me-2'/>Reset</Button>
            <Button className='primaryBtn' onClick={getfilteredCategory} disabled={loader}> 
                { loader ? <Spinner size='sm'/> : <Icon.Funnel className='me-2'/> }Submit</Button>
            </Modal.Footer>
    </Modal> */}
    
    </Container>
    </div>
  )
}
