import { useDispatch, useSelector } from "react-redux";
import { apiConfig, getApiUrl } from "../Utils/apiConfig"; // Import API config
import { useNavigate } from "react-router-dom";
import {setLogout} from '../Features/Auth/LoginSlice';
import { toast } from "react-toastify";

const useApiCall = () => {
  const token =  useSelector(state => state?.loginData?.userData?.data?.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiCall = async ({apiGroup, apiRoute, queryParams = "", payload = null,   extraHeaders = {},extendedRoute = ""}) => {
// const apiCall = async (apiGroup, apiRoute, { queryParams = "", payload = null, extraHeaders = {} } = {}) => {
    const apiDetails = apiConfig[apiGroup]?.[apiRoute];
    if (!apiDetails) {
      throw new Error(`API config for ${apiGroup}.${apiRoute} not found.`);
    }

    const { path, method } = apiDetails;
    const endpoint = getApiUrl(path, queryParams,extendedRoute);

    try {
      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
          ...extraHeaders,
        },
      };

      if (["POST", "PUT"].includes(method) && payload) {
        options.body = JSON.stringify(payload);
      }

      const response = await fetch(endpoint, options);
      return await handleResponse(response);
    } catch (err) {
      console.error("Error during API call:", err);
      const errorObj = JSON.parse(err)
      if(errorObj.status === 403){
        handleLogout()
      }
      throw err; // Rethrow for parent to handle
    }
  };

  const handleResponse = async (response) => {
    if (response.status === 401) {
        dispatch(setLogout({}));
        navigate("/");
        return Promise.reject("Unauthorized, logging out...");
    }
    if (!response.ok) {
      const errorText = await response.text();
      console.error(`API Error: ${errorText}`);
      return Promise.reject(errorText);
    }
    return await response.json();
  };

  const handleLogout = () => {
    toast.error("Authorization Expired , Logging Out")
    dispatch(setLogout({}));
    navigate("/");
  };

  return { apiCall };
};

export default useApiCall;
