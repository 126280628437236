import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row, Form, Spinner, InputGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { setLogout } from '../Auth/LoginSlice';
import { setActiveKey } from './ActiveKeySlice';
import { useNavigate } from 'react-router-dom';
import useApiCall from '../../Hooks/useApiCall';

const Profile = () => {

    const { apiCall } = useApiCall()

    const userState = useSelector((state)=> state.loginData.userData?.data);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [deptDetails, setDeptDetails] = useState([])
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);

    const [newPass, setNewPass] = useState();
    const [confirmPass, setConfirmPass] = useState();

    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);

    const [newPassErr, setNewPassErr] = useState(false);
    const [confirmPassErr, setConfirmPassErr] = useState(false);

    const closeModal =()=>{
        setNewPass('');
        setConfirmPass('');
        setNewPassErr(false);
        setConfirmPassErr(false);

        setShow(false);
    }

    const ifValidate =()=>{
        
        let isValid=true;
        if(!newPass || newPass?.length < 8){
            isValid = false;
            setNewPassErr(true);
        }
        else if(!confirmPass || confirmPass?.length < 8){
            isValid = false;
            setConfirmPassErr(true);
        }
        else if(newPass!==confirmPass){
            isValid = false;
            toast.error('Sorry, New & Confirm password doesnt matched');
        }
       
        return isValid;
    }
    
    const updatePass = async ()=>{
        setLoader(true);
        if(ifValidate()){

            try {
                
                const response = await apiCall({
                    apiGroup:'user',
                    apiRoute:'changePassword',
                    payload:{  id: userState?.userId, password: newPass,}
                })

                if(response.message==='User not found'){
                    setLoader(false);
                    toast.error('Sorry, employee not found');
                }
                if(response.message==='Invalid password'){
                    setLoader(false);
                    toast.error('Sorry, Old password is incorrect');
                }
                if(response.message==='Record Updated Successfully...!'){
                    setLoader(false);
                    toast.success('Password updated successfully');
                    setShow(false);
                    setTimeout(() => {
                        dispatch(setLogout(null));
                        dispatch(setActiveKey(1));
                        navigate('/');   
                    }, 2000);
                }
            } catch (error) {
                console.log("Err while updating pass", error);
                
            }

            // await fetch(process.env.REACT_APP_BASE_URL + '/emp/changePassword',{
            //     method: 'POST',
            //     headers:{'Content-Type':'application/json'},
            //     body: JSON.stringify({  empId: userState?.empId, newPassword: newPass,})
            // }).then((res)=>res.json()).then((response)=>{
            //     console.log("Response", response);
            //     if(response.message==='User not found'){
            //         setLoader(false);
            //         toast.error('Sorry, employee not found');
            //     }
            //     if(response.message==='Invalid password'){
            //         setLoader(false);
            //         toast.error('Sorry, Old password is incorrect');
            //     }
            //     if(response.message==='Password changed successfully'){
            //         setLoader(false);
            //         toast.success('Password updated successfully');
            //         setShow(false);
            //         setTimeout(() => {
            //             dispatch(setLogout(null));
            //             dispatch(setActiveKey(1));
            //             navigate('/');   
            //         }, 2000);
            //     }
            // }).catch((err)=>{
            //     console.log("Err while updating pass", err);
            // })
        }
        else{
            setLoader(false);
        }
    }

    // get department details
    useEffect(()=>{
        getDepartment()
    },[userState?.departmentId])

    const getDepartment = async() =>{

        try {
            const response = await apiCall({
                apiGroup: "department",
                apiRoute: "showDepartment",
                extendedRoute: `/${userState.departmentId}`,
              });
              setDeptDetails(response?.data);
        } catch (error) {
            console.log("error",error);
            
        }

        // await fetch(process.env.REACT_APP_BASE_URL + `/department/show/${userState?.departmentId}`,{
        //     method: 'GET',
        //     headers:{'Content-Type':'application/json'}
        // }).then((res)=>res.json()).then((response)=>{setDeptDetails(response?.data);})
        // .catch((error)=>{
        //     console.log("error",error);
        // })
    }
    console.log("deptDetails",deptDetails,userState);

  return (
    <div className='profile outletPadding'>
        {/* <ToastContainer/> */}
        <Container>
            <div className='d-flex justify-content-between'>
                <h4><Icon.PersonFill className='me-1 w-auto'/>Login Profile</h4>
                <Button className='primaryBtn w-auto' onClick={()=>setShow(true)}>
                    <Icon.Lock className='me-2'/>Update Password
                </Button>
            </div>
        
        <Row className='mt-3'>
            <Col md={6}>
                <Card>
                    <div>
                        <h6><Icon.Person className='me-2'/>Name -</h6>
                        <p>{userState?.name}</p>
                    </div>

                    <div>
                        <h6><Icon.Telephone className='me-2'/>Mobile -</h6>
                        <p>{userState?.contactNo}</p>
                    </div>

                    <div>
                       <h6><Icon.Whatsapp className='me-2'/>Whatsapp Number - </h6>
                        <p>{userState?.whatsappNo}</p>
                    </div>

                    <div>
                         <h6><Icon.Envelope className='me-2'/>Email - </h6>
                         <p>{userState?.email}</p>
                     </div>

                    <div>
                        <h6><Icon.Briefcase className='me-2'/>Role - </h6>
                        <p>{userState?.userType}</p>
                    </div>

                    <div>
                        <h6><Icon.Briefcase className='me-2'/>Department - </h6>
                        <p>{userState?.departmentName}</p>
                    </div>

                </Card>
            </Col>
        </Row>

        <div className='d-flex justify-content-between mt-5'>
                <h4><Icon.BriefcaseFill className='me-2 w-auto'/>Department Profile</h4>
            </div>
        
        <Row className='mt-3 mb-5'>
            <Col md={6}>
                <Card >
                    <div style={{display:"flex",justifyContent:"center"}}><img src={deptDetails?.profileImg}  width={40}/></div>
                    <div style={{width:"maxContent"}}>
                        <h6><Icon.Briefcase className='me-2'/>Department Name -</h6>
                        <p>{deptDetails?.departmentName}</p>
                    </div>

                    <div>
                        <h6><Icon.Whatsapp className='me-2'/>Whatsapp No. -</h6>
                        <p>{deptDetails?.whatsappNo}</p>
                    </div>

                    <div>
                       <h6><Icon.Envelope className='me-2'/>Email ID - </h6>
                        <p>{deptDetails?.email}</p>
                    </div>

                    <div>
                         <h6><Icon.GeoAlt className='me-2'/>Service Address - </h6>
                         <p>{deptDetails?.service_address}</p>
                     </div>

                     <div>
                         <h6><Icon.CreditCard className='me-2'/>Credit Point - </h6>
                         <p>{deptDetails?.credits? deptDetails?.credits : "0"}</p>
                     </div>

                    <div>
                        <h6><Icon.Link className='me-2'/>Social Media Link - </h6>
                        <p>{deptDetails?.socialMediaLinks}</p>
                    </div>

                    <div>
                        <h6><Icon.CardText className='me-2'/>Description - </h6>
                        <p>{deptDetails?.description}</p>
                    </div>

                </Card>
            </Col>
        </Row>
  </Container>


  <Modal show={show} onHide={closeModal} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <h5><Icon.Lock className='me-2'/>Update Password</h5>
        </Modal.Header>
        <Modal.Body>

            <p style={{fontSize:"12px"}}><span className='errMsg'>*</span>At least 8 characters length</p>
          <Form.Group className='mb-3'>
            <Form.Label className='noselect'>New Password<span className='errMsg'>*</span></Form.Label>
            <InputGroup>
                <Form.Control 
                    placeholder="Enter New Password" 
                    value={newPass} 
                    type={showNewPass ? "text" : "password"}
                    onChange={(e)=>  {
                        if(e.target.value.length <= 8){
                            setNewPass(e.target.value);
                        }
                        e.target.value.length > 0 ? 
                        setNewPassErr(false) : 
                        setNewPassErr(true) }}/>
                    <InputGroup.Text onClick={() => setShowNewPass(!showNewPass)}>
                        {showNewPass ? 
                        <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} 
                            onClick={() => setShowNewPass(!showNewPass)} /> :
                        <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                    </InputGroup.Text>
            </InputGroup>

            {newPassErr ? <p className='errMsg'>Please enter valid new password</p> : ''}
          </Form.Group>

          <Form.Group>
            <Form.Label className='noselect'>Confirm New Password<span className='errMsg'>*</span></Form.Label>
            <InputGroup>
                <Form.Control 
                    placeholder="Enter Confirm Password" 
                    type={showConfPass ? "text" : "password"} value={confirmPass} 
                    onChange = {(e)=>{ 
                        if(e.target.value.length <= 8){
                            setConfirmPass(e.target.value);
                        }
                        e.target.value.length>0 ? 
                        setConfirmPassErr(false) : 
                        setConfirmPassErr(true) }}/>
                <InputGroup.Text onClick={() => setShowConfPass(!showConfPass)}>
                        {showConfPass ? 
                        <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} 
                            onClick={() => setShowConfPass(!showConfPass)} /> :
                        <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                </InputGroup.Text>
            </InputGroup>

            {confirmPassErr ? <p className='errMsg'>Please enter valid confirm password</p> : ''}
          </Form.Group>


        </Modal.Body>
        <Modal.Footer>
            {
                loader ? <Spinner variant='primary'/> : <>
                <Button className='primaryBtn' onClick={updatePass}> 
                    <Icon.PencilSquare className='me-2'/>Update</Button></>
            }
          
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Profile;