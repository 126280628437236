import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import edit from "../../Assets/edit.svg";
import deletes from "../../Assets/delete.svg";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import AddUserModel from "./Modal/AddUserModel";
import { useSelector } from "react-redux";
import useApiCall from "../../Hooks/useApiCall";

const ViewDept = () => {
  const { apiCall } = useApiCall();

  const location = useLocation();
  const itm = location?.state;
  const navigate = useNavigate();

  const [deptObj, setDeptObj] = useState("");

  const [showAddWebHook, setShowAddWebHook] = useState(false);
  const [WebHookErr, setWebHookErr] = useState(false);
  const [webHookURL, setWebHookURL] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateAPILoader, setUpdateAPILoader] = useState(false);
  const [channelLoader, setChannelLoader] = useState(false);
  const [webHookLoader, setWebHookLoader] = useState(false);

  // States for form Select option ------
  const [users, setUsers] = useState();

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState();

  const closeAddModel = () => {
    setShowAddWebHook(false);
  };

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };
  useEffect(() => {
    // getAllUsers();
    getDepartmentById();
  }, []);

  const getAllUsers = async () => {
    try {
      setLoader(true);

      const response = await apiCall({
        apiGroup: "department",
        apiRoute: "viewDepartment",
        queryParams: `pageNo=${pageNo}&docPerPage=${docPerPage}&departmentId=${itm?.departmentId}`,
      });
      console.log("response at users", response);
      setUsers(response?.data?.users);
      setNoOfPages(response.data.noOfPages);
      setLoader(false);
    } catch (err) {
      console.log("Error while getting users", err);
      setLoader(false);
    }

    // await fetch(process.env.REACT_APP_BASE_URL + `/user?pageNo=${pageNo}&docPerPage=${docPerPage}&departmentId=${itm?.departmentId}`,{
    //     method:'GET',
    //     headers:{'Content-Type':'application/json'}
    // }).then((res)=>res.json()).then((response)=>{
    //     console.log("response at users", response);
    //     setUsers(response?.data?.users);
    //     setNoOfPages(response.data.noOfPages);
    //     setLoader(false);
    // }).catch((err)=>{
    //     console.log("Error while getting users", err);
    //     setLoader(false);
    // })
  };

  const getDepartmentById = async () => {
    setLoader(true);

    try {
      const response = await apiCall({
        apiGroup: "department",
        apiRoute: "showDepartment",
        extendedRoute: `/${itm.departmentId}`,
      });
      if (response.status === 200) {
        setDeptObj(response.data);
        // console.log(response.data);
        setLoader(false);
      } else {
        setDeptObj("");
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const syncWithCloud = async () => {
    try {
      setUpdateAPILoader(true);
      const response = await apiCall({
        apiGroup: "department",
        apiRoute: "syncWithCloud",
        payload: {
          departmentId: deptObj?.departmentId,
        },
      });

      if (response.status === 200) {
        getDepartmentById();
        setUpdateAPILoader(false);
      } else {
        setUpdateAPILoader(false);
      }
    } catch (error) {
      console.error(error);
      setUpdateAPILoader(false);
    }
  };

  const setUpChannel = async () => {
    setChannelLoader(true);
    try {
      const response = await apiCall({
        apiGroup: "department",
        apiRoute: "setUpChannelDetails",
        payload: {
          departmentId: deptObj?.departmentId,
          whatsappNo: deptObj?.whatsappNo,
        },
      });
      if (response.status === 200) {
        getDepartmentById();
        setChannelLoader(false);
      } else {
        setChannelLoader(false);
      }
    } catch (error) {
      console.error(error);
      setChannelLoader(false);
    }
  };

  const setUpWebHookURL = async () => {
    if (webHookURL) {
      setWebHookLoader(true);
      try {
        const response = await apiCall({
          apiGroup: "department",
          apiRoute: "setUpWebHook",
          payload: {
            departmentId: deptObj?.departmentId,
            webhookUrl: webHookURL,
          },
        });
        if (response.status === 200) {
          getDepartmentById();
          setShowAddWebHook(false);
          setWebHookURL("");
          setWebHookErr(false);
          setWebHookLoader(false);
        } else {
          setWebHookLoader(false);
        }
      } catch (error) {
        console.error(error);
        setWebHookLoader(false);
      }
    } else {
      setWebHookErr(true);
    }
  };

  const formatMobileNumber = (mobileNumber) => {
    // Convert to string to handle numeric inputs
    const mobileString = mobileNumber.toString();

    // Extract the last 10 digits
    const mobile = mobileString.slice(-10);

    // Extract the remaining portion (country code)
    const countryCode = mobileString.slice(0, -10);

    // Format and return the result
    return countryCode ? `+${countryCode} ${mobile}` : mobile;
  };

  const getChannelColor = (accountMode) => {
    switch (accountMode.toLowerCase()) {
      case "live":
        return "bg-success"; // Green dot (Bootstrap)
      case "pending":
        return "bg-danger"; // Red dot (Bootstrap)
      default:
        return "bg-danger"; // Gray dot for unknown status (Bootstrap)
    }
  };

  return (
    <div className="ViewDept outletPadding">
      {/* <ToastContainer /> */}
      {/* <AddUserModel show={showAdd} close={closeAddModel} getAllUsers = {getAllUsers} deptName={itm?.departmentName} deptId={itm?.departmentId}/> */}
      <Container>
        <Button
          className="primaryBtn mb-4"
          onClick={() => navigate(-1)}
          style={{ marginLeft: "5px" }}
        >
          <span>
            <Icon.ArrowLeft className="me-2" />
          </span>
          Back
        </Button>
        <Row>
          {loader ? (
            <center>
              <Spinner variant="primary" className="mt-3" />
            </center>
          ) : deptObj?.departmentId ? (
            <Card className="d-flex">
              <h5 className="text-center">
                <Icon.Postcard className="me-2" />
                Department Details
              </h5>
              <Row>
                <Col md={4}>
                  <h6>
                    <Icon.PersonBadge className="me-2" />
                    Channel Id -
                  </h6>
                </Col>
                <Col md={8}>
                  <p>
                    {deptObj.channel_id ?? (
                      <Button
                        className="primaryBtn"
                        disabled={channelLoader}
                        onClick={() => setUpChannel()}
                      >
                        {channelLoader ? (
                          <Spinner color="white" />
                        ) : (
                          "Add Channel ID"
                        )}
                      </Button>
                    )}
                    {deptObj.channel_id ? (
                      <span
                        className={`ms-2 ${getChannelColor(
                          deptObj?.channelDetails.account_mode
                        )}`}
                        style={{
                          display: "inline-block",
                          width: "13px",
                          height: "13px",
                          borderRadius: "50%",
                        }}
                        title="Live"
                      ></span>
                    ) : null}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <h6>
                    <Icon.Person className="me-2" />
                    Name -
                  </h6>
                </Col>
                <Col md={8}>
                  <p>{deptObj.departmentName ?? "-"}</p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <h6>
                    <Icon.Envelope className="me-2" />
                    Email -
                  </h6>
                </Col>
                <Col md={8}>
                  <p>{deptObj.email ?? "-"}</p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <h6>
                    <Icon.Telephone className="me-2" />
                    Number -
                  </h6>
                </Col>
                <Col md={8}>
                  <p>{ deptObj.whatsappNo ? formatMobileNumber(deptObj.whatsappNo) : "-"}</p>
                </Col>
              </Row>
              {deptObj?.socialMediaLinks.length > 0 && (
                <Row>
                  <Col md={4}>
                    <h6>
                      <Icon.Phone className="me-2" />
                      Social Link -
                    </h6>
                  </Col>
                  <Col md={8}>
                    <p>{deptObj.socialMediaLinks[0] ?? "-"}</p>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md={4}>
                  <h6>
                    <Icon.CardList className="me-2" />
                    Description -
                  </h6>
                </Col>
                <Col md={8}>
                  <p>{deptObj.description ?? "-"}</p>
                </Col>
              </Row>

              {deptObj.channel_id ? (
                deptObj?.service_api_key ? (
                  <>
                    <Row>
                      <Col md={4}>
                        <h6>
                          <Icon.CardList className="me-2" />
                          Service ID -
                        </h6>
                      </Col>
                      <Col md={8}>
                        <p>{deptObj.service_id ?? "-"}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <h6>
                          <Icon.CardList className="me-2" />
                          Service App ID -
                        </h6>
                      </Col>
                      <Col md={8}>
                        <p>{deptObj.service_app_id ?? "-"}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <h6>
                          <Icon.CardList className="me-2" />
                          Service API Key -
                        </h6>
                      </Col>
                      <Col md={8}>
                        <p>{deptObj.service_api_key ?? "-"}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <h6>
                          <Icon.GeoAlt className="me-2" />
                          Service Address -
                        </h6>
                      </Col>
                      <Col md={8}>
                        <p>{deptObj.service_address ?? "-"}</p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Button
                    className="primaryBtn"
                    disabled={updateAPILoader}
                    onClick={() => syncWithCloud()}
                  >
                    {updateAPILoader ? (
                      <Spinner color="white" />
                    ) : (
                      "Get Service Key"
                    )}
                  </Button>
                )
              ) : null}

              {deptObj?.service_api_key ? (
                deptObj?.webhookUrl ? (
                  <Row>
                    <Col md={4}>
                      <h6>
                        <Icon.CardList className="me-2" />
                        WebhookUrl -
                      </h6>
                    </Col>
                    <Col md={8}>
                      <p>{deptObj.webhookUrl ?? "-"}</p>
                    </Col>
                  </Row>
                ) : (
                  <Button
                    className="primaryBtn"
                    onClick={() => setShowAddWebHook(!showAddWebHook)}
                  >
                    Add Web Hook URL
                  </Button>
                )
              ) : null}
            </Card>
          ) : null}
        </Row>

        {/* <h5 className='text-center mt-5'><Icon.Person className='me-2'/>Department User List</h5>
        <Row className='justify-content-end'>
            <Col md={3}>
                <Button className='primaryBtn ms-auto me-0 me-md-3' onClick={()=>setShowAdd(true)}>
                    <Icon.PersonAdd className='me-2'/>Add User
                </Button>
            </Col>
        </Row>
            <div className="outer-wrapper mx-auto">
                {
                  loader ? <center><Spinner variant='primary' className='mt-3'/></center> :
                    <div className="table-wrapper">
                        <table>
                        <thead>
                                <th>Sr No</th>
                                <th>Profile</th>
                                <th>Name</th>
                                <th>Mobile No</th>
                                <th>Whatsapp No</th>
                                <th>Email</th>
                                <th>Department</th>
                                <th>Designation</th>
                                <th>Category</th>
                                <th>Role</th>
                                <th>Actions</th>
                        </thead>
                            {
                                users!== null && users !== undefined && users.length > 0 ? users.map((itm, index) => {

                                    const { profileImg,name,contactNo,whatsappNo, email, departmentName, designation, categoryName, userType} = itm

                                        return (
                                            <tr key={Math.random() * 999999999}>
                                                <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                                <td><img src={profileImg} alt='profile' width={40}/></td>
                                                <td>{name}</td>
                                                <td>{contactNo}</td>
                                                <td>{whatsappNo}</td>
                                                <td>{email}</td>
                                                <td>{departmentName}</td>
                                                <td>{designation}</td>
                                                <td>{categoryName}</td>
                                                <td>{userType}</td>
                                                <td>
                                                <img src={edit} className='me-3 icon' style={{ cursor:'pointer' }}/>
                                                <img src={deletes} className='me-2 icon' style={{ cursor:'pointer' }}/>
                                                </td>
                                            </tr>
                                        )
                                  })
                                  : 'No data Found'
                          }
                      </table>
                  </div>
                }
                  
            </div>
              <Pagination currentPage={pageNo} totalPages={noOfPages}
                  onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage}/> */}
      </Container>

      {/* ---------------- SetUp WebHook Modal ----------------------------------------------------------- */}
      <Modal
        size="md"
        show={showAddWebHook}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => setShowAddWebHook(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h4 className="mb-0">
              <Icon.PlusCircle size={20} className="me-2" />
              Set Up WebHook
            </h4>{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  WebHook URL<span className="errMsg">*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Enter WebHook URL"
                  name="webHookURL"
                  value={webHookURL}
                  onChange={(e) => {
                    if (e.target.value.trim()) {
                      setWebHookURL(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setWebHookURL(e.target.value);
                    }
                    e.target.value.length > 0
                      ? setWebHookErr(false)
                      : setWebHookErr(true);
                  }}
                />
                {WebHookErr ? (
                  <p className="errMsg">Please enter WebHook URL</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="primaryBtn"
            onClick={setUpWebHookURL}
            disabled={webHookLoader}
          >
            {webHookLoader ? (
              <Spinner size="sm" />
            ) : (
              <Icon.PlusCircle className="me-1" />
            )}
            SetUp Web Hook
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewDept;
