import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/logo.svg';


const ForgotPass = () => {

    //---- States For EmpId -----------------------
    const [empId, setEmpId] = useState();
    const [empIdErr, setEmpIdErr] = useState(false);

    //---- States for verify Otp -------------
    const [otp, setOtp] = useState('');
    const [otpErr, setOtpErr] = useState(false);
    
    //---- States for Final Forgot password ------------
    const [newPass, setNewPass] = useState();
    const [confirmPass, setConfirmPass] = useState();

    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);

    const [newPassErr, setNewPassErr] = useState(false);
    const [confirmPassErr, setConfirmPassErr] = useState(false);

 

    // ----- State for loadya -----------------
    const [loader, setLoader] = useState(false);


    // Mainting Flag --------------
    const [flag, setFlag]=useState('sendOtp');

    const navigate = useNavigate();


    // Functions for Send Otp ------------------
        const empIdValidate=()=>{
            let isValid = true;
            if(!empId){
                isValid = false;
                setEmpIdErr(true);
            }
            return isValid;
        }
        const sendOtp= async()=>{
            setLoader(true);
            if(empIdValidate()){
            await fetch(process.env.REACT_APP_BASE_URL + '/OTP/sendOTP',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({empId:empId})
            }).then((res)=>res.json()).then((response)=>{
                console.log("response", response);
                if(response.message==="Otp sent to your work email. OTP will be valid for 10 minutes"){
                    setLoader(false);
                    toast.success('Otp send to your work Email');
                    setFlag('verifyOtp');
                }
            }).catch((err)=>{
                console.log("Err while sendOtp", err);
            })
            }
            else{
                setLoader(false);
            }
        }

    // Functions for verify OTP -----------------------
    const otpValidate=()=>{
        let isValid = true;
        if(!otp || otp.length<6){
            isValid = false;
            setOtpErr(true);
        }
        return isValid;
    }
    const verifyOtp= async()=>{
        setLoader(true);
        if(otpValidate()){
        await fetch(process.env.REACT_APP_BASE_URL + '/OTP/verifyOTP',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({empId:empId, otp:otp})
        }).then((res)=>res.json()).then((response)=>{
            console.log("response", response);
            if(response.message==="Please enter valid OTP"){
                setLoader(false);
                setOtpErr(true);
            }
            else if(response.message==="Otp verified successfully"){
                setLoader(false);
                toast.success('Otp verified successfully !');
                setFlag('forgotPass')
            }
        }).catch((err)=>{
            console.log("Err while verifying OTP", err);
        })
        }
        else{
            setLoader(false);
        }
    }

    // function for Submiting new pass after verifying OTP -------------
    const isForgotValid=()=>{
        let isValid = true;
        if(!newPass){
            isValid = false;
            setNewPassErr(true);
        }
        else if(!confirmPass){
            isValid = false;
            setConfirmPassErr(true);
        }
        else if(newPass!==confirmPass){
            isValid = false;
            toast.error('Sorry, New & Confirm password doesnt matched');
        }
        return isValid;
    }

        const forgotPass=async()=>{
            setLoader(true);
            if(isForgotValid()){
            await fetch(process.env.REACT_APP_BASE_URL + '/emp/forgotPassword',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({empId:empId, password:newPass})
            }).then((res)=>res.json()).then((response)=>{
                console.log("response", response);
                if(response.message==="password change successfully"){
                    setLoader(false);
                    toast.success('Password updated successfully !');
                    setTimeout(() => {
                        navigate('/');
                    }, 2000);
                }
            }).catch((err)=>{
                console.log("Err while forgot password", err);
            })
            }
            else{
                setLoader(false);
            }
        }

  return (
    <div className='ForgotPass'>
        {/* <ToastContainer/> */}
        <Container>
            <Row className='justify-content-center'>
                <Col md={4}>
                    <Card>
                            <img src={logo} width={200}/>
                        <h3>Forgot Password</h3>
                   { flag==='sendOtp' ? <div data-aos="fade-up" className='mt-2'>
                        <h5>Send OTP</h5>
                        <p>Get otp on your work email to proceed</p>
                        <Form.Group className='mb-3 px-3'>
                            <Form.Label>Emp ID<span> *</span></Form.Label>
                            <Form.Control placeholder='Enter your emp ID' value={empId} onChange={(e)=>
                                { setEmpId(e.target.value); e.target.value.length>0 ? setEmpIdErr(false) : setEmpIdErr(true)}}/>
                            {empIdErr ? <p className='errMsg'>Please enter your valid empId</p> :''}
                        </Form.Group>
                        {
                            loader ? <center><Spinner/></center> :
                            <Button className='primaryBtn mx-auto' onClick={sendOtp}><Icon.Send className='me-2'/>Send OTP</Button>
                        }
                    </div> : ''}

           { // ------------------------ Verify OTP ----------------------------------------------------------------
                 flag==='verifyOtp' ? <div data-aos={flag==="verifyOtp"??'fade-up'} className='mt-2'>
                                <h5>Verify OTP</h5>
                                <p>Verify Otp, otp will valid for 10 mins only.</p>
                                <Form.Group className='mb-3 px-3'>
                                    <Form.Label>OTP<span> *</span></Form.Label>
                                    <Form.Control placeholder='Enter 6 digit OTP' value={otp} onChange={(e)=>
                                        { if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 6
                                          ){ setOtp(e.target.value);}
                                    e.target.value.length>0 ? setOtpErr(false) : setOtpErr(true)}}/>
                                    {otpErr ? <p className='errMsg'>Please enter 6 digit valid OTP</p> :''}
                                </Form.Group>
                                {
                                    loader ? <center><Spinner/></center> :
                                    <Button className='primaryBtn mx-auto' onClick={verifyOtp}><Icon.Send className='me-2'/>Verify OTP</Button>
                                }
                                </div> :''
}

         
        { // ------------------Enter New Pass---------------------
            flag==='forgotPass' ?
            <div data-aos={flag==='forgotPass'??"fade-up"}>
            <p>Please enter your new password.</p>
            <Form.Group className='mb-3 px-3'>
                <Form.Label>New Password<span> *</span></Form.Label>
                <InputGroup>
                <Form.Control placeholder='Enter new password' type={showNewPass ? "text" : "password"} value={newPass} onChange={(e)=>
                    { setNewPass(e.target.value); e.target.value.length>0 ?   setNewPassErr(false) : setNewPassErr(true)}}/>
                 <InputGroup.Text onClick={() => setShowNewPass(!showNewPass)}>
                          {showNewPass ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setShowNewPass(!showNewPass)} /> :
                            <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                        </InputGroup.Text>
                      </InputGroup>
                {newPassErr ? <p className='errMsg'>Please enter valid new pass</p> :''}
            </Form.Group>

            <Form.Group className='mb-3 px-3'>
                <Form.Label>Confirm Password<span> *</span></Form.Label>
                <InputGroup>
                <Form.Control placeholder='Enter confirm password' type={showConfPass ? "text" : "password"} 
                    value={confirmPass} onChange={(e)=>{ setConfirmPass(e.target.value); 
                    e.target.value.length>0 ? setConfirmPassErr(false) : setConfirmPassErr(true)}}
                />
                <InputGroup.Text onClick={() => setShowConfPass(!showConfPass)}>
                          {showConfPass ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setShowConfPass(!showConfPass)} /> :
                            <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                        </InputGroup.Text>
                </InputGroup>
                {confirmPassErr ? <p className='errMsg'>Please enter valid confirm pass</p> :''}
            </Form.Group>
            {
                loader ? <center><Spinner/></center> :
                <Button className='primaryBtn mx-auto' onClick={forgotPass}><Icon.Send className='me-2'/>Submit</Button>
            }
            </div> : ''
         }
                    </Card> 
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ForgotPass;