import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Form,
  Spinner,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Pagination from "../Common/Pagination";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import edit from "../../Assets/edit.svg";
import deletes from "../../Assets/delete.svg";
import ReactS3 from "../../Utils/ReactS3";
import { S3Context } from "../Common/S3Provider";
import { useNavigate } from "react-router-dom";
import View from "../../Assets/View.svg";
import FileUploader from "../../Utils/FileUploader";
import useApiCall from "../../Hooks/useApiCall";
import Select from "react-select";

const ManageDeptAdmin = () => {
  const { apiCall } = useApiCall();

  const navigate = useNavigate();
  const { s3Data } = useContext(S3Context);

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [loader, setLoader] = useState(false);

  // created staging Branhch -----------
  // States for form Select option ------
  const [deptList, setDeptList] = useState();

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState();

  // States of Add Role ----------------
  const [id, setId] = useState();
  const [departmentName, setDepartmentName] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [socialMediaLink, setSocialMediaLink] = useState([]);
  const [service_address, setService_address] = useState("");
  const [description, setDescription] = useState("");
    const [mobileCountryCode, setMobileCountryCode] = useState("");
    const [countyCodeList, setCountryCodeList] = useState([]);
  

  // States of Add User Error ----------------
  const [departmentNameErr, setDepartmentNameErr] = useState(false);
  const [whatsappNoErr, setWhatsappNoErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [profileImgErr, setProfileImgErr] = useState(false);
  const [serviceAddrErr, setServiceAddrErr] = useState(false);
  const [socialMediaLinkErr, setSocialMediaLinkErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [searchFieldErr, setSearchFieldErr] = useState(false);
    const [mobileCountryErr, setMobileCountryErr] = useState(false);
  

  // States of Delete user -----------------------
  const [delId, setDelId] = useState();

  // search fields
  const [searchField, setSearchField] = useState();

  useEffect(() => {
    getAllDept();
  }, [pageNo, docPerPage]);

  const getAllDept = async () => {
    try {
      const response = await apiCall({
        apiGroup: "department",
        apiRoute: "getDepartment",
        queryParams: `pageNo=${pageNo}&docPerPage=${docPerPage}&searchField=${
          searchField ? searchField : ""
        }`,
      });
      setDeptList(response?.data?.departments);
      setNoOfPages(response.data.noOfPages);
      setLoader(false);
    } catch (err) {
      console.log("Error while getting users", err);
      setLoader(false);
    }
  };

  const closeAddModal = () => {
    setDepartmentName("");
    setDescription("");
    setWhatsappNo("");
    setEmail("");
    setProfileImage("");
    setSocialMediaLink("");
    setService_address("");

    setDepartmentNameErr(false);
    setWhatsappNoErr(false);
    setEmailErr(false);
    setProfileImgErr(false);
    setSocialMediaLinkErr(false);
    setServiceAddrErr(false);

    setShowAdd(false);
    setShowEdit(false);
  };

  const addValidate = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (profileImage?.length === 0) {
      isValid = false;
      setProfileImgErr(true);
    } else if (departmentName?.length === 0) {
      isValid = false;
      setDepartmentNameErr(true);
    }else if (mobileCountryCode === "") {
      isValid = false;
      setMobileCountryErr(true);
    } else if (whatsappNo?.length === 0 || whatsappNo?.length < 10) {
      isValid = false;
      setWhatsappNoErr(true);
      // }else if(email?.length === 0 && !email || !email.includes('@') || !email.includes('.')){
    } else if (email?.length === 0 || !emailRegex.test(email)) {
      isValid = false;
      setEmailErr(true);
    } else if (service_address?.length === 0) {
      isValid = false;
      setServiceAddrErr(true);
    } else if (socialMediaLink?.length === 0) {
      isValid = false;
      setSocialMediaLinkErr(true);
    } else if (description?.length === 0) {
      isValid = false;
      setDescriptionErr(true);
    }

    return isValid;
  };

  const handleProfileImg = async (e) => {
    let result = await ReactS3(e.target.files[0], s3Data, "departmentDocs");
    setProfileImage(result);
  };

  const editValidate = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (profileImage?.length === 0) {
      isValid = false;
      setProfileImgErr(true);
    } else if (departmentName?.length === 0) {
      isValid = false;
      setDepartmentNameErr(true);
    } else if (whatsappNo?.length === 0 || whatsappNo?.length < 10) {
      isValid = false;
      setWhatsappNoErr(true);
      // }else if(email?.length === 0 && !email || !email.includes('@') || !email.includes('.')){
    } else if (email?.length === 0 || !emailRegex.test(email)) {
      isValid = false;
      setEmailErr(true);
    } else if (service_address?.length === 0) {
      isValid = false;
      setServiceAddrErr(true);
    } else if (socialMediaLink?.length === 0) {
      isValid = false;
      setSocialMediaLinkErr(true);
    } else if (description?.length === 0) {
      isValid = false;
      setDescriptionErr(true);
    }

    return isValid;
  };

  const addDepartment = async () => {
    if (addValidate()) {
      try {
        setLoader(true);
        const response = await apiCall({
          apiGroup: "department",
          apiRoute: "addDepartment",
          payload: {
            departmentName: departmentName,
            whatsappNo: mobileCountryCode.value + whatsappNo,
            email: email,
            profileImg: profileImage,
            socialMediaLinks: socialMediaLink,
            service_address: service_address,
            description: description,
          },
        });
        if (response.message === "Record Created Successfully...!") {
          toast.success("Department added successfully");
          setLoader(false);
          closeAddModal();
          getAllDept();
        } else if (response.message === "Department name already exist...!") {
          toast.error("Department name already exist.");
          setLoader(false);
        }
      } catch (err) {
        console.log("Err while adding user", err);
        setLoader(false);
      }
    }
  };

  /* ----- Edit Modal --------*/
  const setEditData = (itm) => {
    setId(itm?._id);
    setProfileImage(itm?.profileImg);
    setDepartmentName(itm?.departmentName);
    setEmail(itm?.email);
    setWhatsappNo(itm?.whatsappNo);
    setProfileImage(itm?.profileImg);
    setSocialMediaLink(itm?.socialMediaLinks);
    setService_address(itm?.service_address);
    setDescription(itm?.description);
  };

  const editUser = async () => {
    setLoader(true);
    if (editValidate()) {
      try {
        const response = await apiCall({
          apiGroup: "department",
          apiRoute: "updateDepartment",
          payload: {
            id: id,
            departmentName: departmentName,
            description: description,
            whatsappNo: whatsappNo,
            profileImg: profileImage,
            email: email,
            service_address: service_address,
            socialMediaLinks: socialMediaLink,
          },
        });

        if (response.message === "Record Updated Successfully...!") {
          toast.success("Department updated successfully");
          setLoader(false);
          setShowEdit(false);
          closeAddModal();
          getAllDept();
        }
      } catch (err) {
        console.log("Err while updating user", err);
        setLoader(false);
      }

      // await fetch(process.env.REACT_APP_BASE_URL + '/department/update',{
      //     method:'POST',
      //     headers:{'Content-Type':'application/json'},
      //     body:JSON.stringify({
      //         id:id,
      //         departmentName:departmentName,
      //         description: description,
      //         whatsappNo: whatsappNo,
      //         profileImg: profileImage,
      //         email:email,
      //         service_address:service_address,
      //         socialMediaLinks: socialMediaLink
      //     })
      // }).then((res)=>res.json()).then((response)=>{
      //     if(response.message === "Record Updated Successfully...!"){
      //         toast.success("Department updated successfully");
      //         setLoader(false);
      //         setShowEdit(false);
      //         closeAddModal();
      //         getAllDept()
      //     }
      // }).catch((err)=>{
      //     console.log("Err while updating user", err);
      //     setLoader(false);
      // })
    } else {
      setLoader(false);
    }
  };

  const deleteDepart = async () => {
    setLoader(true);

    try {
      const response = await apiCall({
        apiGroup: "department",
        apiRoute: "deleteDepartment",
        payload: {
          id: delId,
        },
      });
      if (response.message === "Record Delete Successfully...!") {
        toast.success("Department deleted successfully !");
        setShowDel(false);
        getAllDept();
      }
      setLoader(false);
    } catch (err) {
      console.log("Err while deleting user", err);
      setLoader(false);
    }

    // await fetch(process.env.REACT_APP_BASE_URL + `/department/delete`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     id: delId,
    //   }),
    // })
    //   .then((res) => res.json())
    //   .then((response) => {
    //     if (response.message === "Record Delete Successfully...!") {
    //       toast.success("Department deleted successfully !");
    //       setShowDel(false);
    //       getAllDept();
    //     }
    //     setLoader(false);
    //   })
    //   .catch((err) => {
    //     console.log("Err while adding user", err);
    //     setLoader(false);
    //   });
  };

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  const removeProfileImage = () => {
    setProfileImage(null);
  };

  useEffect(() => {
    if (searchField?.length === 0) {
      getAllDept();
    }
  }, [searchField]);

    useEffect(() => {
      getAllCountryCodes();
    }, []);
  
    const getAllCountryCodes = async () => {
      try {
        
        const response = await apiCall({
          apiGroup: "consumer",
          apiRoute: "getCoutryCodes",
        });
  
        if(response.status === 200){
          let arr = []
          response.data.map( (row) => {
            arr.push({...row,label:`${row.mobileCode} - ${row.countryName}`,value:row.mobileCode})
          } )
  
          setCountryCodeList(arr)
        }
  
      } catch (error) {
        console.error("Error Fetching Country Codes",error);
        
      }
    }

  const handleSearch = () => {
    if (!searchField) {
      setSearchFieldErr(true);
    } else {
      setSearchFieldErr(false);
      getAllDept();
    }
  };

  const handleCountryCode = (option) => {
    setMobileCountryCode(option)
  }

  const formatMobileNumber = (mobileNumber) => {
    // Convert to string to handle numeric inputs
    const mobileString = mobileNumber.toString();

    // Extract the last 10 digits
    const mobile = mobileString.slice(-10);

    // Extract the remaining portion (country code)
    const countryCode = mobileString.slice(0, -10);

    // Format and return the result
    return countryCode ? `+${countryCode} ${mobile}` : mobile;
  };

  return (
    <div className="ManageUsers outletPadding">
      {/* <ToastContainer /> */}
      <Container>
        <Row className="mb-4 justify-content-between">
          <Col md={4}>
            <div className="d-flex">
              <InputGroup>
                <FormControl
                  className="me-2"
                  placeholder="Search by Dept Name or Whatsapp No."
                  value={searchField}
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    searchField?.length < 0
                      ? setSearchFieldErr(true)
                      : setSearchFieldErr(false);
                  }}
                />
              </InputGroup>
              <Button
                onClick={() => {
                  handleSearch();
                }}
              >
                <Icon.Search />
              </Button>
            </div>
            <p className="errMsg">
              {searchFieldErr ? "Please Enter Name or WhatsApp no." : ""}
            </p>
          </Col>

          <Col md={3} className="d-flex justify-content-end align-items-center">
            <Button className="primaryBtn" onClick={() => setShowAdd(true)}>
              <Icon.BuildingAdd className="me-2" />
              Add Department
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto">
          {loader ? (
            <center>
              <Spinner variant="primary" className="mt-3" />
            </center>
          ) : (
            <div className="table-wrapper" data-aos="fade-up">
              <table>
                <thead>
                  <th>Sr No</th>
                  <th>Profile</th>
                  <th>Department Name</th>
                  <th>Whatsapp number</th>
                  <th>Email</th>
                  <th>Service address</th>
                  <th>Credit Point</th>
                  <th>Actions</th>
                </thead>
                {deptList !== null &&
                deptList !== undefined &&
                deptList.length > 0
                  ? deptList.map((itm, index) => {
                      const {
                        email,
                        service_address,
                        departmentName,
                        whatsappNo,
                        profileImg,
                        credits,
                      } = itm;

                      return (
                        <tr key={Math.random() * 999999999}>
                          <td>
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          <td>
                            <img src={profileImg} alt="profile" width={40} />
                          </td>
                          <td>{departmentName}</td>
                          <td>{formatMobileNumber(whatsappNo)}</td>
                          <td>{email}</td>
                          {/* <td style={{maxWidth:'15vw'}}>{socialMediaLinks}</td> */}
                          <td>{service_address ? service_address : "-"}</td>
                          <td>{credits ? credits : "0"}</td>
                          <td>
                            <img
                              src={View}
                              className="me-3 icon"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/dashboard/view-department", {
                                  state: itm,
                                })
                              }
                            />
                            <img
                              src={edit}
                              className="me-3 icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowEdit(true);
                                setEditData(itm);
                              }}
                            />
                            <img
                              src={deletes}
                              className="me-0 icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowDel(true);
                                setDelId(itm?._id);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  : "No data Found"}
              </table>
            </div>
          )}
        </div>
        <Pagination
          currentPage={pageNo}
          totalPages={noOfPages}
          onPageChange={handlePageChange}
          onDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />

        {/* ---------------- Add User Modal ----------------------------------------------------------- */}
        <Modal
          size="lg"
          show={showAdd}
          backdrop="static"
          keyboard={false}
          centered
          onHide={closeAddModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="mb-0">
                <Icon.BuildingAdd size={20} className="me-2" />
                Add Department
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col md={5} className="mb-3">
                <FileUploader
                  label="Profile Image *"
                  multiple={false}
                  fileTypes={["image"]}
                  maxSizeMB={1}
                  onUploadSuccess={(result) =>
                    console.log("Upload success:", result)
                  }
                  onError={(err) => console.error("Upload error:", err)}
                  onFilesChange={(files) => setProfileImage(files[0]?.url)}
                />
                {profileImgErr ? (
                  <p className="errMsg">Please select profile image</p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Department Name<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter department name"
                    name="departmentName"
                    value={departmentName}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        setDepartmentName(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setDepartmentName(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setDepartmentNameErr(false)
                        : setDepartmentNameErr(true);
                    }}
                  />
                  {departmentNameErr ? (
                    <p className="errMsg">Please enter department name</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Country Code<span className="errMsg">*</span>
                  </Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name="Country code"
                    value={mobileCountryCode}
                    options={countyCodeList}
                    onChange={(option) => {
                      // setGenderErr(false);
                      handleCountryCode(option);
                    }}
                  />
                  {mobileCountryErr ? (
                    <p className="errMsg">
                      Please select Country Code for whatsApp Number
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Whatsapp Number<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter whatsapp number"
                    name="whatsappNo"
                    value={whatsappNo}
                    onChange={(e) => {
                      if (
                        e.target.value.match(/^[0-9]+$/) &&
                        e.target.value.length <= 10
                      ) {
                        setWhatsappNo(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setWhatsappNo(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setWhatsappNoErr(false)
                        : setWhatsappNoErr(true);
                    }}
                  />
                  {whatsappNoErr ? (
                    <p className="errMsg">Please enter valid whatsapp number</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Official Email<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter email id"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      e.target.value.length > 0
                        ? setEmailErr(false)
                        : setEmailErr(true);
                    }}
                  />
                  {emailErr ? (
                    <p className="errMsg">Please enter valid email id</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Service Address<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter service address"
                    name="service_address"
                    value={service_address}
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        setService_address(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setService_address(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setServiceAddrErr(false)
                        : setServiceAddrErr(true);
                    }}
                  />
                  {serviceAddrErr ? (
                    <p className="errMsg">Please enter service address</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Social Media links<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter social media links"
                    name="socialMediaLinks"
                    value={socialMediaLink}
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        setSocialMediaLink(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setSocialMediaLink(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setSocialMediaLinkErr(false)
                        : setSocialMediaLinkErr(true);
                    }}
                  />
                  {socialMediaLinkErr ? (
                    <p className="errMsg">Please enter social media link</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Description<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter description"
                    as="textarea"
                    name="description"
                    value={description}
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        setDescription(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setDescription(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setDescriptionErr(false)
                        : setDescriptionErr(true);
                    }}
                  />
                  {descriptionErr ? (
                    <p className="errMsg">Please enter description</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            {loader ? (
              <Spinner variant="primary" />
            ) : (
              <Button className="primaryBtn" onClick={addDepartment}>
                <Icon.BuildingAdd className="me-2" />
                Add Department
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/* ---------------- Edit User Modal ----------------------------------------------------------- */}
        <Modal
          size="lg"
          show={showEdit}
          backdrop="static"
          keyboard={false}
          centered
          onHide={closeAddModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="mb-0">
                <Icon.PencilSquare size={20} className="me-2" />
                Edit Department
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col md={5} className="mb-3">
                <FileUploader
                  label="Profile Image *"
                  multiple={false}
                  fileTypes={["image"]}
                  maxSizeMB={1}
                  onUploadSuccess={(result) =>
                    console.log("Upload success:", result)
                  }
                  onError={(err) => console.error("Upload error:", err)}
                  onFilesChange={(files) => setProfileImage(files[0]?.url)}
                />
                {profileImgErr ? (
                  <p className="errMsg">Please select profile image</p>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Department name<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter department name"
                    name="departmentName"
                    value={departmentName}
                    onChange={(e) => {
                      setDepartmentName(e.target.value);
                      if (
                        !e.target.value.match(
                          /[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        setDepartmentName(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setDepartmentName(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setDepartmentNameErr(false)
                        : setDepartmentNameErr(true);
                    }}
                  />
                  {departmentNameErr ? (
                    <p className="errMsg">Please enter valid department name</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Whatsapp Number<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter whatsapp number"
                    name="whatsappNo"
                    value={whatsappNo}
                    disabled={true}
                    onChange={(e) => {
                      if (
                        e.target.value.match(/^[0-9]+$/) &&
                        e.target.value.length <= 10
                      ) {
                        setWhatsappNo(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setWhatsappNo(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setWhatsappNoErr(false)
                        : setWhatsappNoErr(true);
                    }}
                  />
                  {whatsappNoErr ? (
                    <p className="errMsg">Please enter valid whatsaap number</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Official Email<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter email id"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      e.target.value.length > 0
                        ? setEmailErr(false)
                        : setEmailErr(true);
                    }}
                  />
                  {emailErr ? (
                    <p className="errMsg">Please enter valid email id</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Service Address<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter service address"
                    name="service_address"
                    value={service_address}
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        setService_address(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setService_address(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setServiceAddrErr(false)
                        : setServiceAddrErr(true);
                    }}
                  />
                  {serviceAddrErr ? (
                    <p className="errMsg">Please enter service address</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Social Media links<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter social media links"
                    name="socialMediaLinks"
                    value={socialMediaLink}
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        setSocialMediaLink(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setSocialMediaLink(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setSocialMediaLinkErr(false)
                        : setSocialMediaLinkErr(true);
                    }}
                  />
                  {socialMediaLinkErr ? (
                    <p className="errMsg">Please enter social media link</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Description<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter description"
                    as="textarea"
                    name="description"
                    value={description}
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        setDescription(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setDescription(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setDescriptionErr(false)
                        : setDescriptionErr(true);
                    }}
                  />
                  {descriptionErr ? (
                    <p className="errMsg">Please enter description</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button className="primaryBtn" onClick={editUser} disabled={loader}>
              {loader ? (
                <Spinner size="sm" />
              ) : (
                <Icon.PencilSquare className="me-2" />
              )}
              Edit Department
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ---------------- Delete User Modal ----------------------------------------------------------- */}
        <Modal
          size="md"
          show={showDel}
          backdrop="static"
          keyboard={false}
          centered
          onHide={() => setShowDel(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="mb-0">
                <Icon.Trash size={20} className="me-2" />
                Delete User
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center mb-0">Are you sure to delete ?</h4>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="primaryBtn"
              onClick={deleteDepart}
              disabled={loader}
            >
              {loader ? <Spinner size="sm" /> : <Icon.Trash className="me-1" />}
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default ManageDeptAdmin;
