import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Form,
  Spinner,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../Auth/LoginSlice";
import useApiCall from "../../../Hooks/useApiCall";

export const AddTemplateModel = ({ show, close, reCallData }) => {
  const { apiCall } = useApiCall();

  const dispatch = useDispatch();
  const loginData = useSelector(
    (state) => state?.loginData?.userData?.data?.name
  );

  const commonData = useSelector((state) => state?.commonData?.commonData);

  const [loader, setLoader] = useState(false);

  // States of Add Role ----------------
  const [id, setId] = useState();
  const [templateTitle, setTemplateTitle] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [variable, setVariable] = useState([]);
  const [noOfVariables, setNoOfVariables] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [channelEndDate, setChannelEndDate] = useState("");
  const [category, setCategory] = useState("");
  const [language, setLanguage] = useState("");
  const [variableValues, setVariableValues] = useState([]);
  const [variableErr, setVariableErr] = useState(
    Array(variableValues.length).fill(false)
  );

  // States of Add User Error ----------------
  const [templateTitleErr, setTemplateTitleErr] = useState(false);
  const [messageErr, setMessageErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  // const [variableErr, setVariableErr] = useState(false)
  const [noOfVariablesErr, setNoOfVariablesErr] = useState(false);
  const [createdDateErr, setCreatedDateErr] = useState(false);
  const [departmentNameErr, setDepartmentNameErr] = useState(false);
  // const [channelEndDateErr, setChannelEndDateErr] = useState(false)
  const [categoryErr, setCategoryErr] = useState(false);
  const [languageErr, setLanguageErr] = useState(false);

  //   BroadCast Variables
  const [broadCastType, setBroadCastType] = useState({
    value: "",
    label: "None",
  });
  const [broadCastText, setBroadCastText] = useState("");
  const [broadCastMedia, setBroadCastMedia] = useState("");
  const [broadCastMediaURL, setBroadCastMediaURL] = useState("");

  //   Footer Variables
  const [footerText, setFooterText] = useState("");

  // Button Variables
  const [buttonType, setButtonType] = useState("");
  const [quickRepltText, setQuickRepltText] = useState("");
  const [buttons, setButtons] = useState([
    { action: "", buttonTitle: "", websiteURL: "", mobileWithCountryCode: "" },
  ]);

  const addButton = () => {
    if (buttons.length < 2) {
      setButtons([
        ...buttons,
        {
          action: "",
          buttonTitle: "",
          websiteURL: "",
          mobileWithCountryCode: "",
        },
      ]);
    }
  };

  const closeAddModal = () => {
    setTemplateTitle("");
    setMessage("");
    setImage("");
    setVariable("");
    setNoOfVariables("");
    setCreatedDate("");
    setDepartmentName("");
    // setChannelEndDate('')
    setCategory("");
    setLanguage("");

    setTemplateTitleErr(false);
    setMessageErr(false);
    setImageErr(false);
    setVariableErr(false);
    setNoOfVariablesErr(false);
    setCreatedDateErr(false);
    setDepartmentNameErr(false);
    // setChannelEndDateErr(false)
    setCategoryErr(false);
    setLanguageErr(false);

    close();
  };

  const addValidate = () => {
    let isValid = true;

    if (templateTitle.length === 0) {
      isValid = false;
      setTemplateTitleErr(true);
    } else if (departmentName.length === 0) {
      isValid = false;
      setDepartmentNameErr(true);
    } else if (category.length === 0) {
      isValid = false;
      setCategoryErr(true);
    } else if (language.length === 0) {
      isValid = false;
      setLanguageErr(true);
    } else if (createdDate.length === 0) {
      isValid = false;
      setCreatedDateErr(true);
    }
    // else if (channelEndDate.length === 0) {
    //     isValid = false;
    //     setChannelEndDateErr(true);
    // }
    else if (language.length === 0) {
      isValid = false;
      setLanguageErr(true);
    } else if (message.length === 0) {
      isValid = false;
      setMessageErr(true);
    } else if (noOfVariables.length === 0) {
      isValid = false;
      setNoOfVariablesErr(true);
    }

    // Validate variables
    const variableFieldErrorState = Array(variableValues.length).fill(false);
    for (let i = 0; i < variableValues.length; i++) {
      if (!variableValues[i]) {
        isValid = false;
        variableFieldErrorState[i] = true;
      }
    }
    setVariableErr(variableFieldErrorState);

    // else if (!image) {
    //     isValid = false;
    //     setImageErr(true);
    // }

    return isValid;
  };

  const addTemplate = async () => {
    setLoader(true);
    setTemplateTitleErr(false);
    setMessageErr(false);
    // setImageErr(false)
    setVariableErr(false);
    setNoOfVariablesErr(false);
    setCreatedDateErr(false);
    setDepartmentNameErr(false);
    // setChannelEndDateErr(false)
    setCategoryErr(false);
    setLanguageErr(false);

    if (addValidate()) {
      try {
        const variablesArray = variableValues.map((item) => item.value);

        const payload = {
          templateTitle: templateTitle,
          message: message,
          variable: variablesArray,
          noOfVariables: noOfVariables,
          createdBy: loginData,
          createdDate: createdDate,
          departmentName: departmentName.label,
          // channelEndDate: channelEndDate,
          category: category.label,
          language: language.value,
          allowToCategoryChange: true,
          templateBrodcastType: broadCastType.value.toLowerCase(),
          footerText: footerText,
          messageHeader: broadCastText,
          content:
            broadCastType.value.toLowerCase() === "text"
              ? broadCastText
              : [
                  {
                    type: broadCastMedia.toUpperCase(),
                    url: broadCastMediaURL,
                  },
                ],
          buttons:
            buttonType?.value === "Quick reply"
              ? {
                  type: "QUICK_REPLY",
                  text: quickRepltText,
                }
              : buttons.map((row) => {
                  const buttonData = {
                    type: row.action?.value,
                    text: row.buttonTitle,
                  };

                  if (row.action?.value === "URL") {
                    buttonData.url = row.websiteURL; // Add the `url` field only if the type is URL
                  } else if (row.action?.value === "PHONE_NUMBER") {
                    buttonData.phone_number = row.mobileWithCountryCode; // Add `phone_number` only if the type is PHONE_NUMBER
                  }

                  return buttonData;
                }),
        };

        const response = await apiCall({
          apiGroup: "template",
          apiRoute: "addTemplate",
          // queryParams: `pageNo=${pageNo}&docPerPage=${docPerPage}`,
          payload: payload,
        });

        console.log("response", response);
        setLoader(false);
        if (response.message === "Record Created Successfully...!") {
          toast.success("Template added successfully");
          setLoader(false);
          closeAddModal();
          reCallData();
          // getAllTemplateData();
        } else if (response.message === "Template title already exist...!") {
          toast.error("Template title already exists.");
          setLoader(false);
        }
      } catch (err) {
        console.log("Err while adding user", err);
        setLoader(false);
      }

      // await fetch(process.env.REACT_APP_BASE_URL +'/template/create', {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({

      //         templateTitle: templateTitle,
      //         message: message,
      //         variable: variablesArray,
      //         noOfVariables:noOfVariables,
      //         createdBy: loginData,
      //         createdDate: createdDate,
      //         departmentName: departmentName.label,
      //         // channelEndDate: channelEndDate,
      //         category: category.label,
      //         language: language.value,
      //         allowToCategoryChange: false
      //     })
      // }).then((res) => res.json()).then((response) => {
      //     console.log("response", response);
      //     setLoader(false);
      //     if (response.message === "Record Created Successfully...!") {
      //         toast.success("Template added successfully");
      //         setLoader(false);
      //         closeAddModal();
      //         reCallData()
      //         // getAllTemplateData();
      //     }else if(response.message === "Template title already exist...!"){
      //         toast.error("Template title already exists.");
      //         setLoader(false);
      //     }
      // }).catch((err) => {
      //     console.log("Err while adding user", err);
      //     setLoader(false);
      // })
    } else {
      setLoader(false);
    }
  };

  const categoryList = [
    // {value:"AUTHENTICATION", label:"AUTHENTICATION"},for now it is commented because problem in AUTHENTICATION
    { value: "MARKETING", label: "MARKETING" },
    { value: "UTILITY", label: "UTILITY" },
  ];

  const broadCastList = [
    { value: "", label: "None" },
    { value: "Text", label: "Text" },
    { value: "Media", label: "Media" },
  ];

  // Options for dropdowns
  const buttonTypeList = [
    { value: "", label: "None" },
    { value: "Call to action", label: "Call to action" },
    { value: "Quick reply", label: "Quick reply" },
  ];
  const actionOptions = [
    { value: "URL", label: "Visit Website" },
    { value: "PHONE_NUMBER", label: "Call Phone" },
  ];

  const handleCategoryList = (selected) => {
    setCategory(selected);
  };

  const handleDepartment = (selected) => {
    setDepartmentName(selected);
  };

  const langList = [{ value: "en", label: "English(en)" }];

  const handleLanguageList = (selected) => {
    setLanguage(selected);
  };

  const [listOfArray, setListOfArray] = useState([]);
  const [messageArray, setMessageArray] = useState([]);

  const handleMessageOnChange = (e) => {
    const value = e.target.value;
    if (value.length <= 1024) {
      setMessage(value);
      const arrayOfWords = value.split(" ");
      setListOfArray(arrayOfWords);
      setMessageArray(
        arrayOfWords.map((word) => ({ value: word, label: word }))
      );
    }
  };

  const handleVariableChange = (option, index) => {
    const updatedValues = [...variableValues];
    updatedValues[index] = option;
    setVariableValues(updatedValues);
  };

  const getAvailableOptions = (index) => {
    return messageArray.filter((option) => {
      return variableValues.every(
        (value, i) => i === index || value?.value !== option.value
      );
    });
  };

  const generateInputFields = () => {
    const numInputFields = noOfVariables ? parseInt(noOfVariables, 10) : 0;

    if (numInputFields > listOfArray.length) {
      return [];
    }

    const rows = [];
    for (let i = 0; i < numInputFields; i += 2) {
      const firstIndex = i;
      const secondIndex = i + 1;

      const firstField = (
        <Col md={6} key={firstIndex}>
          <Form.Group className="mb-3">
            <Form.Label>{`Variable ${firstIndex + 1}`}</Form.Label>
            <Select
              isMulti={false}
              isSearchable={true}
              name={variable}
              value={variableValues[firstIndex] || null}
              options={getAvailableOptions(firstIndex)}
              onChange={(option) => handleVariableChange(option, firstIndex)}
            />
            {variableErr[firstIndex] && (
              <p className="errMsg">Please select Variable {firstIndex + 1}</p>
            )}
          </Form.Group>
        </Col>
      );

      const secondField =
        secondIndex < numInputFields ? (
          <Col md={6} key={secondIndex}>
            <Form.Group className="mb-3">
              <Form.Label>{`Variable ${secondIndex + 1}`}</Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name={variable}
                value={variableValues[secondIndex] || null}
                options={getAvailableOptions(secondIndex)}
                onChange={(option) => handleVariableChange(option, secondIndex)}
              />
              {variableErr[secondIndex] && (
                <p className="errMsg">
                  Please select Variable {secondIndex + 1}
                </p>
              )}
            </Form.Group>
          </Col>
        ) : null;

      rows.push(
        <Row key={i}>
          {firstField}
          {secondField}
        </Row>
      );
    }

    return rows;
  };

  const handleNoOfVariableChange = (e) => {
    const value = e.target.value;
    if (
      !value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) &&
      listOfArray.length >= value
    ) {
      setVariableValues([]);
      setNoOfVariables(value);
      value.length > 0 ? setNoOfVariablesErr(false) : setNoOfVariablesErr(true);
    } else if (!value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)) {
      setNoOfVariables(value);
      toast.error("Please Enter less than message words count");
    }
  };

  const handleMediaTypeChange = (e) => {
    setBroadCastMedia(e.target.value);
  };

  const getFormatAllowed = (broadCastMedia) => {
    switch (broadCastMedia) {
      case "Image":
        return ".jpg, .jpeg, .png";
      case "Video":
        return ".mp4";
      case "Document":
        return ".pdf";
    }
  };

  const handleBroadCastMedia = async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("image", file);

    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/upload/photo",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Upload failed");
    }

    const result = await response.json();
    setBroadCastMediaURL(result.url);
  };

  const handleButtonAction = (option, index) => {
    let arr = [...buttons];
    arr[index].action = option;
    setButtons(arr);
  };
  const handleButtonTitle = (text, index) => {
    let arr = [...buttons];
    arr[index].buttonTitle = text;
    setButtons(arr);
  };
  const handleWebsiteURL = (text, index) => {
    let arr = [...buttons];
    arr[index].websiteURL = text;
    setButtons(arr);
  };
  const handleButtonMobile = (mobile, index) => {
    let arr = [...buttons];
    arr[index].mobileWithCountryCode = mobile;
    setButtons(arr);
  };

  return (
    <Modal
      size="lg"
      show={show}
      backdrop="static"
      keyboard={false}
      centered
      onHide={closeAddModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <h4 className="mb-0">
            <Icon.EnvelopePlus size={20} className="me-2" />
            Add Template
          </h4>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Template Title<span className="errMsg">*</span>
              </Form.Label>
              <Form.Control
                placeholder="Enter template title"
                name="templateTitle"
                value={templateTitle}
                onChange={(e) => {
                  if (
                    /^[a-z_]+$/.test(e.target.value) &&
                    e.target.value.length <= 512 &&
                    e.target.value.trim()
                  ) {
                    setTemplateTitle(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTemplateTitle(e.target.value);
                  }
                  e.target.value.length > 0
                    ? setTemplateTitleErr(false)
                    : setTemplateTitleErr(true);
                }}
              />
              {templateTitleErr ? (
                <p className="errMsg">Please enter valid template title</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Department Name<span className="errMsg">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name="departmentName"
                value={departmentName}
                options={commonData?.departments}
                onChange={(option) => {
                  handleDepartment(option);
                }}
              />
              {departmentNameErr ? (
                <p className="errMsg">Please enter valid department name</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Template Category<span className="errMsg">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name="category"
                value={category}
                options={categoryList}
                onChange={(option) => {
                  handleCategoryList(option);
                  setCategoryErr(false);
                }}
              />
              {categoryErr ? (
                <p className="errMsg">Please enter category</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Template Language<span className="errMsg">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name="language"
                value={language}
                options={langList}
                onChange={(option) => {
                  handleLanguageList(option);
                  setLanguageErr(false);
                }}
              />
              {languageErr ? (
                <p className="errMsg">Please select template language</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Created Date<span className="errMsg">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Select created date"
                name="createdDate"
                value={createdDate}
                onChange={(e) => {
                  setCreatedDate(e.target.value);
                  e.target.value.length > 0
                    ? setCreatedDateErr(false)
                    : setCreatedDateErr(true);
                }}
              />
              {createdDateErr ? (
                <p className="errMsg">Please enter created date</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
        </Row>

        <hr style={{ width: "100%", color: "gray" }} />

        <Row>
          <Form.Label>
            BroadCast Title <span className="optionaltext">(Optional)</span>
          </Form.Label>
          <span className="noteMessage mb-3">
            Hightlight your brand here, use images or videos , to stand out
          </span>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Select
                isMulti={false}
                isSearchable={false}
                name="broadCast Title"
                value={broadCastType}
                options={broadCastList}
                onChange={(option) => {
                  setBroadCastType(option);
                }}
              />
            </Form.Group>
          </Col>

          {broadCastType.value === "Text" && (
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Control
                  // as="textarea"
                  placeholder="Enter Text"
                  name="message"
                  value={broadCastText}
                  maxLength={60}
                  onChange={(e) => {
                    setBroadCastText(e.target.value);
                  }}
                />
                <span className="noteMessage">
                  <span className="error">*</span>Please Keep length of Title
                  less than 60 characters.
                </span>
              </Form.Group>
            </Col>
          )}

          {broadCastType.value === "Media" && (
            <Row className="mt-3">
              {/* Radio Buttons */}
              <Col md={12}>
                <Form.Group as={Row}>
                  <Col md={2}>
                    <Form.Check
                      type="radio"
                      label="Image"
                      name="mediaType"
                      id="imageRadio"
                      value="Image"
                      checked={broadCastMedia === "Image"}
                      onChange={handleMediaTypeChange}
                    />
                  </Col>
                  <Col md={2}>
                    <Form.Check
                      type="radio"
                      label="Video"
                      name="mediaType"
                      id="videoRadio"
                      value="Video"
                      checked={broadCastMedia === "Video"}
                      onChange={handleMediaTypeChange}
                    />
                  </Col>
                  <Col md={2}>
                    <Form.Check
                      type="radio"
                      label="Document"
                      name="mediaType"
                      id="documentRadio"
                      value="Document"
                      checked={broadCastMedia === "Document"}
                      onChange={handleMediaTypeChange}
                    />
                  </Col>
                </Form.Group>
              </Col>

              {/* Caption and Upload Media in the Same Row */}
              <Col md={12}>
                {broadCastMedia ? (
                  <>
                    <span className="formatType">
                      {broadCastMedia} : {getFormatAllowed(broadCastMedia)}
                    </span>
                    <Form.Group as={Row} className="align-items-center mt-3">
                      <Col md={9}>
                        <Form.Control
                          placeholder="Enter Media Url"
                          id="mediaCaption"
                          value={broadCastMediaURL}
                          onChange={(e) => {
                            setBroadCastMediaURL(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={3} className="text-end">
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={(e) => handleBroadCastMedia(e)}
                        />
                        <label htmlFor="fileInput" className="mediaButton">
                          Upload Media
                        </label>
                      </Col>
                    </Form.Group>
                  </>
                ) : null}
              </Col>
            </Row>
          )}
        </Row>

        <hr style={{ width: "100%", color: "gray" }} />

        <Row>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>
                Template Message<span className="errMsg">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter message"
                name="message"
                value={message}
                onChange={(e) => {
                  handleMessageOnChange(e);
                  e.target.value.length > 0
                    ? setMessageErr(false)
                    : setMessageErr(true);
                }}
              />
              {messageErr ? (
                <p className="errMsg">Please enter valid message</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                No of variables<span className="errMsg">*</span>
              </Form.Label>
              <Form.Control
                placeholder="Enter No. of variables"
                name="noOfVariables"
                value={noOfVariables}
                onChange={handleNoOfVariableChange}
              />
              {noOfVariablesErr ? (
                <p className="errMsg">Please enter no of variables</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>

          {generateInputFields()}
        </Row>

        <hr style={{ width: "100%", color: "gray" }} />

        <Row>
          <Form.Label>
            Footer <span className="optionaltext">(Optional)</span>
          </Form.Label>
          <span className="noteMessage mb-3">
            Footer are Great to add disclaimer or the thoughtful PS
          </span>
          <Col md={12}>
            <Form.Group className="">
              <Form.Control
                placeholder="Enter Footer text"
                value={footerText}
                maxLength={60}
                onChange={(e) => setFooterText(e.target.value)}
              />
              <span className="noteMessage">
                <span className="error">*</span>Please Keep length of Footer
                less than 60 characters.
              </span>
            </Form.Group>
          </Col>
        </Row>

        <hr style={{ width: "100%", color: "gray" }} />

        <Row>
          <Form.Label>
            Buttons <span className="optionaltext">(Optional)</span>
          </Form.Label>
          <span className="noteMessage mb-3">
            Create up to 2 buttons that let customers respond to your message or
            take action.
          </span>

          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6}>
              <Form.Group>
                <Select
                  isMulti={false}
                  isSearchable={false}
                  name="Button Actions"
                  value={buttonType}
                  options={buttonTypeList}
                  onChange={(option) => {
                    setButtonType(option);
                  }}
                />
              </Form.Group>
            </Col>

            <Col
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              {buttonType?.value === "Call to action" && buttons.length < 2 && (
                <Button variant="primary" onClick={addButton}>
                  Add button
                </Button>
              )}
            </Col>
          </Row>

          {buttonType?.value === "Call to action" &&
            buttons.map((row, index) => (
              <Row key={index} className="align-items-start mb-3 mt-3">
                <Col md={4}>
                  <Form.Group>
                    <Select
                      placeholder="Select Action"
                      isMulti={false}
                      isSearchable={false}
                      name="Action Titles"
                      value={row.action}
                      options={actionOptions}
                      onChange={(option) => {
                        handleButtonAction(option, index);
                      }}
                    />
                  </Form.Group>
                </Col>

                {row.action?.value === "URL" && (
                  <>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Button Title"
                        value={row.buttonTitle}
                        maxLength={25}
                        onChange={(e) =>
                          handleButtonTitle(e.target.value, index)
                        }
                      />
                      <span className="noteMessage">
                        <span className="error">*</span>Please Keep length of
                        Button Title less than 25 characters.
                      </span>
                    </Col>

                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Website URL"
                        maxLength={100}
                        value={row.websiteURL}
                        onChange={(e) =>
                          handleWebsiteURL(e.target.value, index)
                        }
                      />
                      <span className="noteMessage">
                        <span className="error">*</span>Please Keep length of
                        url less than 100 characters.
                      </span>
                    </Col>
                  </>
                )}

                {row.action?.value === "PHONE_NUMBER" && (
                  <>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Button Title"
                        value={row.buttonTitle}
                        maxLength={25}
                        onChange={(e) =>
                          handleButtonTitle(e.target.value, index)
                        }
                      />
                      <span className="noteMessage">
                        <span className="error">*</span>Please Keep length of
                        Button Title less than 25 characters.
                      </span>
                    </Col>

                    <Col md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Mobile Number"
                        value={row.mobileWithCountryCode}
                        onChange={(e) =>
                          handleButtonMobile(e.target.value, index)
                        }
                      />
                      <span className="noteMessage">
                        <span className="error">*</span> Please Add Mobile
                        Number with Country Code
                      </span>
                    </Col>
                  </>
                )}
              </Row>
            ))}

          {buttonType?.value === "Quick reply" && (
            <Col md={12} className="mt-2">
              <Form.Group>
                <Form.Label>Reply Text</Form.Label>
                <Form.Control
                  placeholder="Enter Button Title"
                  value={quickRepltText}
                  maxLength={25}
                  onChange={(e) => setQuickRepltText(e.target.value)}
                />
                <span className="noteMessage">
                  <span className="error">*</span>Please Keep length of Button
                  Title less than 25 characters.
                </span>
              </Form.Group>
            </Col>
          )}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button className="primaryBtn" onClick={addTemplate} disabled={loader}>
          {loader ? (
            <Spinner size="sm" />
          ) : (
            <Icon.EnvelopePlus className="me-2" />
          )}
          Add Template
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
