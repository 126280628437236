import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Form,
  Spinner,
  Tab,
  Nav,
  TabContent,
  TabPane,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Pagination from "../Common/Pagination";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import edit from "../../Assets/edit.svg";
import deletes from "../../Assets/delete.svg";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import TableSkeleton from "../Common/TableSkeleton";
import useApiCall from "../../Hooks/useApiCall";

export const ManageConsumer = () => {
  const userState = useSelector((state) => state?.loginData?.userData?.data);

  const { apiCall } = useApiCall();

  const [key, setKey] = useState("singleConsumer");
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState();

  // States of Add Role ----------------
  const [file, setFile] = useState("");
  const [id, setId] = useState();
  const [consumerList, setconsumerList] = useState([]);
  const [countyCodeList, setCountryCodeList] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [WhatsaapNo, setWhatsaapNo] = useState("");
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [category, setCategory] = useState("");
  const [age, setAge] = useState("");

  // States of Add User Error ----------------
  const [fileErr, setFileErr] = useState(false);
  const [fisrtNameErr, setFisrtNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [WhatsaapNoErr, setWhatsaapNoErr] = useState(false);
  const [mobileCountryErr, setMobileCountryErr] = useState(false);
  const [genderErr, setGenderErr] = useState(false);
  const [birthDateErr, setBirthDateErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [ageErr, setAgeErr] = useState(false);
  const [searchFieldErr, setSearchFieldErr] = useState(false);

  // States of Delete user -----------------------
  const [delId, setDelId] = useState();

  // search query
  const [searchField, setSearchField] = useState("");

  const commonData = useSelector((state) => state?.commonData?.commonData);

  useEffect(() => {
    getAllConsumer();
  }, [pageNo, docPerPage]);

  useEffect(() => {
    getAllCountryCodes();
  }, []);

  const getAllCountryCodes = async () => {
    try {
      
      const response = await apiCall({
        apiGroup: "consumer",
        apiRoute: "getCoutryCodes",
      });

      if(response.status === 200){
        let arr = []
        response.data.map( (row) => {
          arr.push({...row,label:`${row.mobileCode} - ${row.countryName}`,value:row.mobileCode})
        } )

        setCountryCodeList(arr)
      }

    } catch (error) {
      console.error("Error Fetching Country Codes",error);
      
    }
  }

  const getAllConsumer = async () => {
    setLoader(true);
    try {
      const response = await apiCall({
        apiGroup: "consumer",
        apiRoute: "getConsumers",
        queryParams: `searchField=${
          searchField ? searchField : ""
        }&pageNo=${pageNo}&docPerPage=${docPerPage}&departmentId=${userState?.departmentId}`,
      });
      setconsumerList(response?.data?.consumers);
      setNoOfPages(response?.data?.noOfPages);
    } catch (err) {
      console.error("Error fetching consumers:", err);
    } finally {
      setLoader(false);
    }
  };

  const closeModal = () => {
    setFirstName("");
    setLastName("");
    setWhatsaapNo("");
    setGender("");
    setBirthDate("");
    setCategory("");
    setAge("");
    setMobileCountryCode("")

    setFisrtNameErr(false);
    setLastNameErr(false);
    setWhatsaapNoErr(false);
    setGenderErr(false);
    setBirthDateErr(false);
    setMobileCountryErr(false)
    setAgeErr(false);

    setShowAdd(false);
    setShowEdit(false);
  };

  const addValidate = () => {
    let isValid = true;
    if (firstName.length === 0) {
      isValid = false;
      setFisrtNameErr(true);
    } else if (lastName.length === 0) {
      isValid = false;
      setLastNameErr(true);
    }else if (mobileCountryCode === "") {
      isValid = false;
      setMobileCountryErr(true);
    } else if (WhatsaapNo.length === 0 || WhatsaapNo.length < 10) {
      isValid = false;
      setWhatsaapNoErr(true);
    } else if (gender.length === 0) {
      isValid = false;
      setGenderErr(true);
    } else if (birthDate.length === 0) {
      isValid = false;
      setBirthDateErr(true);
    } else if (age.length === 0 || age?.length < 2) {
      isValid = false;
      setAgeErr(true);
    }

    return isValid;
  };

  const genderList = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];
  const handleGender = (selected) => {
    setGender(selected);
  };

  const lastDayOfJuly2023 = new Date(2023, 6, 31).toISOString().split("T")[0];

  const editValidate = () => {
    let isValid = true;
    if (firstName.length === 0) {
      isValid = false;
      setFisrtNameErr(true);
    } else if (lastName.length === 0) {
      isValid = false;
      setLastNameErr(true);
    } else if (WhatsaapNo?.length === 0 || WhatsaapNo?.length < 10) {
      isValid = false;
      setWhatsaapNoErr(true);
    } else if (gender.length === 0) {
      isValid = false;
      setGenderErr(true);
    } else if (birthDate.length === 0) {
      isValid = false;
      setBirthDateErr(true);
    } else if (age.length === 0 || age?.length < 2) {
      isValid = false;
      setAgeErr(true);
    }

    return isValid;
  };

  const addConsumer = async (e) => {
    e.preventDefault();

    if (addValidate()) {
      setLoader(true);
      try {
        const response = await apiCall({
          apiGroup: "consumer",
          apiRoute: "addConsumers",
          payload: {
            firstName,
            lastName,
            whatsappNo: mobileCountryCode.value + WhatsaapNo,
            departmentId: userState.departmentId,
            gender: gender.label,
            dob: new Date(birthDate).toISOString().split("T")[0],
            categoryId: category?.value ? category?.value : "",
            categoryName: category?.label ? category?.label : "",
            age,
          },
        });

        if (response.message === "Record Created Successfully...!") {
          toast.success("Consumer added successfully");
          closeModal();
          getAllConsumer();
        } else if (response.message === "Whatsapp no already exist...!") {
          toast.error("Whatsapp no already exists.");
        }
      } catch (err) {
        console.log("Error while adding consumer", err);
      } finally {
        setLoader(false);
      }
    }
  };

  /*-------------------- ADD MULTIPLE CONSUMERS ---------------------------*/
  const addMultiplConsumer = async (e) => {
    e.preventDefault();
    setCategoryErr(false);
    setLoader(true);

    if (!category) {
      toast.error("Please select category");
      setLoader(false);
      return;
    } else if (!file) {
      toast.error("Please upload an Excel file");
      setLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    let jsonData;
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        raw: false,
        dateNF: "m/d/yy",
        cellDates: true,
      });

      const missingFields = jsonData.filter((row) => {
        return (
          !row.firstName ||
          !row.lastName ||
          !row.dob ||
          !row.whatsappNo ||
          !row.age ||
          !row.gender
        );
      });

      console.log("missingfields", missingFields);

      if (missingFields.length > 0) {
        setLoader(false);
        const missingFieldNames = [];
        missingFields.forEach((row) => {
          if (!row.firstName) missingFieldNames.push("firstName");
          if (!row.lastName) missingFieldNames.push("lastName");
          if (!row.dob) missingFieldNames.push("dob");
          if (!row.whatsappNo) missingFieldNames.push("whatsappNo");
          if (!row.age) missingFieldNames.push("age");
        });

        toast.error(
          `Please ensure that the following fields are provided for all entries: ${missingFieldNames.join(
            ", "
          )}`
        );
        return;
      }

      jsonData.forEach((row) => {
        if (row.dob) {
          const date = new Date(row.dob);
          row.dob = date.toISOString().split("T")[0]; // Convert to "yyyy-mm-dd" format
        }
      });

      console.log("jsonData in If", jsonData);
      // callUploadConsumers(jsonData);

      try {
        const response = await apiCall({
          apiGroup: "consumer",
          apiRoute: "uploadConsumers",
          extendedRoute: `/${category?.value}`,
          payload: jsonData,
        });

        console.log("response in iF", response);
        if (response?.message === "Consumer Added Successfully") {
          toast.success("Consumer added successfully.");
          setShowAdd(false);
          setLoader(false);
        }
      } catch (err) {
        console.log("Error in adding advocate", err);
        setLoader(false);
      }

      // fetch(process.env.REACT_APP_BASE_URL + `/consumer/uploadConsumers/${category?.value}`, {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify(jsonData),
      //   })
      //     .then((res) => res.json())
      //     .then((response) => {
      //         console.log("response in iF", response);
      //         if(response?.message === "Consumer Added Successfully"){
      //             toast.success("Consumer added successfully.")
      //             setShowAdd(false)
      //             setLoader(false);
      //         }
      //     })
      //     .catch((err) => {
      //       console.log('Error in adding advocate', err);
      //       setLoader(false);
      //     });
    };

    reader.readAsBinaryString(file);
  };

  //    const callUploadConsumers = async(jsonData)=>{
  //     console.log("jsonData in upload", jsonData);
  //     try{
  //         const response = await apiCall({ apiGroup:"consumer", apiRoute:"uploadConsumers",
  //             queryParams:category?.value,
  //             payload:jsonData
  //         });
  //         console.log("response from upload", response);
  //     }catch(Err){
  //         console.log("Err while calling upload multi consumers", Err);
  //     }
  //     finally{
  //         setLoader(false);
  //     }
  //    }

  /* ----- Edit Modal --------*/
  const setEditData = (itm) => {
    setId(itm?._id);
    setFirstName(itm?.firstName);
    setLastName(itm?.lastName);
    setWhatsaapNo(itm?.whatsappNo);
    setBirthDate(new Date(itm?.dob).toISOString().split("T")[0]);
    setGender({ value: itm.gender, label: itm.gender });
    setCategory({ value: itm.categoryId[0], label: itm.categoryName[0] });
    setAge(itm?.age);
  };

  const editConsumer = async () => {
    setLoader(true);
    if (editValidate()) {
      try {
        const response = await apiCall({
          apiGroup: "consumer",
          apiRoute: "editConsumers",
          payload: {
            id: id,
            firstName: firstName,
            lastName: lastName,
            whatsappNo: WhatsaapNo,
            departmentId: userState.departmentId,
            gender: gender.label,
            dob: new Date(birthDate).toISOString().split("T")[0],
            categoryId: category?.value ? category?.value : "",
            categoryName: category?.label ? category?.label : "",
            age: age,
          },
        });

        if (response.message === "Record Updated Successfully...!") {
          toast.success("Consumer updated successfully");
          setLoader(false);
          setShowEdit(false);
          setSearchField("");
          closeModal();
          getAllConsumer();
        }
      } catch (err) {
        console.log("Err while updating user", err);
        setLoader(false);
      }

      // await fetch(process.env.REACT_APP_BASE_URL + '/consumer/update',{
      //     method:'POST',
      //     headers:{'Content-Type':'application/json'},
      //     body:JSON.stringify({
      //         id:id,
      //         firstName:firstName,
      //         lastName:lastName,
      //         whatsappNo:WhatsaapNo,
      //         gender:gender.label,
      //         dob:new Date(birthDate).toISOString().split('T')[0],
      //         categoryId : category?.value ? category?.value : "",
      //         age : age
      //     })
      // }).then((res)=>res.json()).then((response)=>{
      //     if(response.message === "Record Updated Successfully...!"){
      //         toast.success("Consumer updated successfully");
      //         setLoader(false);
      //         setShowEdit(false);
      //         closeModal();
      //         getAllConsumer()
      //     }
      // }).catch((err)=>{
      //     console.log("Err while updating user", err);
      //     setLoader(false);
      // })
    } else {
      setLoader(false);
    }
  };

  const deleteConsumer = async () => {
    setLoader(true);
    try {
      const response = await apiCall({
        apiGroup: "consumer",
        apiRoute: "deleteConsumers",
        extendedRoute: `/${delId}`,
      });
      if (response.message === "Record Delete Successfully...!") {
        toast.success("Consumer deleted successfully !");
        setSearchField("");
        setShowDel(false);
        getAllConsumer();
      }
      setLoader(false);
    } catch (err) {
      console.log("Err while Deleting Consumer", err);
      setLoader(false);
    }
    // await fetch(process.env.REACT_APP_BASE_URL + `/consumer/delete/${delId}`,{
    //     method:'GET',
    //     headers:{'Content-Type':'application/json'}
    // }).then((res)=>res.json()).then((response)=>{
    //     if(response.message === "Record Delete Successfully...!"){
    //         toast.success("Consumer deleted successfully !");
    //         setShowDel(false);
    //         getAllConsumer();
    //     }
    //     setLoader(false);
    // }).catch((err)=>{
    //     console.log("Err while adding user", err);
    //     setLoader(false);
    // })
  };

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    if (searchField?.length === 0) {
      getAllConsumer();
    }
  }, [searchField]);

  const handleSearch = () => {
    if (searchField.trim() === "") {
      setSearchFieldErr(true);
    } else {
      setSearchFieldErr(false);
      setPageNo("");
      setDocPerPage("");
      getAllConsumer();
    }
  };

  const handleCountryCode = (option) => {
    setMobileCountryCode(option)
  }

  // Excel upload code
  const downloadExcel = (customHeadings) => {
    let dataSet = [];

    // Convert object to array of objects
    Object.keys(customHeadings).forEach((key) => {
      dataSet.push({ [key]: customHeadings[key] });
    });

    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "UserDetails.xlsx");
    // setSheetLoader(false);
  };

  const CustomExcel = () => {
    console.log("inside CustomExcel");
    // setSheetLoader(true);

    const customHeadings = {
      firstName: " ",
      lastName: " ",
      whatsappNo: " ",
      gender: " ",
      dob: " ",
      age: " ",
    };

    downloadExcel(customHeadings);
  };

  const formatMobileNumber = (mobileNumber) => {
    // Convert to string to handle numeric inputs
    const mobileString = mobileNumber.toString();
    
    // Extract the last 10 digits
    const mobile = mobileString.slice(-10);
    
    // Extract the remaining portion (country code)
    const countryCode = mobileString.slice(0, -10);
    
    // Format and return the result
    return countryCode ? `+${countryCode} ${mobile}` : mobile;
  }

  return (
    <div className="ManageConsumer outletPadding">
      {/* <ToastContainer /> */}
      <Container>
        <Row>
          <Row className="justify-content-between mb-3" md={12}>
            <Col md={4} className="mb-2 mb-md-0">
              <div className="d-flex mb-1">
                <Form.Control
                  className="me-2"
                  placeholder="Search by Name or Whatsapp No."
                  value={searchField}
                  name="searchField"
                  onChange={(e) => {
                    setSearchField(e.target.value);
                  }}
                />
                <div>
                  <Button onClick={handleSearch}>
                    <Icon.Search />
                  </Button>
                </div>
              </div>
              <p className="errMsg">
                {searchFieldErr ? "Please Enter Name or WhatsApp no." : ""}
              </p>
            </Col>

            <Col
              md={3}
              className="d-flex justify-content-end align-items-start"
            >
              <Button className="primaryBtn" onClick={() => setShowAdd(true)}>
                <Icon.PersonAdd className="me-2" />
                Add Consumer
              </Button>
            </Col>
          </Row>

          <div
            className="outer-wrapper mx-auto"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="table-wrapper">
              <table>
                <thead>
                  <th>Sr No</th>
                  <th>Name</th>
                  <th>Whatsapp number</th>
                  <th>Gender</th>
                  <th>Date of Birth</th>
                  <th>Category</th>
                  <th>Actions</th>
                </thead>
                {loader ? (
                  <TableSkeleton rows={10} cols={7} />
                ) : consumerList !== null &&
                  consumerList !== undefined &&
                  consumerList.length > 0 ? (
                  consumerList.map((itm, index) => {
                    const {
                      firstName,
                      lastName,
                      whatsappNo,
                      gender,
                      dob,
                      categoryName,
                    } = itm;

                    return (
                      <tr key={Math.random() * 999999999}>
                        <td>
                          {pageNo !== 1 ? (
                            <> {index + 1 + docPerPage * (pageNo - 1)}</>
                          ) : (
                            <>{index + 1}</>
                          )}
                        </td>
                        <td>{`${firstName} ${lastName}`}</td>
                        <td>{formatMobileNumber(whatsappNo)}</td>
                        <td>{gender ?? '-' }</td>
                        <td>{new Date(dob).toLocaleDateString("en-GB") ?? '-'}</td>
                        <td>
                          {categoryName && categoryName.length > 0
                            ? categoryName.map((val) => val)
                            : "-"}
                        </td>
                        <td>
                          <img
                            src={edit}
                            className="me-3 icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowEdit(true);
                              setEditData(itm);
                            }}
                          />
                          <img
                            src={deletes}
                            className="me-2 icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowDel(true);
                              setDelId(itm?._id);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  "No data Found"
                )}
              </table>
            </div>
          </div>
          <Pagination
            currentPage={pageNo}
            totalPages={noOfPages}
            onPageChange={handlePageChange}
            onDocsPerPage={handleDocsPerPage}
            docPerPage={docPerPage}
          />
        </Row>

        {/* ---------------- Add Consumer Modal ----------------------------------------------------------- */}
        <Modal
          size="lg"
          show={showAdd}
          backdrop="static"
          keyboard={false}
          centered
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="mb-0">
                <Icon.PersonAdd size={20} className="me-2" />
                Add Consumer
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Nav
                variant="pills"
                className="flex-row justify-content-center mx-auto"
              >
                <Nav.Item>
                  <Nav.Link eventKey="singleConsumer">
                    <Icon.Person className="me-2" />
                    Add single consumer
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="multipleConsumer">
                    <Icon.People className="me-2" />
                    Add multiple consumer
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="singleConsumer">
                  <Form onSubmit={addConsumer}>
                    <Row className="py-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            First name<span className="errMsg">*</span>
                          </Form.Label>
                          <Form.Control
                            placeholder="Enter first name"
                            name="firstName"
                            value={firstName}
                            onChange={(e) => {
                              if (
                                !e.target.value.match(
                                  /[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/
                                ) &&
                                e.target.value.trim()
                              ) {
                                setFirstName(e.target.value);
                              } else if (e.target.value.length === 0) {
                                setFirstName(e.target.value);
                              }
                              e.target.value.length > 0
                                ? setFisrtNameErr(false)
                                : setFisrtNameErr(true);
                            }}
                          />
                          {fisrtNameErr ? (
                            <p className="errMsg">Please enter first name</p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Last name<span className="errMsg">*</span>
                          </Form.Label>
                          <Form.Control
                            placeholder="Enter last name"
                            name="lastName"
                            value={lastName}
                            onChange={(e) => {
                              if (
                                !e.target.value.match(
                                  /[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/
                                ) &&
                                e.target.value.trim()
                              ) {
                                setLastName(e.target.value);
                              } else if (e.target.value.length === 0) {
                                setLastName(e.target.value);
                              }
                              e.target.value.length > 0
                                ? setLastNameErr(false)
                                : setLastNameErr(true);
                            }}
                          />
                          {lastNameErr ? (
                            <p className="errMsg">Please enter last name</p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Country Code<span className="errMsg">*</span>
                          </Form.Label>
                          <Select
                            isMulti={false}
                            isSearchable={true}
                            name="Country code"
                            value={mobileCountryCode}
                            options={countyCodeList}
                            onChange={(option) => {
                              // setGenderErr(false);
                              handleCountryCode(option);
                            }}
                          />
                          {mobileCountryErr ? (
                            <p className="errMsg">Please select Country Code for whatsApp Number</p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            WhatsApp Number<span className="errMsg">*</span>
                          </Form.Label>
                          <Form.Control
                            placeholder="Enter WhatsApp Number"
                            name="WhatsaapNo"
                            value={WhatsaapNo}
                            onChange={(e) => {
                              if (
                                !e.target.value.match(
                                  /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                                ) &&
                                e.target.value.length <= 10
                              ) {
                                setWhatsaapNo(e.target.value);
                              } else if (e.target.value.length === 0) {
                                setWhatsaapNo(e.target.value);
                              }
                              e.target.value.length > 0
                                ? setWhatsaapNoErr(false)
                                : setWhatsaapNoErr(true);
                            }}
                          />
                          {WhatsaapNoErr ? (
                            <p className="errMsg">
                              Please enter Whatsaap number
                            </p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Gender<span className="errMsg">*</span>
                          </Form.Label>
                          <Select
                            isMulti={false}
                            isSearchable={true}
                            name="gender"
                            value={gender}
                            options={genderList}
                            onChange={(option) => {
                              setGenderErr(false);
                              handleGender(option);
                            }}
                          />
                          {genderErr ? (
                            <p className="errMsg">Please select gender</p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Date of Birth<span className="errMsg">*</span>
                          </Form.Label>
                          <Form.Control
                            className=""
                            type="date"
                            name="birthDate"
                            value={birthDate} // min={new Date().toISOString().split("T")[0]}
                            max={lastDayOfJuly2023}
                            onKeyDown={(event) => event.preventDefault()}
                            onChange={(e) => {
                              setBirthDate(e.target.value);
                              birthDate?.length < 0
                                ? setBirthDateErr(true)
                                : setBirthDateErr(false);
                            }}
                          />
                          {birthDateErr ? (
                            <p className="errMsg">Please select birth date</p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Age<span className="errMsg">*</span>
                          </Form.Label>
                          <Form.Control
                            className=""
                            name="age"
                            placeholder="Enter your age"
                            value={age}
                            onChange={(e) => {
                              if (
                                !e.target.value.match(
                                  /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                                ) &&
                                e.target.value.length <= 3
                              ) {
                                setAge(e.target.value);
                              } else if (e.target.value.length === 0) {
                                setAge(e.target.value);
                              }
                              age?.length < 0
                                ? setAgeErr(true)
                                : setAgeErr(false);
                            }}
                          />
                          {ageErr ? (
                            <p className="errMsg">Please select age</p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Category</Form.Label>
                          <Select
                            isMulti={false}
                            isSearchable={true}
                            value={category}
                            options={commonData?.categories}
                            onChange={(option) => {
                              // setCategoryErr(false)
                              setCategory(option);
                            }}
                          />
                          {/* {categoryErr ? <p className='errMsg'>Please select category</p> : ''} */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Modal.Footer>
                      {loader ? (
                        <Spinner size="sm" />
                      ) : (
                        <Button className="primaryBtn mx-auto" type="submit">
                          <Icon.PersonAdd className="me-2" />
                          Add Consumer
                        </Button>
                      )}
                    </Modal.Footer>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="multipleConsumer">
                  <Row md={12} className="mt-4">
                    <Col className="text-center">
                      <button
                        className="btn btn-danger mx-auto d-flex"
                        onClick={CustomExcel}
                      >
                        <Icon.BoxArrowInDown size={18} className="me-2" />
                        Download Sample Excel
                      </button>
                    </Col>
                  </Row>

                  <Modal.Body className="pb-0">
                    <Form
                      onSubmit={addMultiplConsumer}
                      method="post"
                      enctype="multipart/form-data"
                      action={
                        process.env.REACT_APP_BASE_URL +
                        "/consumer/uploadConsumers"
                      }
                    >
                      <Row>
                        <Row className="justify-content-center">
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Upload consumers data excel file
                                <span className="errMsg">*</span>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                name="file"
                                onChange={(e) => {
                                  setFile(e.target.files[0]);
                                  setFileErr(false);
                                }}
                              />
                              {fileErr ? (
                                <p className="errMsg">
                                  Please upload excel file
                                </p>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="justify-content-center">
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Category</Form.Label>
                              <Select
                                isMulti={false}
                                isSearchable={true}
                                value={category}
                                options={commonData?.categories}
                                onChange={(option) => {
                                  // setCategoryErr(false)
                                  setCategory(option);
                                }}
                              />
                              {/* {categoryErr ? <p className='errMsg'>Please select category</p> : ''} */}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Row>

                      <Modal.Footer>
                        {loader ? (
                          <center>
                            <Spinner variant="primary" />
                          </center>
                        ) : (
                          <Button className="primaryBtn mx-auto" type="submit">
                            <Icon.PersonAdd className="me-2" />
                            Add Consumers
                          </Button>
                        )}
                      </Modal.Footer>
                    </Form>
                  </Modal.Body>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Modal.Body>
        </Modal>

        {/* ---------------- Edit Consumer Modal ----------------------------------------------------------- */}
        <Modal
          size="md"
          show={showEdit}
          backdrop="static"
          keyboard={false}
          centered
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="mb-0">
                <Icon.PencilSquare size={20} className="me-2" />
                Edit Consumer
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    First name<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter first name"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        setFirstName(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setFirstName(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setFisrtNameErr(false)
                        : setFisrtNameErr(true);
                    }}
                  />
                  {fisrtNameErr ? (
                    <p className="errMsg">Please enter name</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Last name<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter last name"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        setLastName(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setLastName(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setLastNameErr(false)
                        : setLastNameErr(true);
                    }}
                  />
                  {lastNameErr ? (
                    <p className="errMsg">Please enter valid name</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    WhatsApp Number<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter WhatsApp Number"
                    name="WhatsaapNo"
                    value={WhatsaapNo}
                    disabled={true}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                        ) &&
                        e.target.value.length <= 10
                      ) {
                        setWhatsaapNo(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setWhatsaapNo(e.target.value);
                      }
                      e.target.value.length > 0
                        ? setWhatsaapNoErr(false)
                        : setWhatsaapNoErr(true);
                    }}
                  />
                  {WhatsaapNoErr ? (
                    <p className="errMsg">Please enter Whatsaap number</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Gender<span className="errMsg">*</span>
                  </Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name="gender"
                    value={gender}
                    options={genderList}
                    onChange={(option) => {
                      handleGender(option);
                    }}
                  />
                  {genderErr ? (
                    <p className="errMsg">Please select gender</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Date of Birth<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    className=""
                    type="date"
                    name="birthDate"
                    value={birthDate}
                    // min={new Date().toISOString().split("T")[0]}
                    max={lastDayOfJuly2023}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={(e) => {
                      setBirthDate(e.target.value);
                      birthDate?.length < 0
                        ? setBirthDateErr(true)
                        : setBirthDateErr(false);
                    }}
                  />
                  {birthDateErr ? (
                    <p className="errMsg">Please select birth date</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Age<span className="errMsg">*</span>
                  </Form.Label>
                  <Form.Control
                    className=""
                    name="age"
                    placeholder="Enter your age"
                    value={age}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                        ) &&
                        e.target.value.length <= 3
                      ) {
                        setAge(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setAge(e.target.value);
                      }
                      age?.length < 0 ? setAgeErr(true) : setAgeErr(false);
                    }}
                  />
                  {ageErr ? <p className="errMsg">Please select age</p> : ""}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Category</Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name="categoryName"
                    value={category}
                    options={commonData?.categories}
                    onChange={(option) => {
                      setCategory(option);
                    }}
                  />
                  {/* {categoryErr ? <p className='errMsg'>Please select category</p> : ''} */}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="primaryBtn"
              onClick={editConsumer}
              disabled={loader}
            >
              {loader ? (
                <Spinner size="sm" />
              ) : (
                <Icon.PencilSquare className="me-2" />
              )}
              Edit Consumer
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ---------------- Delete Consumer Modal ----------------------------------------------------------- */}
        <Modal
          size="md"
          show={showDel}
          backdrop="static"
          keyboard={false}
          centered
          onHide={() => setShowDel(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h5 className="mb-0">
                <Icon.Trash size={20} className="me-2" />
                Delete Consumer
              </h5>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center mb-0">Are you sure to delete ?</h4>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="primaryBtn"
              onClick={deleteConsumer}
              disabled={loader}
            >
              {loader ? <Spinner size="sm" /> : <Icon.Trash className="me-1" />}
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ------------- Filter Consumer model -------------------------------------------------------- */}
        {/* <Modal size='md' show={showConsumerFilter} backdrop='static' keyboard={false} centered onHide={handleFilterClose}>
            <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.Funnel size={20} className='me-2'/>Filter</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Name</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='name'
                            value={filterName}
                            options={filterOptionList?.consumerNameOptions}
                            onChange={(option) => {
                                setFilterName(option);
                            }}/>
                        </Form.Group> 
                    </Col>

                    <Col md={12}>
                        <Form.Group className='mb-3'>
                        <Form.Label>WhatsApp Number</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='WhatsaapNo'
                            value={filterWhatsapp}
                            options={filterOptionList?.whatsappNoOptions}
                            onChange={(option) => {
                                setFilterWhatsapp(option);
                            }}/>
                        </Form.Group> 
                    </Col>
                </Row> 
            </Modal.Body>

            <Modal.Footer>
                <Button className='primaryBtn' onClick={clearFilter} disabled={loader}><Icon.XCircle className='me-2'/>Reset</Button>
                <Button className='primaryBtn' onClick={getFilteredConsumer} disabled={loader}> 
                { loader ? <Spinner size='sm'/> : <Icon.Funnel className='me-2'/> }Submit</Button>
            </Modal.Footer>
        </Modal> */}
      </Container>
    </div>
  );
};
