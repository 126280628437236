import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { ToastContainer, toast } from 'react-toastify';

const initialState = {
  userData: '',
  loading: 'idle', // idle, pending, failed
};

export const getUserData = createAsyncThunk("getUserDataPromise", async (data, {rejectWithValue})=>{
  const response = await fetch(process.env.REACT_APP_BASE_URL + '/user/login',{
  method:"POST",
  headers:{"Content-Type":"application/json"},
  body:JSON.stringify(data)
  })
  try{
      const result = await response.json();
      console.log(result)
      return result;
  }catch(error){
      return rejectWithValue(error); 
  }
}
)


const loginSlice = createSlice({
  name: 'userData',
  initialState,
  reducers:{
    setLogout(state, action){
      state.userData = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserData.pending, (s, a) => {
        console.log('inside loading');
        s.loading = 'pending';
      })
      .addCase(getUserData.rejected, (s, a) => {
        s.loading = 'idle';
        console.log('inside failed');
        toast.error('Failed, try again');
      })
      .addCase(getUserData.fulfilled, (s, a) => {
        s.userData = a.payload;
        console.log('inside fulfilled');
        s.loading = 'success';
      });
  },
});


export const {setLogout} = loginSlice.actions;
export default loginSlice.reducer;