const API_BASE_URL = process.env.REACT_APP_BASE_URL; // Centralized base URL

// API Routes with Method Information
const apiConfig = {

    auth: {
      login: { path: "/auth/login", method: "POST" },
      logout: { path: "/auth/logout", method: "POST" },
      getDropdown:{ path:'/user/dropdownValues',method:'POST' }
    },
    dashboard:{
        getCards:{ path:'/dashboard/cards', method:'POST'}
    },
    user:{
        getUsers: { path: "/user", method: "POST" },
        filterOptions: { path: "/user/filterOptions", method: "GET" },
        addUser:{ path: "/user/register", method: "POST" },
        updateUser:{ path: "/user/update", method: "POST" },
        deleteUser:{ path: "/user/delete", method: "GET" },
        changePassword: { path: "/user/update/password", method: "POST" },

    },
    consumer:{
        getConsumers : { path :"/consumer", method:'GET'},
        addConsumers : { path:"/consumer/create", method:"POST"},
        uploadConsumers : { path:"/consumer/uploadConsumers", method:"POST"},
        editConsumers : { path:"/consumer/update", method:"POST"},
        deleteConsumers : { path:"/consumer/delete", method:"GET"},
        getCoutryCodes: { path:"/country", method:"GET"},
    },
    department:{
      getDepartment:{ path:"/department",method:'GET' },
      addDepartment:{ path:'/department/create',method:'POST'},
      updateDepartment:{ path:'/department/update',method:'POST'},
      deleteDepartment:{ path:'/department/delete',method:'POST'},
      viewDepartment:{ path:'/user',method:'GET'},
      showDepartment:{ path:'/department/show',method:'GET'},
      syncWithCloud:{ path:'/department/updateAPIKey',method:'POST'},
      setUpWebHook:{ path:'/department/setup/webhook',method:'POST'},
      setUpChannelDetails:{ path:'/department/setup/channelDetails',method:'POST'},
    },
    departmentCredit:{
      getDepartmentCredit:{ path:"/departmentCredit",method:'GET' },
      addDeprtCredit:{ path:'/departmentCredit/create',method:'POST'},
      deleteDeprtCredit:{ path:'/departmentCredit/delete',method:'POST'},
      filterDeprtCredit:{ path:'/departmentCredit/filter',method:'GET'},
      filterOptions:{ path:'/departmentCredit/filterOptions',method:'GET'},
    },
    template:{
      getTemplates:{ path:'/template',method:'POST' },
      addTemplate:{ path:'/template/create',method:'POST' },
      updateTemplate:{ path:'/template/update',method:'POST' },
      deleteTemplate:{ path:'/template/delete',method:'POST' },
      templateFilterOptions:{ path:'/template/filterOptions',method:'GET' },
      filterTemplate:{ path:'/template/filter' , method:'POST' },
      updateStatus:{ path:'/template/updateStatusThrough360System',method:'POST' },
      showTemplate:{ path:'/template/show',method:'GET' },
      approveTemplate:{ path:'/template/approveTemplate',method:'POST' },

    },
    messenger:{
      getDepartmentHeadConversations:{ path:'/conversation/departmentHeadConversations',method:'POST' },
      sendMessage:{ path:'/conversation/sendMessage',method:'POST' },
      sendMessageWithoutTemplate:{ path:'/conversation/sendMessageWithoutTemplate',method:'POST' },
      getDepartmentHeadConversations:{ path:'/conversation/departmentHeadConversations',method:'POST' },
      getAllConsumers:{ path:'/consumer',method:'GET' },
      sendBulkMessage:{ path:'/conversation/sendBulkMessage',method:'POST' },
    },
    category:{
      getCategories:{ path:'/category/filter',method:'POST' },
      addCategory:{ path:'/category/create',method:'POST' },
      editCategory:{ path:'/category/update',method:'POST' },
      deleteCategory:{ path:'/category/delete',method:'POST' },
      categoryFilterOptions:{ path:'/category/filterOptions',method:'GET' },
    }
};

// Utility function to build the complete URL
const getApiUrl = (endpoint, queryParams = "",extendedRoute = "") => {
  return `${API_BASE_URL}${endpoint}${extendedRoute}${queryParams ? `?${queryParams}` : ""}`;
};

export { apiConfig, getApiUrl };
