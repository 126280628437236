import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import User from '../../Assets/User.svg';
import DashConsumer from '../../Assets/DashConsumer.svg';
import DashDept from '../../Assets/DashDept.svg';
import DashTemplate from '../../Assets/DashTemplate.svg';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setActiveKey } from './ActiveKeySlice';
import useApiCall from '../../Hooks/useApiCall';


const Dashboard = () => {

  const { apiCall } = useApiCall();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [cards, setCards] = useState({});
  const [loader, setLoader] = useState(false);

  const userState = useSelector(state => state.loginData.userData.data);

  const cardsData = [
    {
      title: "Users",
      count: 'userCount',
      activeData:{activeKey: 5, subActiveKey: 5.1, activeTab: 'Manage Users'},
      route: "/dashboard/manage-users",
      gradient: "linear-gradient(to right, #c5ff95, #b6fb7d,  #adfa5b)",
      color:'#468700',
      image: User 
    },
    {
      title: "Consumers",
      count: 'consumerCount',
      activeData:{activeKey: 2, subActiveKey: 2, activeTab: 'Manage Consumers'},
      route: "/dashboard/manage-consumer",
      gradient: "linear-gradient(to right,#8affff,  #71ffff, #0afcfc)",
      color:'#006971',
      image: DashConsumer 
    },
    {
      title: "Departments",
      count: 'departmentCount',
      activeData:{activeKey: 5, subActiveKey: 5.2, activeTab: 'Manage Department'},
      route: "/dashboard/manage-department",
      gradient: "linear-gradient(to right, #ff8167, #ff6c44, #ff6756)",
      color:'#8a0e00',
      image: DashDept 
    },
    {
      title: "Templates",
      count: 'templateCount',
      activeData:{activeKey: 6, subActiveKey: 6.1, activeTab: 'Manage Templates'},
      route: "/dashboard/manage-templates",
      gradient: "linear-gradient(to right, #eb89ff, #e45fff, #dd33ff)",
      color:'#62006e',
      image: DashTemplate 
    },
    {
      title: "Total Credits",
      count: 'totalCreditsForDepartment',
      activeData:{activeKey: 6, subActiveKey: 6.1, activeTab: 'Manage Templates'},
      route: null,
      gradient: "linear-gradient(to right, #eb89ff, #e45fff, #dd33ff)",
      color:'#62006e',
      image: DashTemplate 
    },
  ];
  
  useEffect(()=>{
    getDashCards();
  },[])

  const getDashCards = async()=>{
    setLoader(true);
    try {
      const response = await apiCall({
        apiGroup:"dashboard", // This is API group
        apiRoute:"getCards", // This is API route
        payload:{ departmentId: userState?.userTypeKey === "admin" ? "" : userState?.departmentId } // This is API Payload
    });

    let obj = response?.data
    let count = 0
    response.data.totalCreditsForDepartment.map( (row) => {
      count += row.totalCreditPoints
    } )
      obj.totalCreditsForDepartment = count
      setCards(obj);
    } catch (err) {
      console.error("Error fetching cards:", err);
    } finally {
      setLoader(false);
    }
  }

  // const getDashCards = async ()=>{
  //   setLoader(true);
  //   await fetch(process.env.REACT_APP_BASE_URL + '/dashboard/cards', {
  //     method:'POST',
  //     headers:{'Content-Type':'application/json'},
  //     body:JSON.stringify({ departmentId: userState?.userTypeKey === "admin" ? "" : userState?.departmentId })
  //   }).then((res)=>res.json()).then((response)=>{
  //     setCards(response.data);
  //     setLoader(false);
  //   }).catch((Err)=>{
  //     console.log("Err while getting dashcards", Err);
  //     setLoader(false);
  //   })
  // }

  var today = new Date();
  var curHr = today.getHours();

  // Accordian changes 
  const handleAccordionChange = async(row) => {
    if(row.route !== null){
      await dispatch(setActiveKey(row?.activeData));
      navigate(row?.route);
    }
  };


  return (
    <div className='Dashboard outletPadding'>
      <Container>
      <h6 className='mb-1'>Hello { curHr < 12 ? <>Good Morning<Icon.BrightnessHighFill className='ms-2 me-1' />,</>
                  : curHr < 18 ? <>Good Afternoon<Icon.BrightnessAltHighFill className='ms-2 me-1' size={20} />,</> :
                    <>Good Evening<Icon.MoonStars className='ms-2 me-1' /> ,</>}</h6>
      <h2>Welcome To Dashboard</h2>

        <Row className='mt-4'>
        {
            cardsData?.map((row, index) => (
              userState?.userTypeKey === "DH" && row.title === "Manage Departments" ? null :
              <Col key={index} md={3} data-aos="fade-up" data-aos-delay={100 * (index + 1)} className='mb-5'>
                <Card onClick={() => {handleAccordionChange(row)} }>
                  <Card.Body style={{background:row?.gradient}}>
                    { loader? <Spinner animation="grow" variant="secondary" className='mb-2'/> : 
                      <h2 style={{color:row?.color}}>
                        {cards[row.count]}</h2>
                    }
                    <h5>{row.title}</h5>
                  </Card.Body>
                  <div className="card-image" style={{ backgroundImage: `url(${row.image})` }}></div>
                </Card>
              </Col>
        ))}
        </Row>

   

      </Container>
    </div>
  )
}

export default Dashboard;