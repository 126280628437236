import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Form, Spinner } from 'react-bootstrap';
import Pagination from '../Common/Pagination'; 
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import { AddTemplateModel } from './Modal/AddTemplateModel';
import { useNavigate } from 'react-router-dom';
import wpApproveImg from '../../Assets/whatsapp approved.svg'
import whatsaapPending from "../../Assets/whatsapp pending.svg";
import whatsaapReject from "../../Assets/whatsapp rejected.svg";
import statusApprove from "../../Assets/approved.svg";
import statusPending from "../../Assets/pending.svg";
import statusRejected from "../../Assets/rejected.svg";
import deletes from "../../Assets/delete.svg";
import views from "../../Assets/View.svg";
import refresh from "../../Assets/refresh.svg";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import useApiCall from "../../Hooks/useApiCall"
import { setCommonData } from './commonSlice';

const ManageTemplates = () => {

    const { apiCall } = useApiCall()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userState = useSelector(state=>state?.loginData?.userData?.data);

  const [showAdd, setShowAdd]  = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showFilterModel, setShowFilterModel] = useState(false)
  const [loader, setLoader] = useState(false);
  const [editData, setEditData] = useState()
  const [item, setItem] = useState()

  const [filterOptionList, setFilterOptionList] = useState([])
  const [filterTempTitle, setFilterTempTitle] = useState("")
  const [filterDepart, setFilterDepart] = useState("")
  const [filterCreatedBy, setFilterCreatedBy] = useState("")
  const [filterChannelDate, setFilterChannelDate] = useState("")
  const [filterStatus, setFilterStatus] = useState("")
  const [filterWhatsaap, setFilterWhatsaap] = useState("")

  const closeAddModel = () =>{
    setShowAdd(false)
  }

  const reCallData = ()=>{
    getAllTemplateData()
  }



  const closeFilterModel = () =>{
    setShowFilterModel(false)
  }

  const handleShowEdit = (id) =>{
    setShowEdit(id); 
    setEditData(id)
    setItem(id)
  }
  
  // States for form Select option ------
      const [templateList, setTemplateList] = useState();
      const [id, setId] = useState();

  // States for Pagination ----------
      const [pageNo, setPageNo] = useState(1);
      const [docPerPage, setDocPerPage] = useState(10);
      const [noOfPages, setNoOfPages] = useState();

// States of Delete user -----------------------
     const [delId, setDelId] = useState();

  useEffect(()=>{
    getAllTemplateData();
  },[pageNo, docPerPage])

  useEffect(()=>{
    getAllDropDowns();
  },[])

    const getAllDropDowns = async () => {
      try {
        const response = await apiCall({
          apiGroup: "auth",
          apiRoute: "getDropdown",
          payload: {
            userTypeKey: userState?.data?.userTypeKey === "DH" ? "DH" : "",
            departmentId:
              userState?.data?.userTypeKey === "DH"
                ? userState?.data?.departmentId
                : "",
          },
        });
  
        dispatch(setCommonData(response?.data));
      } catch (err) {
        console.log("Error while getting users", err);
      }
    };

  const getAllTemplateData = async() =>{
    setLoader(true);
    try{

        const response = await apiCall({
            apiGroup: "template",
            apiRoute: "getTemplates",
            queryParams:`pageNo=${pageNo}&docPerPage=${docPerPage}`,
            payload: {
                departmentId : userState?.userTypeKey === "admin" ? "" : userState?.departmentId
            },
          });

        // const res =  await fetch(process.env.REACT_APP_BASE_URL + `/template?pageNo=${pageNo}&docPerPage=${docPerPage}`,{
        //     method:'POST',
        //     headers:{'Content-Type':'application/json'},
        //     body:JSON.stringify({
        //       departmentId : userState?.userTypeKey === "admin" ? "" : userState?.departmentId
        //   })
        // });
        // const response = await res.json();
        setTemplateList(response?.data?.templates);
        setNoOfPages(response.data.noOfPages);
        setLoader(false);
    }catch(err){
          console.log("Error while getting users", err);
          setLoader(false);
      }
  }


    const deleteTemplate = async() =>{  
      setLoader(true);

        try{

            const response = await apiCall({
                apiGroup: "template",
                apiRoute: "deleteTemplate",
                extendedRoute:`/${delId}`
              });
              if(response.message === "Record Delete Successfully...!"){
                toast.success("User deleted successfully !");
                setShowDel(false);
                getAllTemplateData();
            }
            setLoader(false);
        }catch(err){
            console.log("Err while adding user", err);
            setLoader(false);
        }

        //   await fetch(`https://api.itgoa.foxberry.live/v1/template/delete/${delId}`,{
        //       method:'POST',
        //       headers:{'Content-Type':'application/json'}
        //   }).then((res)=>res.json()).then((response)=>{
        //       if(response.message === "Record Delete Successfully...!"){
        //           toast.success("User deleted successfully !");
        //           setShowDel(false);
        //           getAllTemplateData();
        //       }
        //       setLoader(false);
        //   }).catch((err)=>{
        //       console.log("Err while adding user", err);
        //       setLoader(false);
        //   }) 
    }

    const getFilterTemplateData = async() =>{

        try{
            const response = await apiCall({
                apiGroup: "template",
                apiRoute: "filterTemplate",
                payload:{
                    templateTitle : filterTempTitle.value,
                    departmentName : filterDepart.value, 
                    createdBy : filterCreatedBy.value, 
                    channelEndDate : filterChannelDate,
                    isApprove : filterStatus.value, 
                    isWhatsappPannelApprove : filterWhatsaap.value
                },
                queryParams:`pageNo=${pageNo}&docPerPage=${docPerPage}`
              });

            setTemplateList(response.data.templates)
            setShowFilterModel(false)
        }catch(err){
            console.log("err",err);

        }

        // await fetch(process.env.REACT_APP_BASE_URL + `/template/filter?pageNo=${pageNo}&docPerPage=${docPerPage}`,{
        //     method:'POST',
        //     headers:{'Content-Type':'application/json'},
        //     body:JSON.stringify({
        //         templateTitle : filterTempTitle.value,
        //         departmentName : filterDepart.value, 
        //         createdBy : filterCreatedBy.value, 
        //         channelEndDate : filterChannelDate,
        //         isApprove : filterStatus.value, 
        //         isWhatsappPannelApprove : filterWhatsaap.value
        //     })
        // }).then((res) => res.json())
        // .then((response) => {
        //     setTemplateList(response.data.templates)
        //     setShowFilterModel(false)
        // })
        // .catch((err)=>{
        //     console.log("err",err);
        // })
    }

    useEffect(()=>{
        filterOptions()
    },[])
  
    const filterOptions = async() =>{

        try{

            const response = await apiCall({
                apiGroup: "template",
                apiRoute: "templateFilterOptions",
              });
            setFilterOptionList(response.data)

        }catch(err){
            console.log("err",err);

        }

        // await fetch(process.env.REACT_APP_BASE_URL + '/template/filterOptions',{
        //     method:'GET',
        //     headers:{'Content-Type':'application/json'}
        // }).then((res)=>res.json())
        // .then((response)=>{
        //     setFilterOptionList(response.data)
        // })
        // .catch((err)=>{
        //     console.log("err",err);
        // })
    }

    const clearFilter = () =>{
        setFilterTempTitle("")
        setFilterDepart("")
        setFilterCreatedBy("")
        setFilterChannelDate("")
        setFilterStatus("")
        setFilterWhatsaap("")
    }

      // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };
  
    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    // update status by 360 system
    const updateby360System = async(templateId) =>{

        try{
            const response = await apiCall({
                apiGroup: "template",
                apiRoute: "updateStatus",
                payload:{
                    templateId:templateId 
                }
              });

              if(response.code === 200){
            getAllTemplateData()

              }else{
                getAllTemplateData()
              }
        }catch(err){
            console.log("err",err);

        }

        // await fetch(process.env.REACT_APP_BASE_URL + '/template/updateStatusThrough360System',{
        //     method:'POST',
        //     headers:{'Content-Type':'application/json'},
        //     body:JSON.stringify({
        //         templateId:templateId 
        //     })
        // }).then((res)=>res.json())
        // .then((response)=>{
        //     getAllTemplateData()
        // })
        // .catch((err)=>{
        //     console.log("err",err);
        // })
    }

return (
    <div className='ManageUsers outletPadding'>
        {/* <ToastContainer/> */}
        <AddTemplateModel show={showAdd} close={closeAddModel} reCallData={reCallData}/>
        {/* <EditTemplateModel show={showEdit} close={closeEditModel} reCallData={reCallData} itm={item}/> */}
        <Container>
            <Row>
            <Row className='justify-content-end align-items-center mb-4'>
                <Col md={2} className='d-flex justify-content-end'>
                    <Button className='primaryBtn' onClick={()=>setShowAdd(true)}><Icon.EnvelopePlus className='me-2'/>Add Template</Button>
                </Col>
            </Row>
                <div className="outer-wrapper mx-auto">
                {
                    loader ? <center><Spinner variant='primary' className='mt-3'/></center> :
                    <div className="table-wrapper" data-aos="fade-up">
                        <table>
                            <thead>
                                <th>Sr No</th>
                                <th>Template Title</th>
                                <th>Department Name</th>
                                <th>{userState?.userTypekey==="admin" ? 'Department' : 'Channel End Date'}</th>
                                <th>Admin Approve</th>
                                <th>WhatsApp Approve</th>
                                <th>Actions</th>
                            </thead>
                            {
                                templateList!== null && templateList !== undefined && templateList.length > 0 ? templateList.map((itm, index) => {

                                    const { templateTitle, departmentName, isApprove,
                                            isWhatsappPannelApprove, templateId, channelEndDate, credits } = itm

                                    return (
                                        <tr key={Math.random() * 999999999}>
                                            <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                            <td>{templateTitle}</td>
                                            <td>{departmentName}</td>
                                            <td>{userState?.userTypekey==="admin" ?  departmentName : moment(channelEndDate).format('DD-MM-YYYY')}</td>
                                            <td>
                                                {isApprove === "Pending" ? (
                                                    <div className='status'>
                                                    <img src={statusPending} alt="Pending"/>Pending
                                                    </div>
                                                ) : isApprove === "Reject" ? (
                                                    <div className='status'>
                                                    <img src={statusRejected} alt="Reject"/>Rejected
                                                    </div>
                                                ) : isApprove === "Approve" ? (
                                                    <div className='status'>
                                                    <img src={statusApprove} alt="Approve"/>Approved
                                                    </div>
                                                ) : null}
                                            </td>
                                            <td style={{ width:'100%',display: 'flex', justifyContent:'center'}}>
                                                {(isWhatsappPannelApprove === 'pending' || isWhatsappPannelApprove === "submitted") && isApprove === "Approve" ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={whatsaapPending} alt='Pending' className='me-4 icon' />
                                                    Pending
                                                    </div>
                                                ) : isWhatsappPannelApprove === 'rejected' ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={whatsaapReject} alt='Reject' className='me-4 icon' />
                                                    Rejected
                                                    </div>
                                                ) : isWhatsappPannelApprove === 'approved' ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={wpApproveImg} alt='Approve' className='me-4 icon' />
                                                    Approved
                                                    </div>
                                                ) :  
                                                        <span>N.A.</span>
                                                    }
                                                {(isWhatsappPannelApprove === 'pending' || isWhatsappPannelApprove === "submitted") && isApprove === "Approve" ? (
                                                    <img
                                                    src={refresh}
                                                    className='icon ms-4'
                                                    style={{ cursor: 'pointer', width: '16px' }}
                                                    onClick={() => updateby360System(templateId)}
                                                    />
                                                ) : ''}
                                            </td>
                                        
                                           
                                            <td style={{width:"12vw"}}>
                                                <img src={views} className='me-4 icon' style={{ cursor:'pointer' }} 
                                                    onClick={()=>{navigate("/dashboard/view-single-template", {state: { data: itm }})}}/>
                                                {/* <img src={edit} className='me-4 icon' style={{ cursor:'pointer' }} 
                                                    onClick={()=>{handleShowEdit(itm)}}/>  */}
                                                <img src={deletes} className='icon' style={{ cursor:'pointer' }} 
                                                    onClick={()=>{setShowDel(true); setDelId(itm?._id)}}/>
                                            </td>
                                        </tr>
                                    )
                                  })
                                  : 'No data Found'
                            }
                        </table>
                    </div>
                }
                </div>
                <Pagination currentPage={pageNo} totalPages={noOfPages}
                  onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage}/>
            </Row>
          
{/* ---------------- Delete User Modal ----------------------------------------------------------- */}
          <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={() => setShowDel(false)}>
              <Modal.Header closeButton>
                  <Modal.Title> <h4 className='mb-0'><Icon.Trash size={20} className='me-2'/>Delete User</h4> </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <h4 className='text-center mb-0'>Are you sure to delete ?</h4>    
              </Modal.Body>

              <Modal.Footer>
                  <Button className='primaryBtn' onClick={deleteTemplate} disabled={loader}> 
                  { loader ? <Spinner size='sm'/> : <Icon.Trash className='me-1'/> }Delete</Button>
              </Modal.Footer>
          </Modal>

{/* ----------------- Filter model --------------------------------------------------------------- */}
        <Modal size='lg' show={showFilterModel} backdrop='static' keyboard={false} centered onHide={closeFilterModel}>
            <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'><Icon.PersonAdd size={20} className='me-2' />Filter</h4> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Department Name</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='departmentName'
                            value={filterDepart}
                            options={filterOptionList?.departmentOptions}
                            onChange={(option) => {
                                setFilterDepart(option);
                            }}/>
                        </Form.Group>
                    </Col>
                </Row>

                {/* <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Created by</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='templateTitle'
                            value={filterCreatedBy}
                            options={filterOptionList?.createdByOptions}
                            onChange={(option) => {
                                setFilterCreatedBy(option);
                            }}/>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Channel End Date</Form.Label>
                        <Form.Control
                            type='date'
                            placeholder='select Channel end  date'
                            name='channelEndDate'
                            value={filterChannelDate}
                            onChange={(e) => {
                                setFilterChannelDate(e.target.value);
                            }}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Status</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='isApprove'
                            value={filterStatus}
                            options={approveStatus}
                            onChange={(option) => {
                                setFilterStatus(option);
                            }}/>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                        <Form.Label>Whatsapp status</Form.Label>
                        <Select
                            isMulti={false}
                            isSearchable={true}
                            name='isWhatsappPannelApprove'
                            value={filterWhatsaap}
                            options={whatsaapApprove}
                            onChange={(option) => {
                                setFilterWhatsaap(option);
                            }}/>
                        </Form.Group>
                    </Col>
                </Row> */}
            </Modal.Body>

            <Modal.Footer>
                <Button className='secondaryBtn' onClick={clearFilter} disabled={loader}>Reset</Button>
                <Button className='primaryBtn' onClick={getFilterTemplateData} disabled={loader}>
                {loader ? <Spinner size='sm' /> : <Icon.PersonAdd className='me-2' />}Submit</Button>
            </Modal.Footer>
        </Modal>
      
      </Container>
  </div>
)
}

export default ManageTemplates