import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './Features/Common/ScrollToTop';
import { useSelector } from "react-redux";
import S3Provider from "./Features/Common/S3Provider";

// Importing Commons -------
    import Login from "./Features/Auth/Login";
    import ForgotPass from "./Features/Auth/ForgotPass";
    import CommonLayout from "./Layout/CommonLayout";
    import Dashboard from "./Features/Common/Dashboard";
    import Profile from "./Features/Common/Profile";

// Importing Admin ---------
    import ManageUsers from "./Features/Admin/ManageUsers";
    import ManageRoles from "./Features/Admin/ManageRoles";
    import ManageTemplates from "./Features/Admin/ManageTemplates";
    import { ManageConsumer } from "./Features/Admin/ManageConsumer";
    import { ManageTemplateTarget } from "./Features/Admin/ManageTemplateTarget";
    import { ManageCategory } from "./Features/Admin/ManageCategory";
    import { ManageDepartCredit } from "./Features/Admin/ManageDepartCredit";
    import { ManageTemplateUserChannel } from "./Features/Admin/ManageTemplateUserChannel";
    import MessengerLayout from "./Features/Messenger/MessengerLayout";
    import { ViewSingleTemplate } from "./Features/Admin/ViewSingleTemplate";

    import Onboard from "./Features/Onboardings/Onboard";
    import Onboard360 from "./Features/Onboardings/Onboard360";
    import ThankYou from "./Features/Onboardings/ThankYou";
import ViewDept from "./Features/Admin/ViewDept";
import ManageDeptAdmin from "./Features/Admin/ManageDeptAdmin";


function App() {

  const userState = useSelector(state => state?.loginData?.userData);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        {/* <S3Provider> */}
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path="/forgot-pasword" element={<ForgotPass />} />

      {/* ---------------------- 360 Onboarding --------------------------- */}
          <Route path='/onboarding' element={<Onboard/>}/>
          <Route path='/onboarding360' element={<Onboard360/>}/>
          <Route path='/onboarding/thank-you' element={<ThankYou/>}/>

          {
            userState?.data?.userType === "Admin" || userState?.data?.userType === "Department Head" || userState?.data?.userType === "Department User" ?
           
            <Route path='/dashboard' element={<CommonLayout />}>
              <Route path='/dashboard/' element={<Dashboard />} />
              <Route path='/dashboard/manage-users' element={<ManageUsers/>}/>
              <Route path="/dashboard/manage-consumer" element={<ManageConsumer/>}/>
              <Route path="/dashboard/manage-category" element={<ManageCategory/>}/>
              <Route path="/dashboard/manage-department" element={<ManageDeptAdmin/>}/>
              <Route path="/dashboard/view-department" element={<ViewDept/>}/>
              <Route path="/dashboard/manage-department-credit" element={<ManageDepartCredit/>}/>
              <Route path='/dashboard/manage-templates' element={<ManageTemplates/>}/>
              <Route path="/dashboard/manage-template-target" element={<ManageTemplateTarget/>}/>
              <Route path="/dashboard/manage-template-user-channel" element={<ManageTemplateUserChannel/>}/>
              <Route path="/dashboard/view-single-template" element={<ViewSingleTemplate/>}/>
            
                <Route path="/dashboard/messenger" element={<MessengerLayout/>}/>

              <Route path='/dashboard/manage-roles' element={<ManageRoles/>}/>
              <Route path='/dashboard/profile' element={<Profile/>} />
            </Route> :
            ""
          }
        </Routes>
        {/* </S3Provider> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
