import React, { useState } from "react";
import { Card } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

const FileUploader = ({
  label,
  multiple = false,
  fileTypes = ["image"],
  maxSizeMB = 5,
  onFilesChange,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const mimeTypeMap = {
    image: "image/jpeg,image/png,image/gif",
    video: "video/mp4,video/ogg",
    pdf: "application/pdf",
    excel: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  const allowedMimeTypes = fileTypes.map((type) => mimeTypeMap[type]).join(",");

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validatedFiles = selectedFiles.filter((file) => {
      const isValidType = fileTypes.some((type) =>
        mimeTypeMap[type]?.split(",").includes(file.type)
      );
      const isValidSize = file.size <= maxSizeMB * 1024 * 1024;

      if (!isValidType) {
        setError(`Please select a valid ${fileTypes.join(" or ")} file.`);
        return false;
      }

      if (!isValidSize) {
        setError(`File size must not exceed ${maxSizeMB}MB.`);
        return false;
      }

      return true;
    });

    if (!validatedFiles.length) {
      return;
    }

    setError(null);

    try {
      setLoading(true);
      for (const file of validatedFiles) {
        const uploadedFile = await uploadFile(file);
        if (uploadedFile) {
          setUploadedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles, uploadedFile];
            // Notify the parent component with the updated state
            onFilesChange && onFilesChange(updatedFiles);
            return updatedFiles;
          });
        }
      }
      setLoading(false);
    } catch (err) {
      setError("File upload failed. Please try again.");
      setLoading(false);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    const response = await fetch(process.env.REACT_APP_BASE_URL + '/upload/photo', {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Upload failed");
    }

    const result = await response.json();
    return {
      ...result,
      preview: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
      url: result.url, 
    };
  };

  const removeFile = (fileUrl) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((file) => file.url !== fileUrl);
      // Notify the parent component with the updated state
      onFilesChange && onFilesChange(updatedFiles);
      return updatedFiles;
    });
  };

  const renderFilePreview = (file) => {
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={file.preview}
          alt="Uploaded"
          style={{ width: 40, height: 40, cursor: "pointer" }}
          onClick={() => window.open(file.url, "_blank")}
        />
      );
    } else if (file.type.startsWith("video/")) {
      return (
        <Icon.PlayCircleFill
          size={40}
          className="text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => window.open(file.url, "_blank")}
        />
      );
    } else if (file.type === "application/pdf") {
      return (
        <Icon.FileEarmarkPdf
          size={40}
          className="text-danger"
          style={{ cursor: "pointer" }}
          onClick={() => window.open(file.url, "_blank")}
        />
      );
    } else if (
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return (
        <Icon.FileEarmarkExcel
          size={40}
          className="text-success"
          style={{ cursor: "pointer" }}
          onClick={() => window.open(file.url, "_blank")}
        />
      );
    } else {
      return (
        <Icon.FileEarmark
          size={60}
          className="text-secondary"
          style={{ cursor: "pointer" }}
          onClick={() => window.open(file.url, "_blank")}
        />
      );
    }
  };

  return (
    <div>
      <div>
        <span style={{ color: "#6f6f6f", fontWeight: 600, fontSize:'14px'}}>{label}</span>
        <label htmlFor={`fileUploader-${label}`} id="uploadBtn" className="mt-1">
        <Icon.CloudArrowUp size={20} className="me-2" />Upload File{ multiple && "s"}
        </label>
        <input
          type="file"
          accept={allowedMimeTypes}
          id={`fileUploader-${label}`}
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple={multiple}
        />
      </div>
      {error && <div className="text-danger">{error}</div>}
      {uploadedFiles.length > 0 && (
        <div className="d-flex mt-2" style={{ flexWrap: "wrap" }}>
          {uploadedFiles.map((file, index) => (
            <Card className="uploadedFile m-1" key={index}>
            {renderFilePreview(file)}
            <Icon.XCircleFill
              className="removeIcon"
              size={18}
              onClick={() => removeFile(file.url)}
              
            />
          </Card>
          ))}
        </div>
      )}
      {loading && <div>Uploading...</div>}
    </div>
  );
};

export default FileUploader;
