import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Form, Spinner } from 'react-bootstrap';
import Pagination from '../Common/Pagination'; 
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';

const ManageRoles = () => {
    const [showAdd, setShowAdd]  = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [loader, setLoader] = useState(false);

    // created staging Branhch -----------
    // States for form Select option ------
        const [roles, setRoles] = useState();
        const [deptList, setDeptList] = useState();
        const [userTypeList, setUserTypeList] = useState();

    // States for Pagination ----------
        const [pageNo, setPageNo] = useState(1);
        const [docPerPage, setDocPerPage] = useState(10);
        const [noOfPages, setNoOfPages] = useState();

    // States of Add Role ----------------
        const [id, setId] = useState();
        const [roleType, setRoleType] = useState();
        const [level, setLevel] = useState()

    // States of Add User Error ----------------
        const [roleTypeErr, setRoleTypeErr] = useState(false);
        const [levelErr, setLevelErr] = useState(false)

  // States of Delete user -----------------------
       const [delId, setDelId] = useState();

    useEffect(()=>{
        getAllDept();
        getAllUserType();
    },[])

    const getAllDept=async()=>{
        await fetch(process.env.REACT_APP_BASE_URL + '/department',{
            method:'GET'
        }).then((res)=>res.json()).then((response)=>{
            setDeptList(response?.data?.departments);
        })
    }

    const getAllUserType=async()=>{
        await fetch(process.env.REACT_APP_BASE_URL + '/userType',{
            method:'GET'
        }).then((res)=>res.json()).then((response)=>{
            setUserTypeList(response?.data?.userTypes);
        })
    }

    useEffect(()=>{
      getAllRoles();
    },[pageNo, docPerPage])

    const getAllRoles = async()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + "/userType",{
            method:'GET',
            headers:{'Content-Type':'application/json'}
        }).then((res)=>res.json()).then((response)=>{
            setRoles(response?.data?.userTypes);
            setNoOfPages(response.data.noOfPages);
            setLoader(false);
        }).catch((err)=>{
            console.log("Error while getting users", err);
            setLoader(false);
        })
    }

    const closeAddModal=()=>{
        setRoleType('');
        setLevel('');

        setRoleTypeErr(false);
        setLevelErr(false);

        setShowAdd(false);
        setShowEdit(false);
    }

    const addValidate=()=>{
        let isValid = true;
        if(!roleType){
            isValid = false;
            setRoleTypeErr(true); 
        }
        else if(!level){
            isValid = false;
            setLevelErr(true);
        }

        return isValid;
    }

    const editValidate=()=>{
        let isValid = true;
        if(!roleType){
            isValid = false;
            setRoleTypeErr(true); 
        }
        else if(!level){
            isValid = false;
            setLevelErr(true);
        }
        return isValid;
    }

    const addRoleType=async()=>{
        setLoader(true);
      
        if(addValidate()){
            await fetch(process.env.REACT_APP_BASE_URL + '/userType/create',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                  userType:roleType,
                  level:level
                })
            }).then((res)=>res.json()).then((response)=>{
                if(response.message === "Record Created Successfully...!"){
                  toast.success("Role added successfully");
                    setLoader(false);
                    closeAddModal();
                    getAllRoles();
                }
            }).catch((err)=>{
                console.log("Err while adding user", err);
                setLoader(false);
            }) 
        }
        else{
            setLoader(false);
        }
    }

    /* ----- Edit Modal --------*/
    const setEditData=(itm)=>{
        setId(itm?._id);
        setRoleType(itm?.userType);
        setLevel(itm?.level)
    }

    const editUser= async ()=>{
        setLoader(true);
        if(editValidate()){
        await fetch(process.env.REACT_APP_BASE_URL + '/userType/update',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                id:id,
                userType:roleType,
                level:level,
            })
        }).then((res)=>res.json()).then((response)=>{
            if(response.message === "Record Updated Successfully...!"){
                toast.success("User updated successfully");
                setLoader(false);
                setShowEdit(false);
                closeAddModal();
                getAllRoles()
            }
        }).catch((err)=>{
            console.log("Err while updating user", err);
            setLoader(false);
        }) 
    }
    else{
        setLoader(false);
    }
    }

    const deleteRole=async()=>{
        setLoader(true);
            await fetch(process.env.REACT_APP_BASE_URL + `/userType/delete`,{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                  id:delId
                })
            }).then((res)=>res.json()).then((response)=>{
                if(response.message === "Record Delete Successfully...!"){
                    toast.success("User deleted successfully !");
                    setShowDel(false);
                    getAllRoles();
                }
                setLoader(false);
            }).catch((err)=>{
                console.log("Err while adding user", err);
                setLoader(false);
            }) 
        }
    
        // function for pagination
        const handlePageChange = (pageNumber) => {
            setPageNo(pageNumber);
        };
    
        const handleDocsPerPage = (docsPageProp) => {
            setPageNo(1)
            setDocPerPage(docsPageProp);
        }


  return (
    <div className='ManageUsers outletPadding'>
        {/* <ToastContainer/> */}
        <Container>
            <Row>
                <Row className='justify-content-between align-items-center mb-2'>
                    <Col md={3} className='d-flex mb-4 mb-md-0'>
                        <Form.Control placeholder='Enter Mobile No' className='me-3'/>
                        <Button className='primaryBtn'>Search</Button>
                    </Col>
                    <Col md={2} className='d-flex justify-content-end'>
                        <Button className='primaryBtn' onClick={()=>setShowAdd(true)}><Icon.PersonAdd className='me-2'/>Add Role</Button>
                    </Col>
                </Row>
                <div className="outer-wrapper mx-auto">
                {
                   loader ? <center><Spinner variant='primary' className='mt-3'/></center> :
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <th>Sr No</th>
                                <th>Role</th>
                                <th>Level</th>
                                <th>Actions</th>
                            </thead>
                            {
                                roles!== null && roles !== undefined && roles.length > 0 ? roles.map((itm, index) => {

                                        const { userType, level } = itm

                                        return (
                                            <tr key={Math.random() * 999999999}>
                                                <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                                <td>{userType}</td>
                                                <td>{level}</td>
                                                <td>
                                                    <Icon.PencilSquare size={20} className='me-3 icon' onClick={()=>{setShowEdit(true); setEditData(itm)}}/>
                                                    <Icon.Trash size={20} className='me-2 icon' onClick={()=>{setShowDel(true); setDelId(itm?._id)}}/>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : 'No data Found'
                            }

                        </table>
                    </div>
                }
                    
                </div>
                <Pagination currentPage={pageNo} totalPages={noOfPages}
                    onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docPerPage={docPerPage}/>

            </Row>

 {/* ---------------- Add User Modal ----------------------------------------------------------- */}
            <Modal size='md' show={showAdd} backdrop='static' keyboard={false} centered onHide={closeAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title> <h4 className='mb-0'><Icon.PersonAdd size={20} className='me-2'/>Add Role</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Role</Form.Label>
                            <Form.Control placeholder='Enter role type' name='userType' value={roleType} onChange={(e)=> {if (
                                !e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&  e.target.value.trim() ) {
                                    setRoleType(e.target.value);
                                } else if (e.target.value.length === 0) {
                                setRoleType(e.target.value);
                                }
                             e.target.value.length > 0 ? setRoleTypeErr(false) : setRoleTypeErr(true); }} />
                            {roleTypeErr ? <p className='errMsg'>Please enter valid role type</p> : ''}
                        </Form.Group> 
                        </Col>

                        <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Level</Form.Label>
                            <Form.Control placeholder='Enter level' name='level' value={level} onChange={(e)=> {if (
                                !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) &&  e.target.value.trim() ) {
                                    setLevel(e.target.value);
                                } else if (e.target.value.length === 0) {
                                  setLevel(e.target.value);
                                }
                             e.target.value.length > 0 ? setLevelErr(false) : setLevelErr(true); }} />
                            {levelErr ? <p className='errMsg'>Please enter valid level</p> : ''}
                        </Form.Group> 
                        </Col>
                    </Row>  
                </Modal.Body>

                <Modal.Footer>
                    <Button className='primaryBtn' onClick={addRoleType} disabled={loader}> 
                    { loader ? <Spinner size='sm'/> : <Icon.PersonAdd className='me-2'/> }Add Role</Button>
                </Modal.Footer>
            </Modal>

 {/* ---------------- Edit User Modal ----------------------------------------------------------- */}
            <Modal size='md' show={showEdit} backdrop='static' keyboard={false} centered onHide={closeAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title> <h4 className='mb-0'><Icon.PencilSquare size={20} className='me-2'/>Edit Role</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Role</Form.Label>
                            <Form.Control placeholder='Enter role' value={roleType} onChange={(e)=> {if (
                                !e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&  e.target.value.trim() ) {
                                    setRoleType(e.target.value);
                                } else if (e.target.value.length === 0) {
                                  setRoleType(e.target.value);
                                }
                             e.target.value.length > 0 ? setRoleTypeErr(false) : setRoleTypeErr(true); }} />
                            {roleTypeErr ? <p className='errMsg'>Please enter valid role type</p> : ''}
                        </Form.Group> 
                        </Col>

                        <Col md={6}>
                            <Form.Group className='mb-3'>
                            <Form.Label>Level</Form.Label>
                            <Form.Control placeholder='Enter mobile number' name='level' value={level}  onChange={(e)=> {
                                if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10) {
                                    setLevel(e.target.value);
                                } else if (e.target.value.length === 0) {
                                  setLevel(e.target.value);
                                }
                                e.target.value.length > 0 ? setLevelErr(false) : setLevelErr(true)
                            }}/>
                            {levelErr ? <p className='errMsg'>Please enter valid level</p> : ''}
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='primaryBtn' onClick={editUser} disabled={loader}> 
                    { loader ? <Spinner size='sm'/> : <Icon.PersonAdd className='me-2'/> }Edit User</Button>
                </Modal.Footer>
            </Modal>


 {/* ---------------- Delete User Modal ----------------------------------------------------------- */}
            <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={() => setShowDel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> <h4 className='mb-0'><Icon.Trash size={20} className='me-2'/>Delete User</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='text-center mb-0'>Are you sure to delete ?</h4>    
                </Modal.Body>

                <Modal.Footer>
                    <Button className='primaryBtn' onClick={deleteRole} disabled={loader}> 
                    { loader ? <Spinner size='sm'/> : <Icon.Trash className='me-1'/> }Delete</Button>
                </Modal.Footer>
            </Modal>
        
        </Container>
    </div>
  )
}

export default ManageRoles