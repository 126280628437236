import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';
import * as Icon from 'react-bootstrap-icons';
import Avatar from '../../Assets/Avatar.jpg';
import DefaultChatBg from '../../Assets/defaultChatBg.jpg';
import WhatsAppBg from '../../Assets/ChatBg.png';
import sendIconSvg from '../../Assets/sendFilled.svg';

import { formatDate, downloadMedia } from '../../Utils/CommonUtils';
import { AccountContext } from './AccountProvider';
import S3 from 'react-aws-s3';
import imageCompression from 'browser-image-compression';
import pdfIcon from '../../Assets/pdf.svg';
import xlsxIcon from '../../Assets/xlsx.svg';
import fileIcon from '../../Assets/File.svg';
import txtIcon from '../../Assets/Text.svg';
import { ToastContainer, toast } from 'react-toastify';
import io from 'socket.io-client';
import moment from 'moment/moment';
import useApiCall from '../../Hooks/useApiCall';
window.Buffer = window.Buffer || require("buffer").Buffer;




/*------------- Conversation Styling - (Right part of messenegerLayout)---------- */
const ConvHeader = styled.div`
background: #ededed;
padding:10px;
height:50px;
display:flex;
align-items:center;
justify-content:space-between;
`;

const Left = styled.div`
display:flex;
`;

const Right = styled.div`
display:flex;
`;

const Image = styled('img')`
height:30px;
width:30px;
border-radius:50%;
`;

const Name = styled.h6`
font-size:14px;
font-weight:600;
margin-left:10px;
margin-bottom:0px;
`
const Status = styled.p`
font-size:12px;
font-weight:500;
letter-spacing:0.5px;
margin-left:10px;
margin-bottom:0px;
color:rgba(0,0,0,0.6);
`

const Chats = styled.div`
background:url(${WhatsAppBg}) center center no-repeat;
background-size:cover;
height:78vh;
overflow-y:scroll;
padding:20px;
`;

const DefaultChat = styled.div`
background:url(${DefaultChatBg}) center center no-repeat;
background-size:cover;
height:93vh;
padding:10px;
display:flex;
align-items:center;
justify-content:center;
`;

const DefaultChatHead = styled.h1`
  color:#ffffff;
  text-shadow: rgba(0,0,0,0.5) 1px 0 10px;
  font-weight:700;
`;

const ConvFooter = styled.div`
  background: #ededed;
  padding: 0px 20px;
  height:55px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  & > svg{
  margin-right:15px;
  color:#919191;
  cursor:pointer;
  }
  & > label > svg{
    margin-right:15px;
    color:#919191;
    cursor:pointer;

  }
`;


const You = styled.div`
  background:#dcf8c6;
  margin-left:auto;
  width:fit-content;
  max-width:60%;
  padding: 6px 12px;
  display:flex;
  border-radius:10px;
  word-break:break-word;
  margin-bottom:5px;

  h6{
    font-size:14px;
    margin-bottom:0px;
    padding:0px 25px 0px 5px;
  }

p{
    font-size:10px;
    margin-top:auto;
    margin-bottom:0px;
    word-break:keep-all;

}
`;

const Them = styled.div`
  background:#ffffff;
  width:fit-content;
  max-width:60%;
  padding:6px 20px;
  display:flex;
  border-radius:10px;
  word-break:break-word;
  margin-bottom:5px;
  h6{
    font-size:14px;
    margin-bottom:0px;
    padding:0px 25px 0px 5px;
  }

p{
    font-size:10px;
    margin-top:auto;
    margin-bottom:0px;
    word-break:keep-all;

}
`;


// const socket = io('https://api.itgoa.foxberry.live/');
const socket = io("https://api.itgoa.foxberry.live");

const Conversation = () => {

  const { apiCall } = useApiCall()

  const userState = useSelector(state => state?.loginData?.userData?.data);
  const activeChatData = useSelector(state => state?.activeChatReducer?.activeChatData);


  const [conversation, setConversation] = useState([]);
  const [messages, setMessages] = useState([]);

  const [loader, setLoader] = useState(false);
  const [chatloader, setChatLoader] = useState(false);
  const [activeConsumerId, setActiveConsumerId] = useState('');
  const [messageloader, setMessageLoader] = useState(false);
  const [s3Data, setS3Data] = useState();

  const [typeMessage, setTypeMessage] = useState('');
  const [onKeyEnter, setOnkeyEnter] = useState();
  const conversationRef = useRef(null);

  useEffect(() => {
    console.log("rendering");
    const consumerId = activeChatData?.consumerId;

    if(activeConsumerId !== consumerId){
      setChatLoader(true)
    }

    socket.emit("chatOfConsumers", { cid: consumerId});
    socket.on(consumerId, (data) => {
      console.log("chat-------------", data);
      if(data !== null){
        setActiveConsumerId(consumerId)
        setChatLoader(false)
        setMessages(data);
      }else{
        setActiveConsumerId(consumerId)
        setChatLoader(false)
        setMessages([])
      }
    });
  }, [activeChatData])

  useEffect(() => {
    const consumerId = activeChatData?.consumerId;
    socket.emit("chatOfConsumers", { cid: consumerId});
    socket.on(consumerId, (data) => {
      console.log("chat-------------", data);
      if(data !== null){
      setMessages(data);
      }else{
        setMessages([])
      }
    });
  }, [])

  useEffect(() => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [messages]);


  useEffect(() => {
    if (onKeyEnter === "Enter" && typeMessage.length > 0) {
      sendMessege()
    }
  }, [onKeyEnter])

  // const getAwsCredentails = async()=>{
  //     await fetch(process.env.REACT_APP_BASE_URL + '/aws/get/ITGoaWhatsapp', {
  //       method:'GET'
  //     }).then((res)=>res.json()).then((response)=>{
  //       console.log("response at get AWS", response);
  //       setS3Data(response?.data);
  //     }).catch((err)=>{
  //       console.log("err while getting aws credentials", err);
  //     })
  // }

  const sendMessege = async () => {

    console.log("Calling sendMessege", onKeyEnter);
    try{
      setMessageLoader(true)
    const response = await apiCall({
      apiGroup: "messenger",
      apiRoute: "sendMessageWithoutTemplate",
      payload: {
        departmentId: userState?.departmentId,
        consumerId: activeChatData?.consumerId,
        consumerContactNo: activeChatData?.consumerContactNo,
        consumerName: activeChatData?.consumerName,
        message: typeMessage,
        templateInitateById: userState?.userId,
        templateInitateByContactNo: String(userState?.contactNo),
        templateInitateByName: userState?.name,
        templateInitateTime: new Date()
      },
    });
    console.log("response", response?.message);

    if(response?.message){
      setMessageLoader(false)
    }else{
      setMessageLoader(false)

    }

    }catch(err){
      console.log("Err", err);
      setMessageLoader(false)

    }

    // await fetch(process.env.REACT_APP_BASE_URL + '/conversation/sendMessageWithoutTemplate', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     departmentId: userState?.departmentId,
    //     consumerId: activeChatData?.consumerId,
    //     consumerContactNo: activeChatData?.consumerContactNo,
    //     consumerName: activeChatData?.consumerName,
    //     message: typeMessage,
    //     templateInitateById: userState?.userId,
    //     templateInitateByContactNo: String(userState?.contactNo),
    //     templateInitateByName: userState?.name,
    //     templateInitateTime: new Date()
    //   })
    // }).then((res) => res.json()).then((response) => {
    //   console.log("response", response?.message);
    //   // setConversation(response?.data);
    // }).catch((err) => {
    //   console.log("Err", err);
    // })

    setTypeMessage('');
  }


  // ---- Credentails ---------------------   

  // const S3Data={
  //   bucketName:"it-goa",
  //   dirName:"chat",
  //   region:"ap-south-1",
  //   accessKeyId:"AKIA4GWLDQ2LKHRA77VG",
  //   secretAccessKey:"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",
  //   s3Url:"https://it-goa.s3.ap-south-1.amazonaws.com"
  // }


  //----- Config ---------------------------------
  const config = {
    bucketName: s3Data?.bucketName,
    dirName: s3Data?.dirName,
    region: s3Data?.region,
    accessKeyId: s3Data?.accessKeyId,
    secretAccessKey: s3Data?.secretAccessKey,
    s3Url: s3Data?.s3Url,
  };


  //---- Setting up aws S3 Client -----------
  const ReactS3Client = new S3(config);

  const onFileChange = async (e) => {
    setLoader(true);


    if (e.target.files.length > 0) {

      const file = e.target.files[0];

      console.log("file Type", file.name.split(".").pop());

      if (file.type.split('/')[0] === 'image') {
        console.log("------- filename image upload --------", file);

        const imageFile = e.target.files[0];
        console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          console.log("Compressed file", compressedFile);
          ReactS3Client.uploadFile(compressedFile, compressedFile?.name)
            .then((res) => {
              console.log("Response at S3", res);
              if (res.status === 204) {
                console.log("Response at 204", res);
                sendMessege({
                  senderId: userState?.data?._id,
                  receiverId: conversation?.members?.find(member => member !== userState?.data?._id),
                  conversationId: conversation?._id,
                  type: 'image',
                  text: res?.location
                })
                setLoader(false);

              } else {
                console.log("AWS Error")
              }
            })
            .catch((err) => {
              console.error("Err while uploading img on S3", err);
            });

        } catch (error) {
          console.log("Err while Image Compression", error);
        }

      }


      else if (file.type.split('/')[1] === 'pdf') {
        console.log('Ist time for PDF', file);

        try {
          await ReactS3Client.uploadFile(file, file.name)
            .then((res) => {
              console.log("Response at S3", res);
              if (res.status === 204) {
                console.log("Response at 204", res);
                // setFile(res?.location);
                sendMessege({
                  senderId: userState?.data?._id,
                  receiverId: conversation?.members?.find(member => member !== userState?.data?._id),
                  conversationId: conversation?._id,
                  type: 'pdf',
                  text: res?.location
                })
                setLoader(false);

              } else {
                console.log("AWS Error")
              }
            })
            .catch((err) => {
              console.error("Err while uploading img on S3", err);
            });

        } catch (error) {
          console.log("Err while Image Compression", error);
        }

      }

      // Excel ----------------------
      else if (file.name.split(".").pop() === "xlsx") {
        try {
          await ReactS3Client.uploadFile(file, file.name)
            .then((res) => {
              console.log("Response at S3", res);
              if (res.status === 204) {
                console.log("Response at 204", res);
                // setFile(res?.location);
                sendMessege({
                  senderId: userState?.data?._id,
                  receiverId: conversation?.members?.find(member => member !== userState?.data?._id),
                  conversationId: conversation?._id,
                  type: 'xlsx',
                  text: res?.location
                })
                setLoader(false);

              } else {
                console.log("AWS Error")
              }
            })
            .catch((err) => {
              console.error("Err while uploading img on S3", err);
            });

        } catch (error) {
          console.log("Err while Image Compression", error);
        }

      }


      else if (file.type.split('/')[1] === 'msword') {

        try {
          await ReactS3Client.uploadFile(file, file.name)
            .then((res) => {
              console.log("Response at S3", res);
              if (res.status === 204) {
                console.log("Response at 204", res);
                // setFile(res?.location);
                sendMessege({
                  senderId: userState?.data?._id,
                  receiverId: conversation?.members?.find(member => member !== userState?.data?._id),
                  conversationId: conversation?._id,
                  type: 'doc',
                  text: res?.location
                })
                setLoader(false);

              } else {
                console.log("AWS Error")
              }
            })
            .catch((err) => {
              console.error("Err while uploading img on S3", err);
            });

        } catch (error) {
          console.log("Err while Image Compression", error);
        }

      }


      // txt ----------------------
      else if (file.name.split(".").pop() === "txt") {
        try {
          await ReactS3Client.uploadFile(file, file.name)
            .then((res) => {
              console.log("Response at S3", res);
              if (res.status === 204) {
                console.log("Response at 204", res);
                // setFile(res?.location);
                sendMessege({
                  senderId: userState?.data?._id,
                  receiverId: conversation?.members?.find(member => member !== userState?.data?._id),
                  conversationId: conversation?._id,
                  type: 'txt',
                  text: res?.location
                })
                setLoader(false);

              } else {
                console.log("AWS Error")
              }
            })
            .catch((err) => {
              console.error("Err while uploading img on S3", err);
            });

        } catch (error) {
          console.log("Err while Image Compression", error);
        }

      }

      else {
        toast.error("Sorry, invalid file type");
        setLoader(false);
        console.log("invalid", file);
      }

    }

  }

  const getTickIcon = (status) => {
    switch (status.toLowerCase()) {
      case "failed" :
        return <Icon.ExclamationCircle color="red" />
      case "initate":
        return <Icon.CheckLg color="#A9A9A9" />; // Grey single tick
      case "sent":
        return <Icon.CheckAll color="#A9A9A9" />; // Grey double tick
      case "delivered":
        return <Icon.CheckAll color="#4A90E2" />; // Blue double tick
      case "read":
          return <Icon.CheckAll color="#4A90E2" />; // Blue double tick
      default:
        return null; // No icon for unknown status
    }
  };

  const formatMobileNumber = (mobileNumber) => {
    // Convert to string to handle numeric inputs
    const mobileString = mobileNumber.toString();
    
    // Extract the last 10 digits
    const mobile = mobileString.slice(-10);
    
    // Extract the remaining portion (country code)
    const countryCode = mobileString.slice(0, -10);
    
    // Format and return the result
    return countryCode ? `+${countryCode} ${mobile}` : mobile;
  }

  return (
    <div className='Conversation'>
      {/* <ToastContainer /> */}
      {
        !activeChatData ?
          <DefaultChat>
            <div className='text-center'>
              <DefaultChatHead> Now Chat, Hassel free with your department !</DefaultChatHead>
            </div>
          </DefaultChat>
          :
          <>
            <ConvHeader>
              <Left>
                <Image src={Avatar} alt="Profile" />
                <div>
                  <Name>{activeChatData?.consumerName}</Name>
                  <Status>
                    {formatMobileNumber(activeChatData?.consumerContactNo)}
                    {/* {activeUsers?.find(itm=>itm._id===activeChatData._id) ? "Online" : "Offline"} */}
                  </Status>
                </div>
              </Left>

              <Right>
                {/* <Icon.Search className='me-2'/> */}
                <Icon.ThreeDotsVertical className='me-2' size={18} />
              </Right>
            </ConvHeader>



            <Chats className="chats" ref={conversationRef}>
              {
                chatloader ? <center><Spinner variant="primary" /></center> : messages.length > 0 ? messages?.map((itm) => {
                  return (
                    <div key={itm._id}> {
                      itm?.messageBy === "department" ?

                        <You style={itm?.type === "image" ? { padding: '6px 6px' } : {}} >
                          {
                            itm?.type === "image" &&
                            <div style={{ position: 'relative' }}>
                              <img src={itm?.text} alt="" width={300} height="100%" style={{ objectFit: 'cover', borderRadius: '10px' }} />
                              <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: 'white', right: '4%' }} />
                              <p style={{ position: 'absolute', bottom: 5, color: 'white', right: 10, fontWeight: '600' }}>{formatDate(itm?.createdAt)}</p>
                            </div>
                          }

                          {
                            !itm?.type &&
                            <div className='d-flex justify-content-center align-items-center'>
                              <h6>{itm?.message}</h6>
                              <p>{formatDate(itm?.messageTime)} <span style={{ fontSize:18,marginLeft:5 }}>{getTickIcon(itm?.status)}</span></p>
                            </div>
                          }

                          {
                            itm?.type === "pdf" &&
                            <>
                              <div style={{ position: 'relative' }}>
                                <img src={pdfIcon} alt={itm?.text.split("/").pop()} width={100} height={100} />
                                <h6 className='mt-2 me-2'>{itm?.text.split("/").pop()}</h6>
                                <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: '#df5047', right: '0%' }} />
                                <p style={{ position: 'absolute', bottom: '0%', right: '0%' }}>{formatDate(itm?.createdAt)}</p>
                              </div>
                            </>
                          }

                          {
                            itm?.type === "xlsx" &&
                            <>
                              <div style={{ position: 'relative' }}>
                                <img src={xlsxIcon} alt={itm?.text.split("/").pop()} width={100} height={100} />
                                <h6 className='mt-2 me-2'>{itm?.text.split("/").pop()}</h6>
                                <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: '#6BBF4F', right: '0%' }} />
                                <p style={{ position: 'absolute', bottom: '0%', right: '0%' }}>{formatDate(itm?.createdAt)}</p>
                              </div>
                            </>
                          }

                          {
                            itm?.type === "doc" &&
                            <>
                              <div style={{ position: 'relative' }}>
                                <img src={fileIcon} alt={itm?.text.split("/").pop()} width={100} height={100} />
                                <h6 className='mt-2 me-2'>{itm?.text.split("/").pop()}</h6>
                                <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: '#489CE4', right: '0%' }} />
                                <p style={{ position: 'absolute', bottom: '0%', right: '0%' }}>{formatDate(itm?.createdAt)}</p>
                              </div>
                            </>
                          }

                          {
                            itm?.type === "txt" &&
                            <>
                              <div style={{ position: 'relative' }}>
                                <img src={txtIcon} alt={itm?.text.split("/").pop()} width={100} height={100} />
                                <h6 className='mt-2 me-2'>{itm?.text.split("/").pop()}</h6>
                                <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: '#7548DD', right: '0%' }} />
                                <p style={{ position: 'absolute', bottom: '0%', right: '0%' }}>{formatDate(itm?.createdAt)}</p>
                              </div>
                            </>
                          }

                        </You>

                        :
                        <Them style={itm?.type === "image" ? { padding: '6px 6px' } : {}}>
                          {
                            itm?.type === "image" &&
                            <div style={{ position: 'relative' }}>
                              <img src={itm?.text} alt="" width={300} height="100%" style={{ objectFit: 'cover', borderRadius: '10px' }} />
                              <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: 'white', right: '4%' }} />
                              <p style={{ position: 'absolute', bottom: 5, color: 'white', right: 10, fontWeight: '600' }}>{formatDate(itm?.createdAt)}</p>
                            </div>
                          }

                          {
                            !itm?.type &&
                            <>
                              <h6>{itm?.message}</h6>
                              <p>{formatDate(itm?.messageTime)}</p>
                            </>
                          }

                          {
                            itm?.type === "pdf" &&
                            <>
                              <div style={{ position: 'relative' }}>
                                <img src={pdfIcon} alt={itm?.text.split("/").pop()} width={100} height={100} />
                                <h6 className='mt-2 me-2'>{itm?.text.split("/").pop()}</h6>
                                <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: '#df5047', right: '0%' }} />
                                <p style={{ position: 'absolute', bottom: '0%', right: '0%' }}>{formatDate(itm?.createdAt)}</p>
                              </div>
                            </>
                          }

                          {
                            itm?.type === "xlsx" &&
                            <>
                              <div style={{ position: 'relative' }}>
                                <img src={xlsxIcon} alt={itm?.text.split("/").pop()} width={100} height={100} />
                                <h6 className='mt-2 me-2'>{itm?.text.split("/").pop()}</h6>
                                <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: '#6BBF4F', right: '0%' }} />
                                <p style={{ position: 'absolute', bottom: '0%', right: '0%' }}>{formatDate(itm?.createdAt)}</p>
                              </div>
                            </>
                          }

                          {
                            itm?.type === "doc" &&
                            <>
                              <div style={{ position: 'relative' }}>
                                <img src={fileIcon} alt={itm?.text.split("/").pop()} width={100} height={100} />
                                <h6 className='mt-2 me-2'>{itm?.text.split("/").pop()}</h6>
                                <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: '#489CE4', right: '0%' }} />
                                <p style={{ position: 'absolute', bottom: '0%', right: '0%' }}>{formatDate(itm?.createdAt)}</p>
                              </div>
                            </>
                          }

                          {itm?.type === "txt" &&
                            <>
                              <div style={{ position: 'relative' }}>
                                <img src={txtIcon} alt={itm?.text.split("/").pop()} width={100} height={100} />
                                <h6 className='mt-2 me-2'>{itm?.text.split("/").pop()}</h6>
                                <Icon.CloudArrowDownFill onClick={(e) => downloadMedia(e, itm?.text)} size={40} style={{ position: 'absolute', top: '4%', color: '#7548DD', right: '0%' }} />
                                <p style={{ position: 'absolute', bottom: '0%', right: '0%' }}>
                                  {formatDate(itm?.createdAt)}

                                </p>
                              </div>
                            </>
                          }


                        </Them>
                    }

                    </div>


                  )
                } 

                ) : null
              }

              {loader ? <center><Spinner variant="primary" style={{ marginBottom: '100px' }} /></center> : ''}

            </Chats>


            <ConvFooter>
              { messageloader ?
                <div className='w-100 d-flex justify-content-center align-items-center'>
                  <Spinner variant="dark" />
                </div>
              : <>
                <Icon.EmojiSmile size={22} />
              <label htmlFor="fileInput">
                <Icon.Paperclip size={22} style={{ transform: 'rotate(40deg)' }} />
              </label>
              <input
                type='file'
                id="fileInput"
                style={{ display: 'none' }}
                onChange={(e) => onFileChange(e)}
              />

              <Form.Control placeholder='Type a message' value={typeMessage} onChange={(e) => setTypeMessage(e.target.value)}
                onKeyUp={(e) => {
                  setOnkeyEnter(e.key);
                }} />
              {typeMessage &&
                <img src={sendIconSvg} alt="send" className='ms-2' style={{ cursor: 'pointer' }}
                  onClick={() => sendMessege()} />
              }
              </> }
            </ConvFooter>
          </>
      }

    </div>
  )
}

export default Conversation