import React, { useState } from "react";
import { Accordion, Button, Spinner } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../Auth/LoginSlice";
import { setActiveKey, setMultiMsg } from "./ActiveKeySlice";
import GoaGov from '../../Assets/GoaGov White.svg';
import { setActiveChat, setOnlineUsers } from "../Messenger/ChatSlice";
import { setCommonData } from "../Admin/commonSlice";


const Sidebar = () => {

  const userState = useSelector(state => state.loginData.userData);
  const activeKeyRedux = useSelector(state => state.activeData?.activeNumberKey);

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setActiveKey(1));
    dispatch(setActiveChat(''));
    dispatch(setOnlineUsers([]));
    dispatch(setCommonData(null));
    dispatch(setMultiMsg(false));
    navigate('/');
    localStorage.removeItem('persist:root')
    setLoader(false);
  }


  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    console.log('index, tabName', activeKey,subActiveKey, tabName);
    dispatch(setActiveKey({ activeKey: activeKey, subActiveKey:subActiveKey, activeTab: tabName }));
    console.log("activekey at redux", activeKeyRedux);
  };


  return (
    <>
      <div className="sidenav-lg d-flex justify-content-between flex-column" >
        <div>
          <div className="my-3 text-center">
            <img src={GoaGov} alt="Goa Government" height={80} className="mb-2"/>
            <hr></hr>
          </div>

          {
            userState?.data?.userType === "Admin" || userState?.data?.userTypeKey === "DH" || userState?.data?.userTypeKey === "DU" ?
      
              <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

                <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate("/dashboard"); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2 icon' />}Dashboard</Accordion.Header>
                </Accordion.Item>

               

                { userState.data.userTypeKey === "DH" && <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Manage Consumer"); navigate("manage-consumer"); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.PersonBadgeFill className='me-2' /> : <Icon.PersonBadge className='me-2 icon' />}Consumer</Accordion.Header>
                </Accordion.Item>}

                {
                  userState.data.userTypeKey === "DH" &&
                  <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Manage Users"); navigate("manage-users"); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Users</Accordion.Header>
                </Accordion.Item> 

                }
 
                
                {
                      userState.data.userTypeKey === "admin" && 
            
                <Accordion.Item eventKey={5} className={activeKeyRedux?.activeKey === 5 ? "activeMenu dropDown" : "dropDown"}>
                      <Accordion.Header onClick={() => { handleAccordionChange(5, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                        {activeKeyRedux?.activeKey === 5 ? <Icon.EnvelopeAtFill className='me-2' /> : <Icon.EnvelopeAt className='me-2 icon' />}Departments</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                        
                  <Button onClick={() => { handleAccordionChange(5, 5.1, "Manage Users"); navigate("manage-users"); }} className={activeKeyRedux?.subActiveKey===5.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 5.1 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Users
                  </Button>        
                  {/* <Button  onClick={() => { handleAccordionChange(5, 5.2, "Manage Category"); navigate("manage-category"); }} className={activeKeyRedux?.subActiveKey===5.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                   {activeKeyRedux?.subActiveKey === 5.2 ? <Icon.PersonBoundingBox className='me-2' /> : <Icon.PersonBoundingBox className='me-2 icon' />}Manage User Category
                  </Button> */}
                  <Button onClick={() => { navigate("manage-department"); handleAccordionChange(5, 5.2, "Manage Departments") }} className={activeKeyRedux?.subActiveKey===5.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 5.2 ? <Icon.BuildingFillGear className="me-2" /> : <Icon.BuildingGear className="me-2" />}Manage Departments
                  </Button>
                  <Button onClick={() => { navigate("manage-department-credit"); handleAccordionChange(5, 5.3, "Manage Department Credit") }} className={activeKeyRedux?.subActiveKey===5.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                      {activeKeyRedux?.subActiveKey === 5.3 ? <Icon.CreditCard2FrontFill className="me-2" /> : <Icon.CreditCard2Front className="me-2" />}Department Credit
                  </Button>
                </Accordion.Body>
            </Accordion.Item> 
          }
                 

               <Accordion.Item eventKey={6} className={activeKeyRedux?.activeKey === 6 ? "activeMenu dropDown" : "dropDown"}>
                  <Accordion.Header onClick={() => { handleAccordionChange(6, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                    {activeKeyRedux?.activeKey === 6 ? <Icon.EnvelopeAtFill className='me-2' /> : <Icon.EnvelopeAt className='me-2 icon' />}Templates</Accordion.Header>

                  <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("manage-templates"); handleAccordionChange(6, 6.1, "Manage Templates") }} className={activeKeyRedux?.subActiveKey===6.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 6.1 ? <Icon.EnvelopeAtFill className="me-2" /> : <Icon.EnvelopeAt className="me-2" />}Manage Templates
                    </Button>
                   {/* <Button onClick={() => { navigate("manage-template-target"); handleAccordionChange(6, 6.2, "Manage Template Target") }} className={activeKeyRedux?.subActiveKey===6.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 6.2 ? <Icon.EnvelopeSlashFill className="me-2" /> : <Icon.EnvelopeSlash className="me-2" />}Template Target
                    </Button> */}
                    {/* <Button onClick={() => { navigate("manage-template-user-channel"); handleAccordionChange(6, 6.3, "Manage Template User-Channel") }} className={activeKeyRedux?.subActiveKey===6.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 6.3 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Template User-Channel
                    </Button> */}
                  </Accordion.Body>
                </Accordion.Item> 

                <Accordion.Item eventKey={7} onClick={() => { handleAccordionChange(7, 7, "Messenger"); navigate("messenger"); }} className={activeKeyRedux?.activeKey === 7 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 7 ? <Icon.ChatDotsFill className='me-2' /> : <Icon.ChatDots className='me-2 icon' />}Messenger</Accordion.Header>
                </Accordion.Item>

              </Accordion> :''

          }

        </div>

        <div className="pb-4 ps-2">
          <Button className='LogoutBtn w-100' onClick={handleLogout}>{loader ? <Spinner animation="border" variant="light" className='spinner' /> : <><Icon.BoxArrowLeft size={18} className='me-2' />Logout</>}</Button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;




