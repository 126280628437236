import React, { useEffect, useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import Avatar from "../../Assets/Avatar.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setActiveChat } from "./ChatSlice";
import { styled } from "styled-components";
import * as Icon from "react-bootstrap-icons";
import useApiCall from "../../Hooks/useApiCall";
import { io } from "socket.io-client";
import { formatDate } from "../../Utils/CommonUtils";

/* ---------------- Styling ---------------------- */
const ContHeader = styled.div`
  background: #ededed;
  padding: 10px;
  height: 50px;
  border-right: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContHeaderImage = styled("img")`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

const ContSearchDiv = styled("div")`
  padding: 10px;
  background: #ffffff;
  height: 8vh;
  border-bottom: 2px solid #ededed;
`;

const socket = io("https://api.itgoa.foxberry.live");

const ContactList = () => {
  const { apiCall } = useApiCall();

  const userState = useSelector((state) => state?.loginData?.userData?.data);
  const activeChatData = useSelector(
    (state) => state?.activeChatReducer?.activeChatData
  );
  const dispatch = useDispatch();

  // States for Users ------
  const [users, setUsers] = useState([]);

  const [loader, setLoader] = useState(false);
  const [contSearch, setContSearch] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(20);

  // useEffect(()=>{
  //     getAllUsers();
  // },[])

  // const {socket, setActiveUsers} = useContext(AccountContext);

  useEffect(() => {
    console.log("Getting Conversation by Socket");
    socket.emit("deptConversation", { departmentId: userState?.departmentId });

    socket.on(userState?.departmentId, (data) => {
      console.log("Conversations By Socket", data);
      if (data === null) {
        setConversation("");
      } else {
        setUsers(data);
      }
    });
  }, [userState]);

  const getAllUsers = async () => {
    console.log("InSIDE API");
    setLoader(true);
    try {
      const response = await apiCall({
        apiGroup: "messenger",
        apiRoute: "getDepartmentHeadConversations",
        extendedRoute: `/${userState?.userId}`,
        payload: { documentsPerPage: docPerPage, page: pageNo },
      });

      if (response?.data?.consumers.length > 0) {
        setUsers(response?.data?.consumers);
        setPageNo(response?.data?.noOfPages);
        setLoader(false);
      } else {
        setLoader(false);
        setUsers([]);
        setConversation("");
      }
    } catch (Err) {
      console.log("Error while getting users", Err);
      setLoader(false);
    }
  };

  const setConversation = async (itm) => {
    dispatch(setActiveChat(itm));
  };

  const formatMobileNumber = (mobileNumber) => {
    // Convert to string to handle numeric inputs
    const mobileString = mobileNumber.toString();

    // Extract the last 10 digits
    const mobile = mobileString.slice(-10);

    // Extract the remaining portion (country code)
    const countryCode = mobileString.slice(0, -10);

    // Format and return the result
    return countryCode ? `+${countryCode} ${mobile}` : mobile;
  };

  return (
    <div>
      <ContHeader>
        {/* <ContHeaderImage src={Avatar} alt="Profile"/> */}
        <h4 style={{ color: "#1ec912", fontWeight: 600 }}>Chats</h4>
        <Icon.ThreeDotsVertical className="me-2" size={18} />
      </ContHeader>

      {/* <ContSearchDiv>
         <InputGroup>
                  <InputGroup.Text><Icon.Search size={20}/></InputGroup.Text>
                        <Form.Control value={contSearch} placeholder="search here ......."/>
                  </InputGroup>
         </ContSearchDiv> */}

      <div className="ContactList">
        {loader ? (
          <center>
            <Spinner variant="info" className="mt-2" />
          </center>
        ) : users !== null && users !== undefined && users?.length > 0 ? (
          users?.map((itm, index) => {
            const {
              consumerName,
              consumerContactNo,
              messageCount,
              mostRecentMessageDate,
            } = itm;
            return (
              <div
                key={index}
                className={
                  activeChatData?.consumerName === consumerName
                    ? "contactCard contactCardActive mb-2"
                    : "contactCard mb-2"
                }
                onClick={() => setConversation(itm)}
              >
                <div className="w-150 d-flex justify-content-center align-items-center">
                  <img
                    src={Avatar}
                    width={40}
                    height={40}
                    alt="Profile"
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="w-80 ms-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h6>{consumerName}</h6>
                      {/* <p className='lastMsgDate'>{consumerContactNo}</p> */}
                    </div>
                    <p className="lastMsg">
                      {formatMobileNumber(consumerContactNo)}
                    </p>
                  </div>
                </div>
                <div className="w-10 ms-2">
                  <div
                    className="m-1"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <span className="msgTime">
                      {mostRecentMessageDate !== undefined
                        ? formatDate(mostRecentMessageDate)
                        : null}
                    </span>
                    <span className="msgCount">
                      {messageCount !== 0 ? messageCount : null}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center text-danger my-4">No Users Found</p>
        )}
      </div>
    </div>
  );
};

export default ContactList;
